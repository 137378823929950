import * as React from "react";
import { COLORS } from "../../../config";
import IPagedUsers from "../../../ServerEntities/IPagedUsers";
import ErrorText from "../../../SharedComponents/ErrorText";
import Header from "../../../SharedComponents/Header";
import LoadingIndicator from "../../../SharedComponents/LoadingIndicator";
import { SessionContext } from "../../SessionContext";
import CustomerAdd from "./UserAdd";
import { getUsers } from "./UsersService";
import UsersTable from "./UsersTable";

const UsersIndex = () => {
    const { state } = React.useContext(SessionContext);
    const [isAdding, setIsAdding] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const [users, setUsers] = React.useState(null as unknown as IPagedUsers);
    const [refresh, setRefresh] = React.useState(0);
    const [pageNumber, setPageNumber] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(10);

    React.useEffect(() => {
        setLoading(true);
        getUsers(state.webToken, pageSize, pageNumber * pageSize, (users: IPagedUsers) => {
            setLoading(false);
            setError("");
            setUsers(users);
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage); ''
            setUsers(null as unknown as IPagedUsers);
        });
    }, [refresh]);

    const openAddScreen = () => {
        setIsAdding(true);
    };

    const onChangePageNumber = (pageNumber: number) => {
        setPageNumber(pageNumber);
        onRefresh();
    };

    const closeAddScreen = () => {
        setRefresh(refresh + 1);
        setIsAdding(false);
    };

    const onRefresh = () => {
        setRefresh(refresh + 1);
    };

    const getComponent = (isAdding: boolean) => {
        if (isAdding) {
            return <CustomerAdd onAddComplete={closeAddScreen} />;
        }
        return <UsersTable pageSize={pageSize} pageNumber={pageNumber} onChangePageNumber={onChangePageNumber} onRefresh={onRefresh} onAdd={openAddScreen} onDelete={onRefresh} users={users} />;
    };


    if (users && users.data.length === 0 && pageNumber > 0) {
        onChangePageNumber(pageNumber - 1);
    }

    return <div>
        <Header backgroundColor={COLORS.PRIMARY} previousPageUrl="/" />
        <LoadingIndicator type="Linear" show={loading} />
        {error && <ErrorText>{error}</ErrorText>}
        {getComponent(isAdding)}
    </div>;
};

export default UsersIndex;