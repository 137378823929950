import * as React from "react";

import { VERIFY_ACTIVE_INTERVAL } from '../config';
import { SessionContext } from './SessionContext';
import { tellServerUserInteractionHasOccurred } from './sessionService';

const SessionManager = () => {
    const { dispatch, state } = React.useContext(SessionContext);
    const [verifyInterval, setVerifyInterval] = React.useState(null as unknown as NodeJS.Timeout);

    const userInteractionServerCallSuccess = (newJWT: string) => {
        dispatch({ type: "setWebToken", payload: newJWT });
    };

    const userInteractionServerCallFailure = () => {
        return "";
    };

    const tellServerAboutSession = () => {
        if (state.webToken) {
            tellServerUserInteractionHasOccurred(state.webToken, userInteractionServerCallSuccess, userInteractionServerCallFailure);
        }
    };

    React.useEffect(() => {
        setVerifyInterval(setTimeout(tellServerAboutSession, VERIFY_ACTIVE_INTERVAL));
        return () => {
            if (verifyInterval) {
                clearTimeout(verifyInterval);
            }
        }
    }, [state.webToken]);

    return <span />;
};

export default SessionManager;