import styled from "styled-components";

const ErrorBox = styled.p`
    border-radius: 5px;
    padding: 12px;
    margin: 12px;
    color: red;
    border: 1px solid red;
    background-color: white;
    font-size: 15px;
    height: 20px;
`;

export default ErrorBox;