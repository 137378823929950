import * as React from "react";
import styled from "styled-components";
import { SessionContext } from "../../SessionContext";
import Button from "../../../SharedComponents/Button";
import LoadingIndicator from "../../../SharedComponents/LoadingIndicator";
import TextInput, { updateText } from "../../../SharedComponents/TextInput";
import IOpportunity from "../../../ServerEntities/IOpportunity";
import { commitOpportunityNotification, getOpenQuotes, saveOpportunity } from "./OpportunitiesService";
import ErrorText from "../../../SharedComponents/ErrorText";
import { Main, Title } from "./OpportunitiesTable";
import IMeetingInformation from "../../../ServerEntities/IMeetingInformation";
import ICustomerContactDetails from "../../../ServerEntities/ICustomerContactDetails";
import Table from "../../../SharedComponents/Table/Table";
import TableCell from "../../../SharedComponents/Table/TableCell";
import { componentConfiguration } from "../../../UsefulFunctions/getComponentConfiguration";
import { RolesContext } from "../../../Roles/RolesContext";
import useDateConverter from "../../../UsefulFunctions/useDateConverter";
import DynamicSelect from "../../../SharedComponents/Select/DynamicSelect";
import SelectItem from "../../../SharedComponents/Select/SelectItem";
import IQuote from "../../../ServerEntities/IQuote";
import { getContactIdByName } from "../../../UsefulFunctions/opportunityUtils";
import { getCustomerContacts, getCustomers } from "../Customers/CustomersService";
import Select from "../../../SharedComponents/Select/Select";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { createTriggerCommitOpportunity } from "../../../UsefulFunctions/createTriggers";
import { COLORS } from "../../../config";
import ICustomer from "../../../ServerEntities/ICustomer";
import IPagedCustomers from "../../../ServerEntities/IPagedCustomers";
import { getCustomerIdBy } from "../../../UsefulFunctions/customerUtils";
import IPagedUsers from "../../../ServerEntities/IPagedUsers";
import IQuoteUser from "../../../ServerEntities/IQuoteUser";
import { getUsers } from "../Users/UsersService";

const Row = styled.div`
    display: flex;
    flex-direction: row;
    margin: 12px 0px 30px;
`;

const Label = styled.label`
    color: #333333;
    line-height: 32px;
    min-width: 140px;
`;

const ContactsContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    min-height: 62px;
`;

const ContactContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
`;
const ContactName = styled.div`
    padding: 5px;
`;


const ButtonContainer = styled.div`
    text-align: right;
`;

interface IProps {
    onAddComplete: () => void
};


const OpportunityAdd = (props: IProps) => {
    const { state } = React.useContext(SessionContext);
    const { configuration } = React.useContext(RolesContext).state;
    const rbac = componentConfiguration("Opportunities", configuration);
    const [quoteNumber, setQuoteNumber] = React.useState("");
    const [workRequired, setWorkRequired] = React.useState("");
    const [additionalInfo, setAdditionalInfo] = React.useState("");
    const [contacts, setContacts] = React.useState([] as ICustomerContactDetails[]);
    const [dateAdded, setDateAdded] = React.useState(0);
    const [meetings, setMeetings] = React.useState([] as IMeetingInformation[]);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const [editingMeeting, setEditingMeeting] = React.useState(undefined as unknown as IMeetingInformation);
    const [freeText, setFreeText] = React.useState("");
    const [quotes, setQuotes] = React.useState([] as IQuote[]);
    const [customerId, setCustomerId] = React.useState(null as unknown as number);
    const [meetingDate, setMeetingDate] = React.useState(undefined as unknown as Date);
    const [customer, setCustomer] = React.useState("");
    const [customers, setCustomers] = React.useState([] as ICustomer[]);
    const [users, setUsers] = React.useState(null as unknown as IQuoteUser[])
    const [owner, setOwner] = React.useState("");
    const none = -1;

    React.useEffect(() => {
        getOpenQuotes(state.webToken, (quotes: IQuote[]) => {
            setLoading(false);
            setError("");
            setQuotes(quotes);
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage); ''
            setQuotes(null as unknown as IQuote[]);
        });
        getCustomers(state.webToken, 100000, 0, (customers: IPagedCustomers) => {
            setLoading(false);
            setError("");
            setCustomers(customers.data);
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage); ''
            setCustomers(null as unknown as ICustomer[]);
        });
        getUsers(state.webToken, 100000, 0, (quoteUsers: IPagedUsers) => {
            setLoading(false);
            setError("");
            setUsers(quoteUsers.data);
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage); ''
            setUsers(null as unknown as IQuoteUser[]);
        });
    }, []);



    const save = (commit: boolean) => (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const opportunity: IOpportunity = {
            id: 0,
            quoteNumber: quoteNumber,
            workRequired: workRequired,
            additionalInfo: additionalInfo,
            meetingInformation: meetings,
            dateAdded: dateAdded,
            status: commit ? 1 : 0,
            customer: customer,
            customerId: customerId,
            owner: owner
        };
        setLoading(true);
        saveOpportunity(state.webToken, opportunity, () => {
            setLoading(false);
            if (commit) {
                const trigger = createTriggerCommitOpportunity(opportunity);
                commitOpportunityNotification(trigger, state.webToken, () => { }, () => { },)
            }
            setError("");
            props.onAddComplete();
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
        });
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
    };

    const addMeeting = () => {
        const meetingCopy = [...meetings];
        const newMeeting = {
            date: 0, freeText: "",
            id: none,
            contacts: []
        };
        meetingCopy.push(newMeeting);
        setMeetings(meetingCopy);
        setEditingMeeting(newMeeting);
        setMeetingDate(undefined as unknown as Date);
        setFreeText("");
    }


    const addContact = (meetings: IMeetingInformation[], index: number) => () => {
        const contactsCopy = [...editingMeeting.contacts];
        contactsCopy.push({
            id: none,
            name: "",
            email: "",
            jobTitle: "",
            phone: "",
            primaryArch: false,
            primarySales: false,
            primaryFinance: false,
            primaryPmo: false,
            primaryDev: false,
            customerId: 0
        });
        const meetingCopy = [...meetings];
        meetingCopy[index].contacts = contactsCopy;
        setMeetings(meetingCopy);
        setEditingMeeting(meetings[index]);
    }

    const onEditMeeting = (meeting: IMeetingInformation) => (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        meeting.id = none;
        setMeetingDate(new Date(meeting.date));
        setFreeText(meeting.freeText);
        setEditingMeeting(meeting)
    };

    const onRemoveMeeting = (index: number) => (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        meetings.splice(index, 1);
        const meetingCopy = [...meetings];
        setMeetings(meetingCopy);
        setEditingMeeting(undefined as unknown as IMeetingInformation);
    };

    const onQuoteChange = (quoteNumber: string) => {
        setQuoteNumber(quoteNumber);
    };

    const onContactChange = (index: number) => (contactName: string) => {
        editingMeeting.contacts[index].name = contactName;
        editingMeeting.contacts[index].id = getContactIdByName(contactName, contacts);
        const editingMeetingCopy = { ...editingMeeting };
        setEditingMeeting(editingMeetingCopy);
    }

    const onRemoveContact = (index: number) => (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        editingMeeting.contacts.splice(index, 1);
        const editingMeetingCopy = { ...editingMeeting };
        setEditingMeeting(editingMeetingCopy);
    }

    const renderContact = (meetingId: number) => (contact: ICustomerContactDetails, index: number) => {
        if (contact.id == none) {
            return <ContactContainer>
                <Select placeholder="Select a contact" onChange={onContactChange(index)} childValues={contacts && contacts.map(option => option.name)} value={editingMeeting.contacts[index].name}>
                    {contacts.map((option, index) => <SelectItem height="20px" key={`add-contact-item-${index}`} value={option.name}>{option.name}</SelectItem>)}
                </Select>
            </ContactContainer >
        }
        return <ContactContainer>
            <ContactName>{contact.name}</ContactName>
            {editingMeeting !== undefined && meetingId === editingMeeting.id && <Button plain={true} onClick={onRemoveContact(index)}>x</Button>}
        </ContactContainer>
    }

    const onSaveMeeting = (index: number) => () => {
        editingMeeting.id = 1;
        if (meetingDate) {
            editingMeeting.date = meetingDate.getTime();
        }
        editingMeeting.freeText = freeText;
        meetings[index] = { ...editingMeeting };
        const meetingCopy = [...meetings];
        setMeetings(meetingCopy);
        setEditingMeeting(undefined as unknown as IMeetingInformation);
    }

    const onCancelMeeting = (index: number) => () => {
        meetings.splice(index, 1);
        const meetingCopy = [...meetings];
        setMeetings(meetingCopy);
        setEditingMeeting(undefined as unknown as IMeetingInformation);
    }

    const changeDate = (setter: (date: Date) => void) => (date: Date, event: React.SyntheticEvent) => {
        setter(date);
    };

    const onChangeCustomer = (customer: string) => {
        setCustomer(customer);
        const id = getCustomerIdBy(customer, customers);
        setCustomerId(id);
        getCustomerContacts(id, state.webToken, (contacts: ICustomerContactDetails[]) => {
            setLoading(false);
            setError("");
            setContacts(contacts);
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage); ''
            setContacts(null as unknown as ICustomerContactDetails[]);
        });
    };

    const onChangeOwner = (user: string) => {
        setOwner(user);
    };

    return <Main>
        <LoadingIndicator type="Linear" show={loading} />
        {error && <ErrorText>{error}</ErrorText>}
        <Title>Add opportunity</Title>
        <form>
            <Row>
                <Label htmlFor="customer">Customer</Label>
                <DynamicSelect required={true} placeholder="Select a customer" onChange={onChangeCustomer} childValues={customers && customers.map(option => option.customerName)} value={customer}>
                    {customers && customers.filter((obj: { status: boolean; }) => { return obj.status === true }).map((option, index) => <SelectItem height="20px" key={`add-quote-customer-item-${index}`} value={option.customerName}>{option.customerName}</SelectItem>)}
                </DynamicSelect>
            </Row>

            <Row>
                <Label htmlFor="quoteNumber">Quote number</Label>
                <DynamicSelect required={true} placeholder="Select a quote" onChange={onQuoteChange} childValues={quotes && quotes.map(option => option.quoteNumber)} value={quoteNumber}>
                    {quotes.map((option, index) => <SelectItem height="20px" key={`add-quote-item-${index}`} value={option.quoteNumber}>{option.quoteNumber}</SelectItem>)}
                </DynamicSelect>
            </Row>
            <Row>
                <Label htmlFor="orderOwner">Owner</Label>
                <Select required={true} placeholder="Select a user" onChange={onChangeOwner} childValues={users && users.map(option => option.id)} value={owner}>
                    {users && users.map((option, index) => <SelectItem height="20px" key={`add-quote-order-owner-item-${index}`} value={option.id}>{option.forename} {option.surname}</SelectItem>)}
                </Select>
            </Row>
            <Row>
                <Label htmlFor="workRequired">Work required</Label>
                <TextInput width="600px" textArea={true} required={false} htmlId="workRequired" value={workRequired} onChange={updateText(setWorkRequired)} placeholder="Work required" />
            </Row>
            <Row>
                <Label htmlFor="additionalInfo">Additional info</Label>
                <TextInput width="600px" textArea={true} required={false} htmlId="workRequired" value={additionalInfo} onChange={updateText(setAdditionalInfo)} placeholder="Additional info" />
            </Row>
        </form>
        <Title style={{ marginTop: "50px" }}>Meetings</Title>

        {meetings && <Table>
            <tbody>
                <tr style={{ minHeight: "48px", display: "flex" }} >
                    <TableCell width={"10%"}>Date</TableCell>
                    <TableCell width={"30%"}>Free text</TableCell>
                    <TableCell width={"40%"}>Contacts</TableCell>
                    <TableCell width={"20%"} innerStyle={{ display: "flex", justifyContent: "flex-end" }}>{(customerId && editingMeeting === undefined) && <Button color={COLORS.SIXTH} onClick={addMeeting} submit={false}>Add meeting</Button>}</TableCell>
                </tr>
                {meetings.map((meeting, index) => {
                    const id = meeting.id ? meeting.id : none;
                    if (id === none) {
                        return <tr key={`contact-entry=${index}`} style={{ minHeight: "48px", display: "flex" }}>
                            <TableCell width={"10%"}>
                                <DatePicker required={true} dateFormat="dd-MMM-yyyy" selected={meetingDate} onChange={changeDate(setMeetingDate)} placeholderText={"select a date"} />
                            </TableCell>
                            <TableCell width={"30%"}>
                                <TextInput width="100%" textArea={true} value={freeText} onChange={updateText(setFreeText)} placeholder="Free text" />
                            </TableCell>
                            <TableCell width={"40%"}>
                                <ContactsContainer>
                                    <Button color={COLORS.SIXTH} onClick={addContact(meetings, index)} style={{ marginRight: "30px" }} submit={false}>Add contact</Button>
                                    {meeting.contacts.map(renderContact(meeting.id))}
                                </ContactsContainer>
                            </TableCell>
                            <TableCell width={"20%"}>
                                <ButtonContainer>
                                    {rbac.update && <Button plain={true} onClick={onSaveMeeting(index)} submit={false} style={{ marginRight: "10px" }}>Save</Button>}
                                    {rbac.update && <Button plain={true} onClick={onCancelMeeting(index)} submit={false}>Cancel</Button>}
                                </ButtonContainer>
                            </TableCell>
                        </tr>;
                    } else {
                        return <tr key={`contact-entry=${index}`} style={{ minHeight: "48px", display: "flex", flexDirection: "row" }}>
                            <TableCell width={"10%"}>{useDateConverter(meeting.date)}</TableCell>
                            <TableCell width={"30%"}>{meeting.freeText}</TableCell>
                            <TableCell width={"40%"}>
                                <ContactsContainer>
                                    {meeting.contacts.map(renderContact(meeting.id))}
                                </ContactsContainer>
                            </TableCell>
                            <TableCell width={"20%"}>
                                {editingMeeting === undefined &&
                                    <ButtonContainer>
                                        {rbac.update && <Button plain={true} onClick={onEditMeeting(meeting)} style={{ marginRight: "10px" }}>Edit</Button>}
                                        {rbac.update && <Button plain={true} onClick={onRemoveMeeting(index)}>Remove</Button>}
                                    </ButtonContainer>
                                }
                            </TableCell>
                        </tr>;
                    }
                })
                }
            </tbody>
        </Table>}
        <ButtonContainer style={{ marginTop: "80px" }}>
            <Button color={COLORS.SIXTH} submit={true} onClick={save(false)}>Save opportunity</Button>
            <Button color={COLORS.SIXTH} style={{ marginLeft: "8px" }} onClick={save(true)}>Save and commit opportunity</Button>
            <Button plain={true} style={{ marginLeft: "8px" }} onClick={props.onAddComplete}>Cancel</Button>
        </ButtonContainer>

    </Main>;
};

export default OpportunityAdd;
