import styled from "styled-components";

const defaultColor = "#187fda";
const errorColor = "red";

const FloatingLabel = styled.div`
    font-family: Roboto,sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 14px;
    line-height: 32px;
    font-weight: 400;
    position: absolute;
    left: 10px;
    transform-origin: left top;
    transition: transform .15s cubic-bezier(.4,0,.2,1),color .15s cubic-bezier(.4,0,.2,1),-webkit-transform .15s cubic-bezier(.4,0,.2,1);
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: text;
    will-change: transform;
    color: #666;
    &.above {
        cursor:auto;
        transform: translateY(-75%) scale(.75) translateX(-10px);
    }
    &.focused {
        color: ${defaultColor};
    }
    &.error {
        color: ${errorColor};
    }
`;

export default FloatingLabel;
