import * as React from "react";
import styled from "styled-components";
import { getRelatedQuotes, updateQuote } from "./QuotesService";
import { getCustomers } from "../Customers/CustomersService";
import { getUsers } from "../Users/UsersService";
import { Main, Title, winStatuses } from "./QuotesTable";
import { SessionContext } from "../../SessionContext";

import ICustomer from "../../../ServerEntities/ICustomer";
import IPagedCustomers from "../../../ServerEntities/IPagedCustomers";
import IPagedUsers from "../../../ServerEntities/IPagedUsers";
import IQuote from "../../../ServerEntities/IQuote";
import IQuoteType from "../../../ServerEntities/IQuoteType";
import IQuoteUser from "../../../ServerEntities/IQuoteUser";

import Button from "../../../SharedComponents/Button";
import Checkbox from "../../../SharedComponents/Checkbox";
import ErrorText from "../../../SharedComponents/ErrorText";
import LoadingIndicator from "../../../SharedComponents/LoadingIndicator";
import DynamicSelect from "../../../SharedComponents/Select/DynamicSelect";
import Select from "../../../SharedComponents/Select/Select";
import SelectItem from "../../../SharedComponents/Select/SelectItem";
import TextInput, { updateText } from "../../../SharedComponents/TextInput";

import useDateConverter, { dateToUTC } from "../../../UsefulFunctions/useDateConverter";
import RelatedQuotes from "./RelatedQuotes";
import { COLORS } from "../../../config";

const Row = styled.div`
    display: flex;
    flex-direction: row;
    margin: 12px 0px 15px;
`;

const Label = styled.label`
    color: #333333;
    line-height: 32px;
    min-width: 200px;
`;

const Value = styled.div`
    line-height: 32px;
`;

const ColumnsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const ColumnContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 20px;
`;

interface IProps {
    onAddComplete?: () => void,
    types: IQuoteType[],
    quote: IQuote
};

const QuoteEdit = (props: IProps) => {
    const { state } = React.useContext(SessionContext);
    const quoteTypes = props.types;
    const quote = props.quote;
    const [day, setDay] = React.useState("");
    const [month, setMonth] = React.useState("");
    const [year, setYear] = React.useState("");
    const [expiryDate, setExpiryDate] = React.useState(0);
    const [customer, setCustomer] = React.useState("");
    const [customerId, setCustomerId] = React.useState(0);
    const [projectName, setProjectName] = React.useState("");
    const [orderOwner, setOrderOwner] = React.useState("");
    const [orderOwnerId, setOrderOwnerId] = React.useState("");
    const [type, setType] = React.useState("");
    const [typeId, setTypeId] = React.useState(0);
    const [enteredDate, setEnteredDate] = React.useState(0);
    const [projectValue, setProjectValue] = React.useState("");
    const [relatedQuotes, setRelatedQuotes] = React.useState(quote.relatedQuotes);
    const [quotes, setQuotes] = React.useState(null as unknown as string[]);
    const [relatedQuoteDetails, setRelatedQuoteDetails] = React.useState("");
    const [winStatus, setWinStatus] = React.useState("");
    const [closed, setClosed] = React.useState(false);

    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const [customers, setCustomers] = React.useState(null as unknown as ICustomer[])
    const [users, setUsers] = React.useState(null as unknown as IQuoteUser[])

    React.useEffect(() => {
        setProjectName(quote.projectName);
        setExpiryDate(quote.expiryDate);
        setEnteredDate(quote.enteredDate);
        setProjectValue(quote.projectValue);
        setRelatedQuoteDetails(quote.relatedQuoteDetails);
        setWinStatus(quote.winStatus);
        setClosed(quote.closed);
        if (quote.closedDate) {
            const date = new Date(quote.closedDate);
            setDay(date.getDate().toString());
            setMonth((date.getMonth() + 1).toString());
            setYear(date.getFullYear().toString());
        }
        onChangeType(quote.typeId);
        setLoading(true);
        getCustomers(state.webToken, 100000, 0, (customers: IPagedCustomers) => {
            setLoading(false);
            setError("");
            setCustomers(customers.data);
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage); ''
            setCustomers(null as unknown as ICustomer[]);
        });
        getUsers(state.webToken, 100000, 0, (quoteUsers: IPagedUsers) => {
            setLoading(false);
            setError("");
            setUsers(quoteUsers.data);
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage); ''
            setUsers(null as unknown as IQuoteUser[]);
        });
    }, []);

    React.useEffect(() => {
        if (customers && quote.customerId) {
            onChangeCustomer(quote.customerId);
        }
    }, [customers]);

    React.useEffect(() => {
        if (users) {
            onChangeOrderOwner(quote.orderOwner);
        }
    }, [users]);


    React.useEffect(() => {
        setLoading(true);
        if (customerId !== undefined) {
            getRelatedQuotes(state.webToken, customerId, (quotes: string[]) => {
                setLoading(false);
                setError("");
                setQuotes(quotes);
            }, (errorMessage: string) => {
                setLoading(false);
                setError(errorMessage); ''
                setQuotes([]);
            });
        }

    }, [customer]);



    const save = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const quoteToSave: IQuote = {
            orderOwner: orderOwnerId,
            quoteNumber: quote.quoteNumber,
            enteredDate: enteredDate,
            customerId: customerId,
            projectName: projectName,
            projectValue: projectValue,
            orderEnteredBy: quote.orderEnteredBy,
            relatedQuotes: relatedQuotes ? relatedQuotes : [],
            relatedQuoteDetails: relatedQuoteDetails,
            archived: quote.archived,
            expiryDate: expiryDate,
            workAuthorisedBy: quote.workAuthorisedBy,
            workAuthorisedDate: quote.workAuthorisedDate,
            winStatus: winStatus,
            closed: closed,
            closedDate: dateToUTC(new Date(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10))),
            authorised: quote.authorised,
            typeId: typeId,
            id: quote.id
        };
        setLoading(true);
        updateQuote(state.webToken, quoteToSave, () => {
            setLoading(false);
            setError("");
            if (props.onAddComplete) {
                props.onAddComplete();
            }
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
        });
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
    };

    const onChangeCustomer = (customerId: number) => {
        setCustomerId(customerId);
        setCustomer(getCustomerById(customerId));
    }

    const onChangeOrderOwner = (userId: string) => {
        setOrderOwnerId(userId);
        setOrderOwner(getUserById(userId));
    }

    const onChangeType = (typeId: number) => {
        setTypeId(typeId);
        setType(getTypeById(typeId));
    }

    const getCustomerById = (customerId: number) => {
        if (customers) {
            const userObj = customers.filter(obj => {
                return obj.id === customerId
            });
            return userObj ? userObj[0].customerName : "";
        }
        return "";
    }

    const getUserById = (userId: string) => {
        const userArray = users.filter(obj => {
            return obj.id === userId
        });
        if (userArray.length > 0) {
            return userArray[0].forename + " " + userArray[0].surname;
        }
        return "";
    }

    const getTypeById = (typeId: number) => {
        const typeArray = quoteTypes.filter(obj => {
            return obj.id === typeId
        });
        if (typeArray.length > 0) {
            return typeArray[0].description;
        }
        return "";
    }

    const validateForm = () => {
        if (customer.length > 0 && projectName.length > 0 && orderOwner.length > 0 && type.length > 0) {
            return true;
        }
        return false;
    }

    const onChangeWinStatus = (status: string) => {
        setWinStatus(status);
    }

    const onUpdateRelatedQuotes = (quotes: string[]) => {
        setRelatedQuotes(quotes);
    }

    return <Main>
        <LoadingIndicator type="Linear" show={loading} />
        {error && <ErrorText>{error}</ErrorText>}
        <Title>Edit quote</Title>
        <form>
            <ColumnsContainer>
                <ColumnContainer>
                    <Row>
                        <Label>Quote number</Label>
                        <Value>{quote.quoteNumber}</Value>
                    </Row>
                    <Row>
                        <Label>Expiry Date</Label>
                        <Value>{useDateConverter(expiryDate)}</Value>
                    </Row>
                    <Row>
                        <Label>Customer</Label>
                        <DynamicSelect required={true} placeholder="Select a customer" onChange={onChangeCustomer} childValues={customers && customers.map(option => option.customerName)} value={customer}>
                            {customers && customers.filter((obj: { status: boolean; }) => { return obj.status === true }).map((option, index) => <SelectItem height="20px" key={`add-quote-customer-item-${index}`} value={option.id}>{option.customerName}</SelectItem>)}
                        </DynamicSelect>
                    </Row>
                    <Row>
                        <Label htmlFor="projectName">Project name</Label>
                        <TextInput required={true} width={"500px"} htmlId="projectName" value={projectName} onChange={updateText(setProjectName)} placeholder="Project name" />
                    </Row>
                    <Row>
                        <Label>Order owner</Label>
                        <Select required={true} placeholder="Select a user" onChange={onChangeOrderOwner} childValues={users && users.map(option => option.id)} value={orderOwner}>
                            {users && users.map((option, index) => <SelectItem height="20px" key={`add-quote-order-owner-item-${index}`} value={option.id}>{option.forename} {option.surname}</SelectItem>)}
                        </Select>
                    </Row>
                    <Row>
                        <Label>Order entered by</Label>
                        <Value>{quote.orderEnteredBy}</Value>
                    </Row>
                    <Row>
                        <Label>Type</Label>
                        <Select required={true} placeholder="Select a type" onChange={onChangeType} childValues={quoteTypes && quoteTypes.map(option => option.description)} value={type}>
                            {quoteTypes && quoteTypes.map((option, index) => <SelectItem height="20px" key={`add-quote-type-item-${index}`} value={option.id}>{option.description}</SelectItem>)}
                        </Select>
                    </Row>
                    <Row>
                        <Label>Entered date</Label>
                        <Value>{useDateConverter(enteredDate)}</Value>
                    </Row>
                    <Row>
                        <Label htmlFor="projectValue">Project value</Label>
                        <TextInput required={false} width={"500px"} htmlId="projectValue" value={projectValue} onChange={updateText(setProjectValue)} placeholder="Project value" />
                    </Row>
                </ColumnContainer>
                <ColumnContainer>
                    <Row>
                        <Label>Related quote</Label>
                        <RelatedQuotes quotes={quotes} relatedQuotes={relatedQuotes} updateRelateQuotes={onUpdateRelatedQuotes}></RelatedQuotes>
                    </Row>
                    <Row>
                        <Label htmlFor="relatedQuoteDetails">Related quote details</Label>
                        <TextInput textArea={true} width={"500px"} height={"100px"} required={false} htmlId="relatedQuoteDetails" value={relatedQuoteDetails} onChange={updateText(setRelatedQuoteDetails)} placeholder="Related quote details" />
                    </Row>
                    <Row>
                        <Label>Work authorised by</Label>
                        <Value>{quote.workAuthorisedBy}</Value>
                    </Row>
                    <Row>
                        <Label>Work authorised date</Label>
                        <Value>{quote.workAuthorisedDate ? useDateConverter(quote.workAuthorisedDate) : ""}</Value>
                    </Row>
                    <Row>
                        <Label>Win status</Label>
                        <Select required={true} placeholder="Select a status" onChange={onChangeWinStatus} childValues={winStatuses && winStatuses.map(option => option)} value={winStatus}>
                            {winStatuses && winStatuses.map((option, index) => <SelectItem height="20px" key={`add-win-status-item-${index}`} value={option}>{option}</SelectItem>)}
                        </Select>
                    </Row>
                    <Row>
                        <Label htmlFor="closed">Closed</Label>
                        <Checkbox htmlId="closed" checked={closed} onChange={setClosed} />
                    </Row>
                    <Row>
                        <Label htmlFor="closedDate">Closed date</Label>
                        <Value>{quote.closedDate && useDateConverter(quote.closedDate)}</Value>
                    </Row>
                </ColumnContainer>
            </ColumnsContainer>
            <ButtonContainer>
                <Button color={COLORS.FIFTH} disabled={!validateForm()} onClick={save}>Save quote</Button>
                <Button plain={true} style={{ marginLeft: "8px" }} onClick={props.onAddComplete}>Cancel</Button>
            </ButtonContainer>
        </form>
    </Main >
};

export default QuoteEdit;
