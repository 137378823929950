import React from "react";

const focusSelfOrFirstChild = (ref: React.MutableRefObject<any>) => {
    if (ref.current && ref.current.tabIndex === 0) {
        ref.current.focus();
    } else {
        const focusableChild = ref.current.querySelector('[tabindex = "0"]');
        if (focusableChild) {
            focusableChild.focus();
        }
    }
};

const focusWhenReady = (ref: React.MutableRefObject<any>) => {
    setTimeout(() => {
        if (ref.current) {
            focusSelfOrFirstChild(ref);
        } else {
            const interval = setInterval(() => {
                if (ref.current) {
                    focusSelfOrFirstChild(ref);
                    clearInterval(interval);
                }
            }, 100);
        }
    }, 1)
};

const useFocus = (ref: React.MutableRefObject<any>) => {
    return () => focusWhenReady(ref);
};

export default useFocus;
