import axios, { AxiosError, AxiosResponse } from "axios";
import { getAPIUrl } from "../../../config";
import ICustomer from "../../../ServerEntities/ICustomer";
import ICustomerContactDetails from "../../../ServerEntities/ICustomerContactDetails";
import IPagedCustomers from "../../../ServerEntities/IPagedCustomers";


export const saveCustomer = (webToken: string, customer: ICustomer, successCallback: () => void, failureCallback: (error: string) => void) => {
    axios.post(
        `${getAPIUrl()}quotes/customers`,
        customer,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then(successCallback).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const getCustomers = (webToken: string, limit: number, offset: number, successCallback: (customers: IPagedCustomers) => void, failureCallback: (error: string) => void) => {
    axios.get(
        `${getAPIUrl()}quotes/customers?limit=${limit}&offset=${offset}`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then((response: AxiosResponse<IPagedCustomers>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const updateCustomer = (webToken: string, customer: ICustomer, successCallback: () => void, failureCallback: (error: string) => void) => {
    axios.put(
        `${getAPIUrl()}quotes/customers`,
        customer,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then(successCallback).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const archiveCustomer = (webToken: string, customerId: number, successCallback: () => void, failureCallback: (error: string) => void) => {
    axios.delete(
        `${getAPIUrl()}quotes/customers/${customerId}`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then(successCallback).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const unarchiveCustomer = (webToken: string, customerId: number, successCallback: () => void, failureCallback: (error: string) => void) => {
    axios.put(
        `${getAPIUrl()}quotes/customers/${customerId}/unarchive`,
        {},
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then(successCallback).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const getCustomersCSV = (webToken: string) => {
    let url: string = `${getAPIUrl()}quotes/customers/csv`;
    url += `?bearer=${webToken}`
    window.open(url);

};

export const getCustomerContacts = (customerId: number, webToken: string, successCallback: (contacts: ICustomerContactDetails[]) => void, failureCallback: (error: string) => void) => {
    axios.get(
        `${getAPIUrl()}quotes/customers/${customerId}/contacts`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then((response: AxiosResponse<ICustomerContactDetails[]>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const updateDetails = (webToken: string, contact: ICustomerContactDetails, successCallback: () => void, failureCallback: (error: string) => void) => {
    axios.put(
        `${getAPIUrl()}quotes/customers/contacts`,
        contact,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then(successCallback).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const addDetails = (webToken: string, contact: ICustomerContactDetails, successCallback: () => void, failureCallback: (error: string) => void) => {
    axios.post(
        `${getAPIUrl()}quotes/customers/contacts`,
        contact,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then(successCallback).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const deleteCustomerContactDetails = (webToken: string, contactDetailsId: number, successCallback: () => void, failureCallback: (error: string) => void) => {
    axios.delete(
        `${getAPIUrl()}quotes/customers/contacts/${contactDetailsId}`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then(successCallback).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};



