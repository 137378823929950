import * as React from "react";
import styled from "styled-components";

import ViewContainer from "../../SharedComponents/ViewContainer";
import { SessionContext } from "../SessionContext";
import TitleBar from "./Titlebar"

import { getLastRemindees, getUsers, sendReminders } from "./ExpensesService";
import ErrorBox from "../../SharedComponents/ErrorBox";
import LoadingIndicator from "../../SharedComponents/LoadingIndicator";
import Header from "../../SharedComponents/Header";
import IUser from "../../ServerEntities/IUser";
import Select from "../../SharedComponents/Select/Select";
import SelectItem from "../../SharedComponents/Select/SelectItem";
import Button from "../../SharedComponents/Button";
import Card from "../../SharedComponents/Card";
import GrowableTextArea from "../../SharedComponents/GrowableTextArea";
import Dialog from "../../SharedComponents/Dialog/Dialog";
import { COLORS } from "../../config";

const MessageContainer = styled.div`
    width: 50%;
    margin-top: 50px;
`;

const RecipientListContainer = styled.div`
    width: 50%;
    margin-top: 50px;
`;

const MainContainer = styled.div`
    display: block;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 90%;
    margin:auto;
    justify-content: space-evenly;
    align-items: flex-start;
`;

const RecipientContainer = styled.div`
    margin: 24px;
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
`;

const SelectUserContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
`;

const RecipientName = styled.div`
    padding: 10px;
`;


interface IExpenseHistoryProps {
    isAdmin: boolean,
    backUrl: string
};

const FinanceReminders = (props: IExpenseHistoryProps) => {
    const { isAdmin, backUrl } = props;
    const { webToken } = React.useContext(SessionContext).state;
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const [reminder, setReminder] = React.useState("");
    const [allUsers, setAllUsers] = React.useState([] as IUser[]);
    const [selectedUserId, setSelectedUserId] = React.useState("");
    const [lastRemindees, setLastRemindees] = React.useState([] as string[]);
    const [recipientList, setRecipientList] = React.useState([] as IUser[]);
    const selectRef = React.useRef(null as unknown as HTMLDivElement);
    const [reminderSent, setReminderSent] = React.useState(false);

    React.useEffect(() => {
        setLoading(true);
        getUsers(webToken, onDataLoaded, onLoadingError);
        getLastRemindees(webToken, setLastRemindees, onLoadingError);
    }, []);

    const getUserFromId = (id: string, userArray: IUser[]) => {
        return userArray.find(function (element) {
            return element.id === id;
        });
    };

    const addToRecipientList = (value: string, index: number, array: string[]) => {
        const user = getUserFromId(value, allUsers);
        if (user) {
            recipientList.push(user);
        }
    };

    const onSendReminders = () => {
        setLoading(true);
        sendReminders(reminder, recipientList.map(r => r.id), webToken, () => {
            setLoading(false);
            setError("");
            setReminderSent(true);
        }, onLoadingError);
    };

    const onDataLoaded = (users: IUser[]) => {
        setLoading(false);
        setError("");
        setAllUsers(users);
    };

    if (recipientList.length === 0 && lastRemindees.length > 0 && allUsers.length > 0) {
        lastRemindees.forEach(addToRecipientList);
    }

    const onLoadingError = (errorMessage: string) => {
        setError(errorMessage);
        setLoading(false);
        setReminderSent(false);
    };

    const onAddRecipient = () => {
        const user = getUserFromId(selectedUserId, allUsers);
        if (user && getUserFromId(user.id, recipientList) == null) {
            recipientList.push(user);
            setSelectedUserId("");
        }
    };

    const getUserTextFromId = () => {
        const user = getUserFromId(selectedUserId, allUsers);
        if (user) {
            return `${user.department} - ${user.firstName} ${user.lastName}`;
        }
        return "";
    };

    const recipientToRow = (user: IUser) => {
        return <Card key={`recipient - item - ${user.id} `} style={{ display: "flex", width: "200px", justifyContent: "space-between", marginLeft: "10px", marginBottom: "10px" }}>
            <RecipientName>{`${user.firstName} ${user.lastName} `}</RecipientName><div onClick={onRemoveRecipient(user)}>x</div>
        </Card>
    };

    const onRemoveRecipient = (user: IUser) => (() => {
        recipientList.splice(recipientList.indexOf(user), 1);
        setSelectedUserId(selectedUserId + " ");
        return;
    });

    const sortRecipients = (userA: IUser, userB: IUser) => {
        let valueToReturn: number = 0;
        const itemA = `${userA.department} - ${userA.firstName} ${userA.lastName}`;
        const itemB = `${userB.department} - ${userB.firstName} ${userB.lastName}`;

        if ((typeof itemA === "string" && typeof itemB === "string") || (typeof itemA === "number" && typeof itemB === "number")) {
            if (itemA < itemB) {
                valueToReturn = -1;
            }
            if (itemA > itemB) {
                valueToReturn = 1;
            }
        }
        return valueToReturn;
    };

    const onCloseReminderSent = () => {
        setReminderSent(false);
        setReminder("");
    };

    return <div>
        <Header backgroundColor={COLORS.SECONDARY} previousPageUrl={backUrl} />
        <TitleBar viewName="Send reminder" viewDescription="" />
        <MainContainer>
            {reminderSent && <div><Dialog style={{ padding: "100px" }} open={reminderSent} onClose={onCloseReminderSent}>
                <h3>Reminder sent!</h3>
            </Dialog>
            </div>}
            <LoadingIndicator show={loading} type={"Linear"}></LoadingIndicator>
            {error && <ErrorBox>{error}</ErrorBox>}
            <MessageContainer>
                <GrowableTextArea min-height={200} required={true} htmlId="reminder" text={reminder} setText={setReminder} placeholder="Reminder..." />
                <Button margin="20px 0px 0px 0px" disabled={!(recipientList.length > 0 && reminder !== "")} onClick={onSendReminders}>Send</Button>
            </MessageContainer>
            <RecipientListContainer>
                {allUsers && <ViewContainer ref={selectRef}>
                    <SelectUserContainer>
                        <Select
                            width="350px"
                            placeholder="Select recipient"
                            value={getUserTextFromId()}
                            onChange={setSelectedUserId}
                            childValues={[]}>
                            {allUsers.sort(sortRecipients).map((user: IUser) => <SelectItem key={`select - item - ${user.id} `} value={user.id}>
                                {`${user.department} - ${user.firstName} ${user.lastName}`}
                            </SelectItem>)}
                        </Select>
                        <Button disabled={selectedUserId === ""} onClick={onAddRecipient}>Add recipient</Button>
                    </SelectUserContainer>
                    <RecipientContainer>
                        {recipientList.map(recipientToRow)}
                    </RecipientContainer>
                </ViewContainer>}
            </RecipientListContainer>
        </MainContainer>
    </div>;
};

export default FinanceReminders;

