import * as React from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import FloatingLabel from './FloatingLabel';

const defaultColor = "#187fda";
const errorColor = "red";

const InputBox = styled.input`
line-height:28px;
font-size:14px;
background-color:transparent;
color: #0c0c0c;
width:100%;
`;
const InputArea = styled.textarea`
line-height:28px;
background-color:transparent;
color: #0c0c0c;
width:100%;
resize: vertical;
min-height:${({ height }: { height?: string }) => height ? (parseInt(height.replace("px", ""), 10) - 4) + "px" : "28px"};
`;


const TextInputContainer = styled.div`
opacity: ${(props: IProps) => props.disabled ? 0.2 : 1};
position:relative;
border: 1px solid #e5e5e5;
border-radius:3px;
padding: 0px 10px 0px 10px;
margin: ${(props: IProps) => props.margin ? props.margin : ""};
transition: border-color .15s cubic-bezier(.4,0,.2,1);
background-color: white;

&.focused{
    border-color:${defaultColor};
}
&.error{
    border-color:${errorColor};
}
&.noOutline{
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-radius:0px;
    padding-left:0px;
    padding-right:0px;
}
width:${(props: IProps) => props.width};
flex:${(props: IProps) => props.width ? "0 0 auto" : ""};
`;


interface IProps {
    autoFocus?: boolean,
    value: string,
    onBlur?: (e: React.SyntheticEvent<HTMLInputElement>) => void,
    onInputFocus?: (e: React.SyntheticEvent<HTMLInputElement>) => void,
    onChange: (e: React.SyntheticEvent<HTMLInputElement>) => void,
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void,
    disabled?: boolean,
    htmlId?: string,
    htmlStep?: string,
    label?: string,
    type?: string,
    margin?: string,
    required?: boolean,
    hideAssistiveText?: boolean,
    width?: string,
    placeholder?: string,
    noOutline?: boolean,
    autoComplete?: string,
    invalid?: boolean,
    maxLength?: number,
    textArea?: boolean,
    height?: string
};

const AssistiveText = styled.p`
    font-size: 10px;
    color: ${({ error }: { error?: boolean }) => error ? errorColor : "#62676B"};
    margin: 2px 0;
`;

const AssistiveTextRow = styled.div`
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    left: 0;
`;

export const updateNumeric = (setter: (value: number) => void) => (e: React.SyntheticEvent<HTMLInputElement>) => {
    setter(parseFloat(e.currentTarget.value));
};

export const updateText = (setter: (value: string) => void) => (e: React.SyntheticEvent<HTMLInputElement>) => {
    setter(e.currentTarget.value);
};

const TextInput = (props: IProps) => {
    const {
        autoFocus,
        htmlId,
        htmlStep,
        value,
        onChange,
        onInputFocus,
        onBlur,
        onKeyDown,
        label,
        type,
        required,
        placeholder,
        noOutline,
        hideAssistiveText,
        autoComplete,
        disabled,
        invalid,
        maxLength,
        textArea,
        height
    } = props;

    const [focus, setFocus] = useState(false);
    const [error, setError] = useState(false);
    const reference = React.useRef(null as unknown as any);

    const flagAsFocused = () => {
        if (reference.current != null) {
            reference.current.focus();
        }
        setFocus(true);
    };

    const clearFocus = () => {
        setFocus(false);
        setError(value === "" && !!required);
    };

    const changeText = (event: React.SyntheticEvent<HTMLInputElement>) => {
        setError(event.currentTarget.value === "" && !!required);
        onChange(event);
    };

    const cancelContainerChangeEvent = (e: React.SyntheticEvent<any>) => {
        e.stopPropagation();
    };

    const changeTextArea = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        if ((event.currentTarget.value === "" && required)) {
            setError(true);
        } else {
            setError(false);
        }
        onChange(event as any);
    };

    return <TextInputContainer
        style={invalid ? { borderColor: "red" } : undefined}
        className={`textInput ${focus && "focused"} ${error && "error"} ${noOutline && "noOutline"}`}
        onFocus={flagAsFocused}
        {...props}
        onBlur={clearFocus}
        onChange={cancelContainerChangeEvent}
    >
        {label && <FloatingLabel className={`textInputLabel ${focus && "focused"} ${(focus || value !== "" || placeholder) && "above"} ${(error && "error")}`} onClick={flagAsFocused}>{label}</FloatingLabel>}
        {textArea ?
            <InputArea
                autoFocus={autoFocus}
                disabled={disabled}
                value={value}
                ref={reference}
                onChange={changeTextArea}
                maxLength={maxLength}
                placeholder={placeholder}
                height={height} />
            :
            <InputBox
                id={htmlId}
                step={htmlStep}
                autoFocus={autoFocus}
                disabled={disabled}
                value={value}
                ref={reference}
                onChange={changeText}
                onBlur={onBlur}
                onFocus={onInputFocus}
                onKeyDown={onKeyDown}
                type={type}
                maxLength={maxLength}
                placeholder={placeholder}
                autoComplete={autoComplete} />
        }
        <AssistiveTextRow className="AssistiveTextRow">
            {(required && !hideAssistiveText) ? <AssistiveText className="AssistiveText" error={error}>*Required</AssistiveText> : null}
        </AssistiveTextRow>

    </TextInputContainer>
}

export default TextInput;
