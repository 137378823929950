import * as React from "react";
import styled from "styled-components";

import Modal from "../Modal";
import Overlay from "../Overlay";

interface IProps {
    open: boolean,
    onClose: () => void,
    children: React.ReactNode,
    preventClickOutsideClose?: boolean,
    style?: React.CSSProperties
};

const DialogContainer = styled.div`
    max-width: 600px;
    display: flex;
    flex: 0 1 auto;
    position: relative;
    overflow-y: auto;
    flex-direction: column;
    box-shadow: 0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12);
    border-radius: 5px;
    background-color: white;
`;

function Dialog(props: IProps) {
    const { open, children, onClose, preventClickOutsideClose } = props;

    function closeDialog(e: React.SyntheticEvent<HTMLElement>) {
        onClose();
    }

    function cancelEventPropagation(e: React.SyntheticEvent<HTMLElement>) {
        e.stopPropagation();
    }

    const handleKeyDown = (event: any) => {
        const key = event.key || event.keyCode;
        if (key === "Escape") {
            event.stopPropagation();
            onClose();
        }
    };

    return <Modal show={open} >
        <Overlay onClick={preventClickOutsideClose ? cancelEventPropagation : closeDialog} className="overlay">
            <DialogContainer onKeyDown={handleKeyDown} className="DialogContainer" onClick={cancelEventPropagation} style={props.style}>
                {children}
            </DialogContainer>
        </Overlay>
    </Modal>;
};

export default Dialog;
