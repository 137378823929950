import ICustomer from "../ServerEntities/ICustomer";

export const getCustomerIdBy = (customer: string, customers: ICustomer[]) => {
    if (customers) {
        const userObj = customers.filter(obj => {
            return obj.customerName === customer
        });
        return userObj && userObj[0].id ? userObj[0].id : 0;
    }
    return 0;
};
