import * as React from "react";
import styled from "styled-components";
import Ripple from "./Ripple";


const CardContainer = styled.div`
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    border-radius:5px;
    cursor: ${(props: any) => props.onClick ? "pointer" : "auto"};
    margin: ${(props: IProps) => props.margin ? props.margin : ""};
    display:flex;
    width: ${(props: IProps) => props.width ? props.width : ""};
    height: ${(props: IProps) => props.height ? props.height : ""};
    background-color:white;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    &:hover{
        background-color: ${({ hoverColor }: { hoverColor?: string }) => hoverColor};
    }
`;

interface IProps {
    children?: any;
    onClick?: () => void;
    margin?: string;
    width?: string;
    height?: string;
    style?: React.CSSProperties,
    hoverColor?: string;
    rippleColor?: string;
    rippleStyle?: React.CSSProperties
    refObject?: React.MutableRefObject<HTMLDivElement>
    className?: string
}

const Card = (props: IProps) => {
    const { onClick, children, refObject } = props;
    if (onClick) {
        return <CardContainer {...props} className="Card" hoverColor={props.hoverColor} ref={refObject}>
            <Ripple color={props.rippleColor ? props.rippleColor : "#333333"} style={props.rippleStyle}>
                {children}
            </Ripple>
        </CardContainer>
    }
    return <CardContainer {...props} hoverColor={props.hoverColor}>
        {children}
    </CardContainer>;
};

export default Card;