import { useCallback, useLayoutEffect, useState } from "react";

interface IRectDimensions {
    bottom: number,
    height: number,
    left: number,
    right: number,
    top: number,
    width: number,
    x: number,
    y: number,
};

const getDimensionObject = (node: HTMLDivElement): IRectDimensions => {
    const rect = node.getBoundingClientRect();
    return {
        bottom: rect.bottom,
        height: node.clientHeight,
        left: rect.left,
        right: rect.right,
        top: rect.top,
        width: node.clientWidth,
        x: rect.left,
        y: rect.top
    };
};

const useDimensions = (): [(newNode: any) => void, IRectDimensions, HTMLDivElement] => {
    const [dimensions, setDimensions] = useState({} as unknown as IRectDimensions);
    const [node, setNode] = useState(null as unknown as HTMLDivElement);

    const ref = useCallback((newNode: any) => {
        setNode(newNode);
    }, []);

    useLayoutEffect(() => {
        if (node) {
            const measure = () =>
                window.requestAnimationFrame(() =>
                    setDimensions(getDimensionObject(node))
                );
            measure();

            window.addEventListener("resize", measure);
            window.addEventListener("scroll", measure);

            return () => {
                window.removeEventListener("resize", measure);
                window.removeEventListener("scroll", measure);
            };
        }
        return () => {
            return "";
        }
    }, [node]);

    return [ref, dimensions as IRectDimensions, node];
};

export default useDimensions;
