import * as React from "react";
import { COLORS } from "../../../config";
import ICustomer from "../../../ServerEntities/ICustomer";
import IPagedCustomers from "../../../ServerEntities/IPagedCustomers";
import ErrorText from "../../../SharedComponents/ErrorText";
import Header from "../../../SharedComponents/Header";
import LoadingIndicator from "../../../SharedComponents/LoadingIndicator";
import { SessionContext } from "../../SessionContext";
import CustomerAdd from "./CustomerAdd";
import CustomerEdit from "./CustomerEdit";
import { getCustomers } from "./CustomersService";
import CustomersTable from "./CustomersTable";

const CustomersIndex = () => {
    const { state } = React.useContext(SessionContext);
    const [isAdding, setIsAdding] = React.useState(false);
    const [isViewing, setIsViewing] = React.useState(null as unknown as ICustomer | null);
    const [isEditing, setIsEditing] = React.useState(null as unknown as ICustomer | null);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const [customers, setCustomers] = React.useState(null as unknown as IPagedCustomers);
    const [refresh, setRefresh] = React.useState(0);
    const [pageNumber, setPageNumber] = React.useState(0);
    const pageSize = 20;

    React.useEffect(() => {
        setLoading(true);
        getCustomers(state.webToken, pageSize, pageNumber * pageSize, (customers: IPagedCustomers) => {
            setLoading(false);
            setError("");
            setCustomers(customers);
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage); ''
            setCustomers(null as unknown as IPagedCustomers);
        });
    }, [refresh]);



    const openAddScreen = () => {
        setIsAdding(true);
    };

    const onChangePageNumber = (pageNumber: number) => {
        setPageNumber(pageNumber);
        onRefresh();
    };

    const openEditScreen = (customer: ICustomer) => {
        setIsEditing(customer);
    };

    const openViewScreen = (customer: ICustomer) => {
        setIsViewing(customer);
    };

    const closeAddScreen = () => {
        setRefresh(refresh + 1);
        setIsAdding(false);
        setIsEditing(null);
        setIsViewing(null);
    };

    const onRefresh = () => {
        setRefresh(refresh + 1);
    };

    const getComponent = (isAdding: boolean, isEditing: ICustomer | null, isViewing: ICustomer | null) => {
        if (isAdding) {
            return <CustomerAdd onAddComplete={closeAddScreen} />;
        }
        else if (isEditing) {
            return <CustomerEdit onAddComplete={closeAddScreen} customer={isEditing} />;
        }

        return <CustomersTable pageSize={pageSize} pageNumber={pageNumber} onChangePageNumber={onChangePageNumber} onRefresh={onRefresh} onAdd={openAddScreen} onEdit={openEditScreen} onView={openViewScreen} onDelete={onRefresh} customers={customers} />;
    };


    if (customers && customers.data.length === 0 && pageNumber > 0) {
        onChangePageNumber(pageNumber - 1);
    }

    return <div>
        <Header backgroundColor={COLORS.FOURTH} previousPageUrl="/" />
        <LoadingIndicator type="Linear" show={loading} />
        {error && <ErrorText>{error}</ErrorText>}
        {getComponent(isAdding, isEditing, isViewing)}
    </div>;
};

export default CustomersIndex;