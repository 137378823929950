import * as React from "react";
import { useParams } from "react-router-dom";
import { COLORS } from "../../../config";
import IPagedQuotes from "../../../ServerEntities/IPagedQuotes";
import IQuote from "../../../ServerEntities/IQuote";
import IQuoteType from "../../../ServerEntities/IQuoteType";
import ErrorText from "../../../SharedComponents/ErrorText";
import Header from "../../../SharedComponents/Header";
import LoadingIndicator from "../../../SharedComponents/LoadingIndicator";
import { isFinancialUser } from "../../../UsefulFunctions/roleCheck";
import { SessionContext } from "../../SessionContext";
import QuoteAdd from "./QuoteAdd";
import QuoteDetails from "./QuoteDetails";
import QuoteEdit from "./QuoteEdit";
import { getQuote, getQuotes, getQuoteTypes } from "./QuotesService";
import QuotesTable from "./QuotesTable";

const QuotesIndex = () => {
    const { quoteId } = useParams();
    const { state } = React.useContext(SessionContext);
    const [isAdding, setIsAdding] = React.useState(false);
    const [isEditing, setIsEditing] = React.useState(null as unknown as IQuote | null);
    const [isViewing, setIsViewing] = React.useState(null as unknown as IQuote | null);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const [quotes, setQuotes] = React.useState(null as unknown as IPagedQuotes);
    const [quoteTypes, setQuoteTypes] = React.useState(null as unknown as IQuoteType[]);
    const [refresh, setRefresh] = React.useState(0);
    const [pageNumber, setPageNumber] = React.useState(0);

    React.useEffect(() => {
        setLoading(true);
        getQuotes(state.webToken, (quotes: IPagedQuotes) => {
            setLoading(false);
            setError("");
            setQuotes(quotes);
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage); ''
            setQuotes(null as unknown as IPagedQuotes);
        });
    }, [refresh]);

    React.useEffect(() => {
        if (quoteTypes == null) {
            setLoading(true);
            getQuoteTypes(state.webToken, (types: IQuoteType[]) => {
                setLoading(false);
                setError("");
                setQuoteTypes(types);
            }, (errorMessage: string) => {
                setLoading(false);
                setError(errorMessage); ''
                setQuoteTypes(null as unknown as IQuoteType[]);
            });
        }
    }, []);

    React.useEffect(() => {
        if (quoteId && isViewing == null) {
            setLoading(true);
            getQuote(quoteId, state.webToken, (quote: IQuote) => {
                setLoading(false);
                setError("");
                setIsViewing(quote);
            }, (errorMessage: string) => {
                setLoading(false);
                setError(errorMessage); ''
                setIsViewing(null as unknown as IQuote);
            });
        }
    });

    const openAddScreen = () => {
        setIsAdding(true);
    };

    const openEditScreen = (quote: IQuote) => {
        setIsEditing(quote);
    };

    const openViewScreen = (quote: IQuote) => {
        setIsViewing(quote);
    };

    const onChangePageNumber = (pageNumber: number) => {
        setPageNumber(pageNumber);
        onRefresh();
    };

    const closeAddScreen = () => {
        setRefresh(refresh + 1);
        setIsAdding(false);
        setIsEditing(null);
        setIsViewing(null);
    };

    const onRefresh = () => {
        setRefresh(refresh + 1);
    };

    const getComponent = (isAdding: boolean, isEditing: IQuote | null, isViewing: IQuote | null) => {
        if (isAdding) {
            return <QuoteAdd onAddComplete={closeAddScreen} types={quoteTypes} />;
        }
        else if (isEditing) {
            return <QuoteEdit onAddComplete={closeAddScreen} types={quoteTypes} quote={isEditing} />;
        }
        else if (isViewing) {
            return <QuoteDetails onEdit={openEditScreen} types={quoteTypes} quote={isViewing} />;
        }
        return <QuotesTable isAdmin={isFinancialUser()}
            pageNumber={pageNumber}
            onChangePageNumber={onChangePageNumber}
            onRefresh={onRefresh}
            onAdd={openAddScreen}
            onEdit={openEditScreen}
            onDelete={onRefresh}
            onView={openViewScreen}
            quotes={quotes}
            types={quoteTypes}
        />;
    };


    if (quotes && quotes.data.length === 0 && pageNumber > 0) {
        onChangePageNumber(pageNumber - 1);
    }

    const onBack = () => {
        setIsAdding(false);
        setIsEditing(null);
        setIsViewing(null);
    }

    return <div>
        <Header backgroundColor={COLORS.FIFTH} onBack={onBack} previousPageUrl={isAdding || isEditing || isViewing ? "/quotes" : "/"} />
        <LoadingIndicator type="Linear" show={loading} />
        {error && <ErrorText>{error}</ErrorText>}
        {getComponent(isAdding, isEditing, isViewing)}
    </div>;
};

export default QuotesIndex;
