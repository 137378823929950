import * as React from 'react';
import styled from 'styled-components';

interface IProps {
    value: any,
    children: React.ReactNode,
    height?: string
};

const SelectItemContainer = styled.div`
    line-height: ${(props: any) => props.height ? props.height : "30px"};
    padding: 0px 10px 0px 10px;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    &:hover {
        background-color: rgba(24,127,186,0.05);
    }
    cursor: pointer;
    white-space: nowrap;
`;

const SelectItem = (props: IProps) => {
    const { children } = props;
    return <SelectItemContainer>
        {children}
    </SelectItemContainer>;
};

export default SelectItem;