import * as React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { SessionContext, SessionContextProvider } from './SessionContext';

import Login from "./Login/Login";
import MainMenu from "./MainMenu";
import SessionManager from './SessionManager';
import { RolesContext, RolesContextProvider } from '../Roles/RolesContext';
import ExpensesIndex from "./Expenses/ExpensesIndex";
import ExpenseHistory from "./Expenses/ExpenseHistory";
import { isFinancialUser } from "../UsefulFunctions/roleCheck";
import FinanceReminders from "./Expenses/FinanceReminders";
import CustomersIndex from "./Quotes/Customers/CustomersIndex";
import UsersIndex from "./Quotes/Users/UsersIndex";
import QuotesIndex from "./Quotes/Quotes/QuotesIndex";
import PreferredRolePicker from "./PreferredRolePicker";
import ResetPassword from "./Login/ResetPassword";
import LoginForm from "./Login/LoginForm";
import OpportunitiesIndex from "./Quotes/Opportunities/OpportunitiesIndex";

const PageIndex = () => <SessionContextProvider>
    <SessionManager />
    <RolesContextProvider>
        <IndexWithSession />
    </RolesContextProvider>
</SessionContextProvider>;

const IndexWithSession = () => {
    const { state } = React.useContext(SessionContext);
    const { role } = React.useContext(RolesContext).state;


    const renderRoute = (route: JSX.Element) => {
        return state.webToken ? route : <LoginForm />;
    };

    if (role === "") {
        return <PreferredRolePicker applicationName="InternalSystem" />
    }

    return <BrowserRouter>
        <Routes>
            <Route path="/Expenses" element={renderRoute(<ExpensesIndex />)} />
            <Route path="/code" element={<Login applicationName={"InternalSystem"} />} />
            <Route path="/" element={renderRoute(<MainMenu />)} />
            <Route path="/Finances" element={renderRoute(<ExpenseHistory backUrl="/" isAdmin={isFinancialUser()} />)} />
            <Route path="/Finances/Reminder" element={renderRoute(<FinanceReminders backUrl="/" isAdmin={isFinancialUser()} />)} />
            <Route path="/Expenses/Manage" element={renderRoute(<ExpenseHistory backUrl="/" isAdmin={isFinancialUser()} />)} />
            <Route path="/Customers" element={renderRoute(<CustomersIndex />)} />
            <Route path="/Users" element={renderRoute(<UsersIndex />)} />
            <Route path="/Quotes" element={renderRoute(<QuotesIndex />)} />
            <Route path="/Quote/:quoteId" element={renderRoute(<QuotesIndex />)} />
            <Route path="/Opportunities" element={renderRoute(<OpportunitiesIndex />)} />
            <Route path="/Opportunities/:opportunityId" element={renderRoute(<OpportunitiesIndex />)} />
        </Routes>
    </BrowserRouter>;
};

export default PageIndex;
