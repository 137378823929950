import axios, { AxiosError, AxiosResponse } from "axios";
import { getAPIUrl } from "../../../config";
import IQuote from "../../../ServerEntities/IQuote";
import IPagedQuotes from "../../../ServerEntities/IPagedQuotes";
import IQuoteType from "../../../ServerEntities/IQuoteType";
import INotificationTrigger from "../../../ServerEntities/INotificationTrigger";


export const saveQuote = (webToken: string, quote: IQuote, successCallback: (quote: IQuote) => void, failureCallback: (error: string) => void) => {
    axios.post(
        `${getAPIUrl()}quotes/quotes`,
        quote,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then((response: AxiosResponse<IQuote>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const getQuoteTypes = (webToken: string, successCallback: (quoteTypes: IQuoteType[]) => void, failureCallback: (error: string) => void) => {
    axios.get(
        `${getAPIUrl()}quotes/types`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then((response: AxiosResponse<IQuoteType[]>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const getQuote = (quoteId: string, webToken: string, successCallback: (quote: IQuote) => void, failureCallback: (error: string) => void) => {
    axios.get(
        `${getAPIUrl()}quotes/quote/${quoteId}`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then((response: AxiosResponse<IQuote>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const getQuotes = (webToken: string, successCallback: (quotes: IPagedQuotes) => void, failureCallback: (error: string) => void) => {
    axios.get(
        `${getAPIUrl()}quotes/quotes`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then((response: AxiosResponse<IPagedQuotes>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const updateQuote = (webToken: string, quote: IQuote, successCallback: () => void, failureCallback: (error: string) => void) => {
    axios.put(
        `${getAPIUrl()}quotes/quotes`,
        quote,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then(successCallback).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const deleteQuote = (webToken: string, quoteId: number, successCallback: () => void, failureCallback: (error: string) => void) => {
    axios.post(
        `${getAPIUrl()}quotes/quotes/${quoteId}/delete`,
        quoteId,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then(successCallback).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const archiveQuote = (webToken: string, quoteId: number, successCallback: () => void, failureCallback: (error: string) => void) => {
    axios.post(
        `${getAPIUrl()}quotes/quotes/${quoteId}/archive`,
        true,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then(successCallback).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const unarchiveQuote = (webToken: string, quoteId: number, successCallback: () => void, failureCallback: (error: string) => void) => {
    axios.post(
        `${getAPIUrl()}quotes/quotes/${quoteId}/archive`,
        false,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then(successCallback).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const authoriseQuote = (webToken: string, quoteId: number, user: string, successCallback: (quote: IQuote) => void, failureCallback: (error: string) => void) => {
    axios.post(
        `${getAPIUrl()}quotes/quotes/${quoteId}/authorise`,
        user,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then((response: AxiosResponse<IQuote>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const getRelatedQuotes = (webToken: string, customerId: number, successCallback: (relatedQuotes: string[]) => void, failureCallback: (error: string) => void) => {
    axios.get(
        `${getAPIUrl()}quotes/quotes/${customerId}/related`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then((response: AxiosResponse<string[]>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const getQuotesCSV = (webToken: string, typeFilter: string, orderOwnerFilter: string, quoteNumberFilter: string, enteredDateFilter: Date, customerFilter: string,
    projectFilter: string, expiryDateFilter: Date, statusFilter: string, authorisedFilter: string, archivedFilter: string) => {
    let url: string = `${getAPIUrl()}quotes/quotes/csv?`;

    if (typeFilter) {
        url += `&type=${typeFilter}`;
    }
    if (orderOwnerFilter) {
        url += `&orderOwner=${orderOwnerFilter}`;
    }
    if (quoteNumberFilter) {
        url += `&quoteNumber=${quoteNumberFilter}`;
    }
    if (enteredDateFilter) {
        url += `&enteredDate=${enteredDateFilter.getTime()}`;
    }
    if (customerFilter) {
        url += `&customer=${customerFilter}`;
    }
    if (projectFilter) {
        url += `&project=${projectFilter}`;
    }
    if (expiryDateFilter) {
        url += `&expiryDate=${expiryDateFilter.getTime()}`;
    }
    if (statusFilter) {
        url += `&status=${statusFilter}`;
    }
    if (authorisedFilter) {
        url += `&authorised=${authorisedFilter === "Yes" ? true : false}`;
    }
    if (archivedFilter) {
        url += `&archived=${archivedFilter === "Yes" ? true : false}`;
    }
    url += `&bearer=${webToken}`


    window.open(url);

};

export const createQuoteNotification = (trigger: INotificationTrigger, webToken: string, successCallback: () => void, failureCallback: (error: string) => void) => {
    axios.post(
        `${getAPIUrl()}notifications/message/publish`,
        trigger,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then(successCallback).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const getNotificationEmailList = (triggerName: String, webToken: string, successCallback: (emailList: string[]) => void, failureCallback: (error: string) => void) => {
    axios.get(
        `${getAPIUrl()}notifications/message/${triggerName}/emails`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then((response: AxiosResponse<string[]>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};
