import styled from 'styled-components';

const Overlay = styled.div`
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    position: fixed;
    touch-action: none;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-tap-highlight-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export default Overlay;
