import * as React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";

import useDataSorter from "../../../CustomHooks/useDataSorter";
import Button from "../../../SharedComponents/Button";
import Dialog from "../../../SharedComponents/Dialog/Dialog";
import ErrorText from "../../../SharedComponents/ErrorText";
import LoadingIndicator from "../../../SharedComponents/LoadingIndicator";
import { SessionContext } from "../../SessionContext";
import TableSortLabel from "../../../SharedComponents/Table/TableSortLabel";

import IPagedOpportunities from "../../../ServerEntities/IPagedOpportunities";
import IOpportunity from "../../../ServerEntities/IOpportunity";

import useDateConverter from "../../../UsefulFunctions/useDateConverter";
import "react-datepicker/dist/react-datepicker.css";
import { RolesContext } from "../../../Roles/RolesContext";
import { componentConfiguration } from "../../../UsefulFunctions/getComponentConfiguration";
import { deleteOpportunity } from "./OpportunitiesService";
import Paginator from "../../../SharedComponents/Table/Paginator";
import TableFooter from "../../../SharedComponents/Table/TableFooter";
import Select from "../../../SharedComponents/Select/Select";
import SelectItem from "../../../SharedComponents/Select/SelectItem";
import TextInput, { updateText } from "../../../SharedComponents/TextInput";
import { isEditableOpportunity, STATUS } from "../../../UsefulFunctions/opportunityUtils";
import { COLORS } from "../../../config";

export const Main = styled.main`
    padding: 50px;
    display: flex;
    flex-direction: column;
    text-align: left;
`;

export const Title = styled.h1`
    font-size: 1.3rem;
    margin: 12px 0px 20px;
`;

const ActionContainer = styled.div`
    height: 24px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;

const Table = styled.table`
    border-collapse: collapse;
`;

const TableCell = styled.td`
    height: 24px;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
`;

interface IProps {
    onAdd: () => void,
    onEdit: (opportunity: IOpportunity) => void,
    onView: (opportunity: IOpportunity) => void,
    onDelete: () => void,
    onRefresh: () => void,
    pageNumber: number;
    pageSize: number;
    onChangePageNumber: (pageNumber: number) => void,
    opportunities: IPagedOpportunities
};


const OpportunitiesTable = (props: IProps) => {
    const { state } = React.useContext(SessionContext);
    const { configuration } = React.useContext(RolesContext).state;
    const rbac = componentConfiguration("Opportunities", configuration);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const opportunities = props.opportunities ? props.opportunities.data : [];
    const [opportunityToDelete, setOpportunityToDelete] = React.useState(0);
    const [sortByColumn, setSortByColumn] = React.useState(2);
    const [sortDescending, setSortDescending] = React.useState(true);
    const [quoteNumberFilter, setQuoteNumberFilter] = React.useState("");
    const [workRequiredFilter, setWorkRequiredFilter] = React.useState("");
    const [additionalInfoFilter, setAdditionalInfoFilter] = React.useState("");
    const [statusFilter, setStatusFilter] = React.useState("");
    const [dateAddedFilter, setDateAddedFilter] = React.useState(undefined as unknown as Date);

    const opportunitiesCount = props.opportunities ? props.opportunities.count : 0;


    const changeTablePage = (pageNumber: number) => {
        props.onChangePageNumber(pageNumber);
    };

    const editOpportunity = (opportunityId: number) => (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const opportunity = opportunities.find((opportunity: IOpportunity) => {
            return opportunity.id === opportunityId;
        })
        if (opportunity) {
            props.onEdit(opportunity);
        }
    };

    const viewOpportunity = (opportunityId: number) => (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const opportunity = opportunities.find((opportunity: IOpportunity) => {
            return opportunity.id === opportunityId;
        })
        if (opportunity) {
            props.onView(opportunity);
        }
    };

    const onCloseDeleteDialog = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setOpportunityToDelete(0);
    }

    const onShowDeleteDialog = (opportunityId: number) => (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setOpportunityToDelete(opportunityId);
    }

    const onDelete = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setLoading(true);
        setOpportunityToDelete(0);
        deleteOpportunity(state.webToken, opportunityToDelete, () => {
            setLoading(false);
            setError("");
            props.onDelete();
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
        });
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
    }

    const changeSortColumn = (columnIndex: number) => () => {
        if (columnIndex === sortByColumn) {
            setSortDescending(!sortDescending);
        } else {
            setSortDescending(true);
        }
        setSortByColumn(columnIndex);
    };

    const OPPORTUNITY_ID = 0;
    const OPPORTUNITY_QUOTE_NUMBER = 1;
    const OPPORTUNITY_DATE_ADDED = 2;
    const OPPORTUNITY_WORK_REQUIRED = 3;
    const OPPORTUNITY_ADDITIONAL_INFO = 4;
    const OPPORTUNITY_STATUS = 5;

    const opportunityToArray = (opportunity: IOpportunity, index: number): [number, string, number, string, string, number, number] => [
        opportunity.id,
        opportunity.quoteNumber,
        opportunity.dateAdded,
        opportunity.workRequired,
        opportunity.additionalInfo,
        opportunity.status,
        index
    ];

    const filterOpportunities = (opportunity: IOpportunity) => {

        if (quoteNumberFilter.length > 0 && !opportunity.quoteNumber.toLowerCase().includes(quoteNumberFilter.toLowerCase())) {
            return false;
        }
        if (dateAddedFilter && !useDateConverter(opportunity.dateAdded).toLowerCase().includes(useDateConverter(dateAddedFilter).toLowerCase())) {
            return false;
        }
        if (workRequiredFilter.length > 0 && (opportunity.workRequired && !opportunity.workRequired.toLowerCase().includes(workRequiredFilter.toLowerCase()))) {
            return false;
        }
        if (additionalInfoFilter.length > 0 && !opportunity.additionalInfo.toLowerCase().includes(additionalInfoFilter.toLowerCase())) {
            return false;
        }
        if (statusFilter.length > 0 && !STATUS[opportunity.status].toLocaleLowerCase().includes(statusFilter.toLowerCase())) {
            return false;
        }
        return true;
    }

    const onClearFilters = () => {
        setQuoteNumberFilter("");
        setWorkRequiredFilter("");
        setAdditionalInfoFilter("");
        setStatusFilter("");
        setDateAddedFilter(undefined as unknown as Date);
    }

    const onChangeStatusFilter = (status: string) => {
        setStatusFilter(status);
    }

    const onQuoteNumberFilter = (quoteNumber: string) => {
        setQuoteNumberFilter(quoteNumber);
    }

    const onWorkRequiredFilter = (workRequired: string) => {
        setWorkRequiredFilter(workRequired);
    };

    const changeDate = (setter: (date: Date) => void) => (date: Date, event: React.SyntheticEvent) => {
        setter(date);
    };

    const onAdditionalInfoFilter = (additionalInfo: string) => {
        setAdditionalInfoFilter(additionalInfo);
    };




    return <Main>
        <Title>Opportunities</Title>
        <ActionContainer>
            {rbac.create && <Button color={COLORS.SIXTH} plain={false} style={{ marginLeft: "8px" }} onClick={props.onAdd}>Add new opportunity</Button>}
        </ActionContainer>
        <LoadingIndicator type="Linear" show={loading} />
        {error && <ErrorText>{error}</ErrorText>}
        <Dialog style={{ padding: "32px" }} open={opportunityToDelete > 0} onClose={onCloseDeleteDialog}>
            <h3>Are you sure you want to delete?</h3>
            <ActionContainer>
                <Button color={COLORS.SIXTH} onClick={onCloseDeleteDialog} style={{ marginRight: "10px" }}>Cancel</Button>
                <Button onClick={onDelete} color={"#e60000"}>Delete</Button>
            </ActionContainer>
        </Dialog>
        <Table>
            <thead>
                <tr>
                    <th style={{ width: "10%" }}><TableSortLabel active={sortByColumn === OPPORTUNITY_QUOTE_NUMBER} direction={sortDescending === true ? "descending" : "ascending"} onClick={changeSortColumn(OPPORTUNITY_QUOTE_NUMBER)}>Quote number</TableSortLabel></th>
                    <th style={{ width: "10%" }}><TableSortLabel active={sortByColumn === OPPORTUNITY_DATE_ADDED} direction={sortDescending === true ? "descending" : "ascending"} onClick={changeSortColumn(OPPORTUNITY_DATE_ADDED)}>Date added</TableSortLabel></th>
                    <th style={{ width: "20%" }}><TableSortLabel active={sortByColumn === OPPORTUNITY_WORK_REQUIRED} direction={sortDescending === true ? "descending" : "ascending"} onClick={changeSortColumn(OPPORTUNITY_WORK_REQUIRED)}>Work required</TableSortLabel></th>
                    <th style={{ width: "20%" }}><TableSortLabel active={sortByColumn === OPPORTUNITY_ADDITIONAL_INFO} direction={sortDescending === true ? "descending" : "ascending"} onClick={changeSortColumn(OPPORTUNITY_ADDITIONAL_INFO)}>Additional Information</TableSortLabel></th>
                    <th style={{ width: "10%" }}><TableSortLabel active={sortByColumn === OPPORTUNITY_STATUS} direction={sortDescending === true ? "descending" : "ascending"} onClick={changeSortColumn(OPPORTUNITY_STATUS)}>Status</TableSortLabel></th>
                    <th style={{ width: "10%" }}></th>
                </tr>
                <tr>
                    <th><TextInput value={quoteNumberFilter} onChange={updateText(setQuoteNumberFilter)}></TextInput></th>
                    <th><div style={{
                        border: "1px solid #e5e5e5",
                        borderRadius: "3px",
                        lineHeight: "30px"
                    }}>
                        <DatePicker dateFormat="dd-MMM-yyyy" selected={dateAddedFilter} onChange={changeDate(setDateAddedFilter)} />
                    </div>
                    </th>
                    <th><TextInput value={workRequiredFilter} onChange={updateText(setWorkRequiredFilter)}></TextInput></th>
                    <th><TextInput value={additionalInfoFilter} onChange={updateText(setAdditionalInfoFilter)}></TextInput></th>
                    <th><Select onChange={setStatusFilter} childValues={STATUS.map(option => option)} value={statusFilter}>
                        {STATUS.map((option, index) => <SelectItem key={`select-op-status-item-${index}`} value={option}>{option}</SelectItem>)}
                    </Select>
                    </th>
                    <th style={{ textAlign: "center" }}> <Button plain={true} onClick={onClearFilters} >clear filters</Button></th>
                </tr>
            </thead>
            <tbody>
                {opportunities
                    .filter(filterOpportunities)
                    .map(opportunityToArray)
                    .sort(useDataSorter(sortByColumn, sortDescending))
                    .map((opportunity, index: any) => {
                        return <tr key={`opportunity-table-row-${index}`} >
                            <TableCell>{opportunity[OPPORTUNITY_QUOTE_NUMBER]}</TableCell>
                            <TableCell>{useDateConverter(opportunity[OPPORTUNITY_DATE_ADDED])}</TableCell>
                            <TableCell style={{ maxHeight: "100px" }} >{opportunity[OPPORTUNITY_WORK_REQUIRED]}</TableCell>
                            <TableCell style={{ maxHeight: "100px" }} >{opportunity[OPPORTUNITY_ADDITIONAL_INFO]}</TableCell>
                            <TableCell>{STATUS[opportunity[OPPORTUNITY_STATUS]]}</TableCell>
                            <TableCell>
                                <ActionContainer>
                                    {rbac.read && <Button plain={true} onClick={viewOpportunity(opportunity[OPPORTUNITY_ID])} style={{ marginRight: "10px" }}>View</Button>}
                                    {rbac.update && isEditableOpportunity(opportunity[OPPORTUNITY_STATUS]) && <Button plain={true} onClick={editOpportunity(opportunity[OPPORTUNITY_ID])} style={{ marginRight: "10px" }}>Edit</Button>}
                                    {rbac.delete && isEditableOpportunity(opportunity[OPPORTUNITY_STATUS]) && <Button plain={true} style={{ marginLeft: "8px" }} onClick={onShowDeleteDialog(opportunity[OPPORTUNITY_ID])}>Delete</Button>}
                                </ActionContainer>
                            </TableCell>
                        </tr>;
                    })}
                <tr>
                    <td colSpan={6}>
                        <TableFooter>
                            <Paginator
                                pageSize={props.pageSize}
                                onPageChange={changeTablePage}
                                currentPage={props.pageNumber}
                                totalNumberOfRows={opportunitiesCount}
                            />
                        </TableFooter>
                    </td>
                </tr>
            </tbody>
        </Table>
    </Main >
};

export default OpportunitiesTable;
