import * as React from "react";
import styled from "styled-components";
import Card from "../../../SharedComponents/Card";
import IconButton from "../../../SharedComponents/IconButton";

import DynamicSelect from "../../../SharedComponents/Select/DynamicSelect";
import SelectItem from "../../../SharedComponents/Select/SelectItem";

const Grid = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 10px 0px;
    overflow-y: auto;
    height: 60px;
`;

const NoUsersText = styled.div`
    display: flex;
    flex-direction: row;
    color: #555;
    align-items: center;
    justify-content: center;
    padding: 14px;
`;

const Label = styled.div`
    padding: 10px 8px 10px 0;
    display:flex;
    flex-direction:column;
    flex: 1 1 auto;
    overflow: hidden;

    &.ellipsis {
        white-space: nowrap; 
        text-overflow: ellipsis;
        h2 {
            white-space: nowrap; 
            overflow: hidden;
            text-overflow: ellipsis;
        }

        h3 {
            white-space: nowrap !important; 
            overflow: hidden !important;
            text-overflow: ellipsis !important;
        }
        p {
            white-space: nowrap; 
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    
    h2 {
        font-size: 15px;
        font-weight: 500;
        line-height: 20px;
        margin: 0;
    }
    h3 {
        font-size: 15px !important;
        font-weight: 400 !important;
        line-height: normal !important;
        margin: 0 !important;
        margin-bottom: 0 !important; 
        color: #666666 !important;
    }
    p {
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
        margin: 0;
        color: #A9A9A9;
    }
`;

const MainContainer = styled.div`
    width: 80%;
    height: 100px;
`;

interface IProps {
    quotes: string[],
    relatedQuotes: string[],
    updateRelateQuotes: (quotes: string[]) => void
};

const RelatedQuotes = (props: IProps) => {
    const { quotes, updateRelateQuotes } = props;
    const [relatedQuote, setRelatedQuote] = React.useState("")
    const [relatedQuotes, setRelatedQuotes] = React.useState(props.relatedQuotes ? props.relatedQuotes : [])

    const onChangeRelatedQuote = (quote: string) => {
        setRelatedQuote(quote);
        relatedQuotes.push(quote);
        updateRelateQuotes(relatedQuotes);
    }

    const onRemoveQuote = (quote: string) => (e?: React.SyntheticEvent<HTMLDivElement, Event>) => {
        removeFrom(quote);
    }

    const removeFrom = (quote: string) => {
        const removed = relatedQuotes.filter(function (value, index, arr) {
            return value !== quote;
        });
        setRelatedQuotes(removed);
        updateRelateQuotes(removed);
    }

    return <MainContainer>
        <DynamicSelect placeholder="Select a related quote" onChange={onChangeRelatedQuote} childValues={quotes && quotes.map(option => option)} value={relatedQuote}>
            {quotes && quotes.map((option, index) => <SelectItem height="20px" key={`add-related-quote-item-${index}`} value={option}>{option}</SelectItem>)}
        </DynamicSelect>
        <Grid>
            {relatedQuotes && relatedQuotes.map((quote) => <Card key={quote} style={{ width: "128px", margin: "12px", padding: "2px 2px 0px 0px" }}>
                <Label style={{ padding: "6px 8px 6px 16px", wordWrap: "normal" }}>
                    <h2>{quote}</h2>
                </Label>
                <IconButton style={{ alignItems: "flex-start", lineHeight: "10px" }} onClick={onRemoveQuote(quote)}>×</IconButton>
            </Card>)}
            {relatedQuotes && relatedQuotes.length === 0 && <NoUsersText>There are no related quotes.</NoUsersText>}
        </Grid>
    </MainContainer>;
};
export default RelatedQuotes;