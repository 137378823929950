import styled from "styled-components";

const TableFooter = styled.div`
display: flex;
flex: 0 0 auto;
flex-direction:column;
color: #999999;
border-top: 1px solid #e5e5e5;
`;

export default TableFooter;