import { faBell } from '@fortawesome/free-regular-svg-icons';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import useFocus from '../CustomHooks/useFocus';
import useRestAPI from '../CustomHooks/useRestAPI';
import RoleChangeDialog from '../Roles/RoleChangeDialog';
import { RolesContext } from '../Roles/RolesContext';
import INotification from '../ServerEntities/INotification';

import Notification from '../SharedComponents/Notification';
import { SessionContext } from '../Views/SessionContext';
import Button from './Button';
import IconButton from './IconButton';
import LoadingIndicator from './LoadingIndicator';
import Menu from './Menu/Menu';
import UserMenu from './UserMenu';

import * as messageService from "../Views/MessageService";

interface IHeaderProps {
    backgroundColor: string
};

const Banner = styled.header`
    background: ${(props: IHeaderProps) => props.backgroundColor};
    display: flex;
    flex-direction: row;
    padding: 12px;
`;

const RowPadding = styled.div`
    flex: 1 0 auto;
`;

const NotificationsMenuTitle = styled.h2`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e5e5e5;
    padding: 0px 12px 12px;
`;

interface IProps {
    backgroundColor: string,
    previousPageUrl?: string,
    onBack?: () => void
};

const Header = (props: IProps) => {
    const { backgroundColor, previousPageUrl, onBack } = props;
    const navigate = useNavigate();
    const roleDialogRef = React.useRef(null as unknown as HTMLDivElement);
    const notificationButtonRef = React.useRef(null as unknown as HTMLDivElement);
    const notificationsRef = React.useRef(null as unknown as HTMLDivElement);
    const [roleChangeDialogOpen, setRoleChangeDialogOpen] = React.useState(false);
    const [notificationMenuOpen, setNotificationMenuOpen] = React.useState(false);
    const sessionContext = React.useContext(SessionContext);
    const { state: rolesState, dispatch: rolesDispatch } = React.useContext(RolesContext);
    const { role } = rolesState;
    const messageDispatch = sessionContext.dispatch;
    const { refreshMessages } = sessionContext.state;

    const goBack = (url: string) => () => {
        navigate(url);
        if (onBack) {
            onBack();
        }
    };

    const findNumberOfUnreadNotifications = () => {
        return data ? data.length : 0;
    };

    const closeRoleChangeDialog = () => {
        setRoleChangeDialogOpen(false);
    };

    const closeNotificationMenu = () => {
        setNotificationMenuOpen(false);
    };

    const toggleNotificationMenu = () => {
        if (!notificationMenuOpen) {
            setNotificationMenuOpen(true);
            useFocus(notificationsRef);
        } else {
            setNotificationMenuOpen(false);
        }
    };

    const signOut = () => {
        sessionContext.dispatch({ type: "clearWebToken", payload: undefined });
        rolesDispatch({ type: "clearRole" });
    };

    const openRoleChangeDialog = () => {
        setRoleChangeDialogOpen(true);
        useFocus(roleDialogRef);
    };

    const downloadMessages = () => {
        messageDispatch({ type: "refreshMessages", payload: undefined });
    };

    React.useEffect(() => {
        downloadMessages();
    }, []);

    const { loading, data, error } = useRestAPI("notifications", "GET", {}, sessionContext.state.webToken, refreshMessages) as { data: INotification[], loading: boolean, error: string };
    const [errorMessage, setErrorMessage] = React.useState("");

    const removeNotificationFromMenu = (index: number) => () => {
        data.splice(index, 1);
        downloadMessages();
    };

    const removeNotificationFailed = (errorMessageFromRequest: string) => {
        setErrorMessage(errorMessageFromRequest);
    };

    const removeMessage = (messageId: number, index: number) => () => {
        messageService.removeMessage(messageId, sessionContext.state.webToken, removeNotificationFromMenu(index), removeNotificationFailed);
    };

    const deleteMessages = () => {
        const messageIDsToDelete = data.map(item => item.id);
        if (messageIDsToDelete.length) {
            messageService.deleteMessages(messageIDsToDelete, sessionContext.state.webToken, downloadMessages);
        }
    };

    const handleNotificationKeyDown = (isFirst: boolean, isLast: boolean) => (event: any) => {
        const key = event.key || event.keyCode;
        if (key === "Tab") {
            if (event.shift && isFirst) {
                notificationButtonRef.current.focus();
                setNotificationMenuOpen(false);
            }
            if (!event.shift && isLast) {
                notificationButtonRef.current.focus();
                setNotificationMenuOpen(false);
            }
        }
    };

    const createNotification = (dataLength: number) => (item: INotification, index: number) => {
        return <Notification key={`Notification-${item.id}`}
            id={item.id}
            title={item.title || ""}
            text={item.text}
            created={item.created}
            seen={item.seen || false}
            handleKeyDown={handleNotificationKeyDown(index === 0, index === dataLength - 1)}
            onDelete={removeMessage(item.id, index)}
        />;
    };

    return <Banner backgroundColor={backgroundColor}>
        <RoleChangeDialog
            dialogRef={roleDialogRef}
            open={roleChangeDialogOpen}
            onClose={closeRoleChangeDialog}
            applicationName="InternalSystem" />
        {previousPageUrl && <Button style={{ border: "0 none", fontSize: "1.1rem" }} plain={true} color="white" onClick={goBack(previousPageUrl)}>
            <FontAwesomeIcon icon={faArrowLeft} /> Back
        </Button>}
        <img className="logo" src="/images/slate-white.png" height={30} />
        <RowPadding />
        <IconButton onClick={toggleNotificationMenu} refObject={notificationButtonRef} notificationCount={findNumberOfUnreadNotifications()} style={{ margin: "0 16px" }}>
            <FontAwesomeIcon color="white" icon={faBell} />
        </IconButton>
        {notificationMenuOpen && <Menu menuContainerRef={notificationsRef} onClose={closeNotificationMenu} anchorElement={notificationButtonRef} width={320}>
            <LoadingIndicator show={false} type="Linear" />
            {error && error}
            {errorMessage && errorMessage}
            <NotificationsMenuTitle>Notifications {data && data.length > 1 && <Button plain={true} onClick={deleteMessages}>Clear all</Button>}</NotificationsMenuTitle>
            {data ? data.map(createNotification(data.length)) : undefined}
            {!data || data.length === 0 ? <div style={{ padding: "12px", color: "#aaaaaa" }}>There are no notifications to show at this time.</div> : null}
        </Menu>}
        <UserMenu role={role} username={sessionContext.state.loggedInUser} onChangeRole={openRoleChangeDialog} onSignOut={signOut} />
    </Banner>
};

export default Header;