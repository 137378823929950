import styled from "styled-components";

const Table = styled.div`
display:flex;
flex: 1 1 auto;
flex-direction:column;
border-spacing: 0;
border-collapse: collapse;
font-smoothing: antialiased;
background-color:white;
overflow:hidden;
`;

export default Table;