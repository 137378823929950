import * as React from "react";
import styled from "styled-components";
import { SessionContext } from "../../SessionContext";
import LoadingIndicator from "../../../SharedComponents/LoadingIndicator";
import ErrorText from "../../../SharedComponents/ErrorText";
import IPagedUsers from "../../../ServerEntities/IPagedUsers";
import Button from "../../../SharedComponents/Button";
import { archiveUser, updateUser } from "./UsersService";
import TextInput, { updateText } from "../../../SharedComponents/TextInput";
import Dialog from "../../../SharedComponents/Dialog/Dialog";
import Paginator from "../../../SharedComponents/Table/Paginator";
import IQuoteUser from "../../../ServerEntities/IQuoteUser";
import handleKeyboardSelect from "../../../CustomHooks/handleKeyboardSelect";
import { RolesContext } from "../../../Roles/RolesContext";
import { componentConfiguration } from "../../../UsefulFunctions/getComponentConfiguration";

export const Main = styled.main`
    padding: 50px;
    display: flex;
    flex-direction: column;
    text-align: left;
`;

export const Title = styled.h1`
    font-size: 1.3rem;
    margin: 12px 0px 20px;
`;

const ActionContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;

const Table = styled.table`
    border-collapse: collapse;
`;

const TableFooter = styled.tfoot`
    & td {
        border-top: 1px solid #333333;
        font-weight: 600;
    }
`;

const TableCell = styled.td`
    height: 20px;
`;

interface IProps {
    onAdd: () => void,
    onDelete: () => void,
    onRefresh: () => void,
    pageNumber: number;
    pageSize: number;
    onChangePageNumber: (pageNumber: number) => void,
    users: IPagedUsers
};

const UsersTable = (props: IProps) => {
    const { state } = React.useContext(SessionContext);
    const { configuration } = React.useContext(RolesContext).state;
    const rbac = componentConfiguration("Users", configuration);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const [editing, setEditing] = React.useState("");
    const [deleting, setDeleting] = React.useState("");
    const [forename, setForename] = React.useState("");
    const [surname, setSurname] = React.useState("");
    const [editingUser, setEditingUser] = React.useState(null as unknown as IQuoteUser);
    const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);

    const users = props.users ? props.users.data : [];
    const usersCount = props.users ? props.users.count : 0;

    const saveUser = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setLoading(true);
        editingUser.forename = forename;
        editingUser.surname = surname;
        updateUser(state.webToken, editingUser, () => {
            setLoading(false);
            setError("");
            setEditing("");
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
        });
    };


    const editUser = (user: IQuoteUser) => (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (user.id) {
            setForename(user.forename);
            setSurname(user.surname);
            setEditingUser(user);
            setEditing(user.id);
        }
    };

    const cancelEditUser = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setEditing("");
    };

    const onCloseDeleteDialog = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setShowDeleteDialog(false);
    };

    const onChangeStatus = (id: string, archive: boolean) => (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (archive) {
            setShowDeleteDialog(true);
            setDeleting(id);
        } else {
            changeStatus(id, archive)
        }
    };

    const changeStatus = (id: string, archive: boolean) => {
        setLoading(true);
        setShowDeleteDialog(false);
        archiveUser(state.webToken, id, archive, () => {
            setLoading(false);
            setError("");
            setDeleting("");
            props.onDelete();
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
        });
    };

    const onArchive = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        changeStatus(deleting, true)
    };

    const changeTablePage = (pageNumber: number) => {
        props.onChangePageNumber(pageNumber);
    };

    return <Main>
        <Title>Users</Title>
        <ActionContainer>
            {rbac.create && <Button plain={false} onClick={props.onAdd}>Add new user</Button>}
        </ActionContainer>
        <LoadingIndicator type="Linear" show={loading} />
        {error && <ErrorText>{error}</ErrorText>}
        <Dialog style={{ padding: "32px" }} open={showDeleteDialog} onClose={onCloseDeleteDialog}>
            <h3>Are you sure you want to archive?</h3>
            <ActionContainer>
                <Button onClick={onCloseDeleteDialog} style={{ marginRight: "10px" }}>Cancel</Button>
                <Button onClick={onArchive} color={"#e60000"}>Archive</Button>
            </ActionContainer>
        </Dialog>
        <Table>
            <thead>
                <tr>
                    <th style={{ width: "20%" }}>Initials</th>
                    <th style={{ width: "40%" }}>Forename</th>
                    <th style={{ width: "20%" }}>Surname</th>
                    <th style={{ width: "20%" }}></th>
                </tr>
            </thead>
            <tbody>
                {users.map((user, index) => {
                    const id = user.id ? user.id : "";
                    if (editing === id) {
                        return <tr key={`user-entry=${index}`} style={{ height: "46px" }}>
                            <TableCell>{user.id}</TableCell>
                            <TableCell width={"40%"}>
                                <TextInput onKeyDown={handleKeyboardSelect(saveUser, false)} value={forename} onChange={updateText(setForename)} placeholder="Forename" />
                            </TableCell>
                            <TableCell width={"20%"}>
                                <TextInput onKeyDown={handleKeyboardSelect(saveUser, false)} value={surname} onChange={updateText(setSurname)} placeholder="Surname" />
                            </TableCell>
                            <TableCell width={"20%"}>
                                <ActionContainer>
                                    <Button plain={true} onClick={saveUser}>Save</Button>
                                    <Button plain={true} onClick={cancelEditUser}>Cancel</Button>
                                </ActionContainer>
                            </TableCell>
                        </tr>;
                    } else {
                        return <tr key={`user-entry=${index}`} style={{ height: "46px", backgroundColor: user.archived ? "#ccc" : "" }}>
                            <TableCell>{user.id}</TableCell>
                            <TableCell>{user.forename}</TableCell>
                            <TableCell>{user.surname}</TableCell>
                            <TableCell>
                                {editing === "" &&
                                    <ActionContainer>
                                        {rbac.update && user.archived === false && <Button plain={true} onClick={editUser(user)} style={{ marginRight: "10px" }}>Edit</Button>}
                                        {rbac.archive && user.archived === false && <Button plain={true} style={{ width: "100px" }} onClick={onChangeStatus(id, true)}>Archive</Button>}
                                        {rbac.archive && user.archived === true && <Button plain={true} style={{ width: "100px" }} onClick={onChangeStatus(id, false)}>Unarchive</Button>}
                                    </ActionContainer>}
                            </TableCell>
                        </tr>;
                    }
                })}
            </tbody>
            <TableFooter>
                <tr>
                    <td colSpan={4}>
                        <Paginator
                            pageSize={props.pageSize}
                            onPageChange={changeTablePage}
                            currentPage={props.pageNumber}
                            totalNumberOfRows={usersCount}
                        />
                    </td>
                </tr>
            </TableFooter>
        </Table>
    </Main >;
};

export default UsersTable;