import * as React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { COLORS } from "../config";
import { RolesContext } from "../Roles/RolesContext";
import IConfigurationComponent from "../ServerEntities/IConfigurationComponent";
import Header from "../SharedComponents/Header";

const Menu = styled.ul`
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;

interface IMenuItemProps {
    backgroundColor: string,
    color: string
};

const MenuItem = styled.li`
    width: 240px;
    height: 240px;
    line-height: 240px;
    text-align: center;
    font-size: 2rem;
    color: ${(props: IMenuItemProps) => props.color};
    background-color: ${(props: IMenuItemProps) => props.backgroundColor};
    cursor: pointer;
    margin: 24px;
`;

const MainMenu = () => {
    const navigate = useNavigate();
    const { state: rolesState } = React.useContext(RolesContext);

    const selectOption = (pageName: string) => () => {
        navigate(pageName);
    };

    const getComponentForName = (component: IConfigurationComponent, index: number) => {
        switch (component.name) {
            case "Expenses":
                return <MenuItem key="expenses-menu-item" onClick={selectOption("Expenses")} backgroundColor={COLORS.PRIMARY} color="#ffffff" >Expenses</MenuItem>
            case "Finances":
                return <MenuItem key="finances-menu-item" onClick={selectOption("Finances")} backgroundColor={COLORS.SECONDARY} color="#ffffff">Finances</MenuItem>
            case "Helpdesk":
                return <MenuItem key="helpdesk-menu-item" onClick={selectOption("Helpdesk")} backgroundColor={COLORS.THIRD} color="#ffffff">Helpdesk</MenuItem>
            case "Customers":
                return <MenuItem key="customers-menu-item" onClick={selectOption("Customers")} backgroundColor={COLORS.FOURTH} color="#ffffff">Customers</MenuItem>
            case "Quotes":
                return <MenuItem key="quotes-menu-item" onClick={selectOption("Quotes")} backgroundColor={COLORS.FIFTH} color="#ffffff">Quotes</MenuItem>
            case "Opportunities":
                return <MenuItem key="opportunities-menu-item" onClick={selectOption("Opportunities")} backgroundColor={COLORS.SIXTH} color="#ffffff">Opportunities</MenuItem>
            case "Users":
                return <MenuItem key="users-menu-item" onClick={selectOption("Users")} backgroundColor={COLORS.PRIMARY} color="#ffffff">Users</MenuItem>
        }
    };

    const components = rolesState.configuration ? rolesState.configuration.components : [];

    return <div>
        <Header backgroundColor={COLORS.PRIMARY} />
        <main>
            <Menu>
                {components && components.map(getComponentForName)}
            </Menu>
        </main>
    </div>;
};

export default MainMenu;