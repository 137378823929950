import ICustomerContactDetails from "../ServerEntities/ICustomerContactDetails";
import IOpportunity from "../ServerEntities/IOpportunity";
import IQuote from "../ServerEntities/IQuote";

export const STATUS = ["Saved", "Committed"];

export const isEditableOpportunity = (status: number) => {
    return STATUS[status] !== "Committed"
}

export const getClientByQuoteNumber = (quoteNumber: string, quotes: IQuote[]) => {
    if (quotes) {
        const userObj = quotes.filter(obj => {
            return obj.quoteNumber === quoteNumber
        });
        return userObj && userObj[0].customerId ? userObj[0].customerId : 0;
    }
    return 0;
};

export const getContactIdByName = (contactName: string, quotes: ICustomerContactDetails[]) => {
    if (quotes) {
        const userObj = quotes.filter(obj => {
            return obj.name === contactName
        });
        return userObj && userObj[0].id ? userObj[0].id : 0;
    }
    return 0;
};
