import * as React from "react";
import styled from "styled-components";
import IExpenseForm from "../../ServerEntities/IExpenseForm";
import IPagedExpenseForm from "../../ServerEntities/IPagedExpenseForm";
import Card from "../../SharedComponents/Card";
import Paginator from "../../SharedComponents/Table/Paginator";
import Table from "../../SharedComponents/Table/Table";
import TableBody from "../../SharedComponents/Table/TableBody";
import TableCell from "../../SharedComponents/Table/TableCell";
import TableCellEllipsis from "../../SharedComponents/Table/TableCellEllipsis";
import TableFooter from "../../SharedComponents/Table/TableFooter";
import TableHead from "../../SharedComponents/Table/TableHead";
import TableRow from "../../SharedComponents/Table/TableRow";
import useDateConverter from "../../UsefulFunctions/useDateConverter";

const ReportContainer = styled.div`
    padding: 16px;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
`;

interface IProps {
    data: IPagedExpenseForm,
    tablePage: number,
    tablePageSize: number,
    changeTablePage: (page: number) => void,
    changeTablePageSize: (size: number) => void,
    onSelect: (expenseForm: IExpenseForm) => void
};

const useStateConverter = (id: number) => {
    const statusArray: string[] = ["In progress", "Submitted", "Approved", "Rejected"];
    return statusArray[id];
};

const ExpenseResults = (props: IProps) => {
    const { data, tablePage, tablePageSize, changeTablePage, changeTablePageSize, onSelect } = props;

    const formToRow = (expenseForm: IExpenseForm, index: number) => {
        const formEntries = expenseForm.formEntries.sort((itemA, itemB) => itemA.date - itemB.date);
        const total = formEntries.length ? formEntries.reduce((acc, val) => acc + val.total, 0) : 0;
        const from = formEntries.length ? formEntries[0].date : "";
        const to = formEntries.length ? formEntries[formEntries.length - 1].date : "";

        const openForm = () => {
            onSelect(expenseForm);
        };

        return <TableRow key={`user-activity-report-item-${index}`} onClick={openForm}>
            <TableCellEllipsis hoverText={expenseForm.username} width="30%">{expenseForm.username}</TableCellEllipsis>
            <TableCell width="20%">
                {useDateConverter(from)}
            </TableCell>
            <TableCell width="20%">
                {useDateConverter(to)}
            </TableCell>
            <TableCell width="15%">
                {total}
            </TableCell>
            <TableCell width="15%">{useStateConverter(expenseForm.status)}</TableCell>
        </TableRow>;
    };

    return <ReportContainer>
        <Card style={{ flexDirection: "column", overflowY: "auto" }}>
            {data.expenseForms.length > 0 && <Table>
                <TableHead>
                    <TableRow>
                        <TableCell width="30%">
                            Username
                        </TableCell>
                        <TableCell width="20%" marginRight="-10px">
                            From
                        </TableCell>
                        <TableCell width="20%">
                            To
                        </TableCell>
                        <TableCell width="15%">
                            Total
                        </TableCell>
                        <TableCell width="15%">
                            State
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.expenseForms.map(formToRow)}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <Paginator
                            pageSize={tablePageSize}
                            onPageChange={changeTablePage}
                            onPageSizeChange={changeTablePageSize}
                            currentPage={tablePage}
                            totalNumberOfRows={data ? data.total : 0}
                        />
                    </TableRow>
                </TableFooter>
            </Table>}
        </Card>
    </ReportContainer>;
};

export default ExpenseResults;