import * as React from "react";
import styled from "styled-components";

const TitleBarContainer = styled.div`
display:flex;
flex-direction:row;
padding: 24px 16px;
justify-content:space-between;
align-items:center;
border-bottom:1px solid #e5e5e5;
flex: 0 0 auto;
`;

const TextContainer = styled.div`
`;

const Title = styled.h1`
margin:0px;
font-size: 17px;
line-height:22px;
font-weight:500;
`;

const Description = styled.p`
margin:0px;
font-size: 14px;
line-height:19px;
`;

interface IProps {
    viewName: string;
    viewDescription: string;
    children?: React.ReactNode;
    style?: React.CSSProperties
}

const TitleBar = (props: IProps) => {
    return <TitleBarContainer style={props.style} className="TitleBarContainer">
        <TextContainer>
            <Title>{props.viewName}</Title>
            <Description>{props.viewDescription}</Description>
        </TextContainer>
        {props.children}
    </TitleBarContainer>
}

export default TitleBar;