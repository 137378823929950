import styled from "styled-components";

const DialogTitle = styled.h1`
    flex: 0 0 auto;
    margin: 0;
    padding: 24px 24px 20px;
    color: rgba(0, 0, 0, 0.87);
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0.0075em;
`;

export default DialogTitle;
