import * as React from "react"
import styled, { keyframes } from "styled-components";

const rippleFadeInDurationInMs = 200;

enum RippleState {
    fadingIn,
    fadingOut
};

interface IRipple {
    position: { x: number, y: number };
    size: number,
    color?: string,
    state: RippleState
};

const expandRipple = keyframes`
0%{
    transform: scale(0);
}
  100% {
    transform: scale(2);
  }
`;

const fadeInRipple = keyframes`
0%{
    opacity:0;
}
  100% {
    opacity: .5;
  }
`;

const fadeOutRipple = keyframes`
0%{
    opacity:.4;
    transform: scale(2);
}
100%{
    opacity:0;
    transform: scale(2);
}
`;

const RippleItem = styled.div`
  position: absolute;
  left: ${(props: IRipple) => `${props.position.x}px`};
  top: ${(props: IRipple) => `${props.position.y}px`};
  width: ${(props: IRipple) => `${props.size}px`};
  height: ${(props: IRipple) => `${props.size}px`};
  background: ${(props: IRipple) => props.color || 'white'};
  border-radius: 50%;
  user-select: none;
  pointer-events: none;
  transform: scale(0);

  &.fade-in{
    animation: ${expandRipple} ${rippleFadeInDurationInMs}ms linear forwards , ${fadeInRipple} 0.075s linear forwards;
  }
  &.fade-out{
    animation: ${fadeOutRipple} 0.15s linear;
  }
`;

interface IProps {
    color?: string,
    children?: any,
    style?: object
}

const initialRippleState: IRipple = {
    color: "",
    position: { x: 0, y: 0 },
    size: 0,
    state: RippleState.fadingOut
}

interface IRippleComponentState {
    ripple: IRipple,
    animationDone: boolean,
    mouseDown: boolean,
}

class Ripple extends React.Component<IProps, IRippleComponentState>{
    private timeoutHolder: NodeJS.Timeout | null;
    private rippleContainerReference?: HTMLDivElement;
    constructor(props: IProps) {
        super(props);
        this.timeoutHolder = null;
        this.fadeOut = this.fadeOut.bind(this);
        this.createRipple = this.createRipple.bind(this);
        this.flagMouseAsUp = this.flagMouseAsUp.bind(this);
        this.storeRippleContainerReference = this.storeRippleContainerReference.bind(this);
        this.flagAnimationAsDone = this.flagAnimationAsDone.bind(this);
        this.state = {
            animationDone: false,
            mouseDown: false,
            ripple: initialRippleState
        }
    }

    public componentWillUnmount() {
        if (this.timeoutHolder) {
            clearTimeout(this.timeoutHolder);
        }
    }

    public componentDidUpdate(prevProps: IProps, prevState: IRippleComponentState) {
        if (prevState.ripple.state === RippleState.fadingIn && this.state.animationDone && !this.state.mouseDown) {
            this.fadeOut();
        }
    }

    public render() {
        return <div
            style={{
                ...this.props.style,
                overflow: "hidden",
                position: "relative",
            }}
            onMouseDown={this.createRipple}
            onMouseUp={this.flagMouseAsUp}
            onMouseLeave={this.flagMouseAsUp}
            ref={this.storeRippleContainerReference}
        >
            {this.props.children}
            <RippleItem
                {...this.state.ripple}
                color={this.props.color || "white"}
                className={`Ripple ${this.state.ripple.state === RippleState.fadingIn ? "fade-in" : this.state.ripple.state === RippleState.fadingOut ? "fade-out" : ""}`}
            />
        </div>
    }

    private fadeOut() {
        this.setState({ ...this.state, ripple: { ...this.state.ripple, state: RippleState.fadingOut } });
    }

    private flagMouseAsUp() {
        this.setState({ ...this.state, mouseDown: false });
    }

    private storeRippleContainerReference(reference: HTMLDivElement) {
        if (reference) {
            this.rippleContainerReference = reference;
        }
    }

    private flagAnimationAsDone() {
        this.setState({ ...this.state, animationDone: true })
    }

    private createRipple(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        if (this.rippleContainerReference) {
            this.timeoutHolder = setTimeout(this.flagAnimationAsDone, rippleFadeInDurationInMs);
            const { width, height, left, top } = this.rippleContainerReference.getBoundingClientRect();
            const size = Math.max(width, height);
            const position = {
                x: event.clientX - left - (size / 2),
                y: event.clientY - top - (size / 2),
            }
            this.setState({
                ...this.state,
                animationDone: false,
                mouseDown: true,
                ripple: {
                    color: this.props.color,
                    position,
                    size,
                    state: RippleState.fadingIn
                }
            })
        }
    }

}

export default Ripple;