import { useEffect } from 'react';

// Hook
function useOnClickOutside(ref: { current: HTMLElement }, linkRef: { current: HTMLElement }, handler: (event: Event) => void) {
    useEffect(
        () => {
            const listener = (event: Event) => {
                // Do nothing if clicking ref's element or descendent elements
                if (!ref.current || ref.current.contains(event.target as Node) || linkRef.current === event.target) {
                    return;
                }

                handler(event);
            };

            document.addEventListener('click', listener);

            return () => {
                document.removeEventListener('click', listener);
            };
        },
        [ref, linkRef, handler]
    );
}

export default useOnClickOutside;
