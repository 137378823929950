type Row = Array<string | Date | number | undefined | boolean>

const useDataSorter = (sortDataByColumn?: number, sortDescending?: boolean) => (row1: Row, row2: Row) => {
    if (sortDataByColumn === undefined) {
        return 0;
    }
    const columnIndexToCompare = sortDataByColumn;
    let sortDirectionModifier: number = 0;
    let valueToReturn: number = 0;
    if (!sortDescending) {
        sortDirectionModifier = -1;
    }
    if (sortDescending) {
        sortDirectionModifier = 1;
    }
    if (columnIndexToCompare === undefined) {
        valueToReturn = 0;
    } else {
        const itemA = row1[columnIndexToCompare];
        const itemB = row2[columnIndexToCompare];

        if (itemA === null && itemB === null) {
            return 0;
        }

        if (itemA === null && itemB !== null) {
            return 1 * sortDirectionModifier;
        }

        if (itemA !== null && itemB === null) {
            return -1 * sortDirectionModifier;
        }


        if ((typeof itemA === "string" && typeof itemB === "string") || (typeof itemA === "number" && typeof itemB === "number")) {
            if (itemA < itemB) {
                valueToReturn = 1;
            }
            if (itemA > itemB) {
                valueToReturn = -1;
            }
        }

        if (typeof itemA === "object" && typeof itemB === "object") {
            if (itemA.getTime() < itemB.getTime()) {
                valueToReturn = 1;
            }
            if (itemA.getTime() > itemB.getTime()) {
                valueToReturn = -1;
            }
        }

        if (typeof itemA === "boolean" && typeof itemB === "boolean") {
            if (itemA === false && itemB === true) {
                valueToReturn = 1;
            }
            if (itemA === true && itemB === false) {
                valueToReturn = -1;
            }
        }
    }
    return valueToReturn * sortDirectionModifier;
};

export default useDataSorter;
