const createErrorMessage = (action: string, error?: string) => {
    if (error) {
        return `There was an error ${action}: ${error}. Please try again later, if this issue persists please contact your IT administrator.`;
    }
    return `There was an error ${action}. Please try again later, if this issue persists please contact your IT administrator.`;
};

export const NETWORK_ERROR = "Unable to connect to server, please try again later. If this error persists contact your IT administrator.";

export interface IErrorResponse {
    message: string
};

export default createErrorMessage;
