import * as React from "react";
import styled from "styled-components";
import Button from "../../SharedComponents/Button";
import DateInput from "../../SharedComponents/DateInput";
import Select from "../../SharedComponents/Select/Select";
import SelectItem from "../../SharedComponents/Select/SelectItem";
import TextInput from "../../SharedComponents/TextInput";
import { isFinancialUser } from "../../UsefulFunctions/roleCheck";
import { validate } from "../../UsefulFunctions/useDateConverter";

const MainContainer = styled.div`
    overflow-x: hidden;
    display: block;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
`;

interface IProps {
    onSearch: () => void,
    setStartDate: (date: Date | null) => void,
    setEndDate: (date: Date | null) => void,
    setDescription: (description: string) => void,
    setStatus: (status: string) => void,
    isAdmin: boolean,
    status: string
};

const Label = styled.p`
    font-size: 14px;
    color: #333333;
    margin-bottom: 5px;
    margin-top: 20px;
`;

const STATUS_OPTIONS = [{
    key: "",
    value: "Any"
}, {
    key: "0",
    value: "In progress"
}, {
    key: "1",
    value: "Submitted"
}, {
    key: 2,
    value: "Approved"
}, {
    key: 3,
    value: "Rejected"
}];

const ExpenseSearch = (props: IProps) => {
    const { setStartDate, setEndDate, onSearch, setDescription, setStatus, isAdmin, status } = props;
    const [startDay, setStartDay] = React.useState("");
    const [startMonth, setStartMonth] = React.useState("");
    const [startYear, setStartYear] = React.useState("");
    const [endDay, setEndDay] = React.useState("");
    const [endMonth, setEndMonth] = React.useState("");
    const [endYear, setEndYear] = React.useState("");
    const [enableButtons, setEnableButtons] = React.useState(false);
    const [desc, setDesc] = React.useState("");

    const getDateFromParts = (day: string, month: string, year: string) => {
        if (year && month && day) {
            return new Date(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10));
        }
        return null;
    };

    const changeStartDay = (e: React.SyntheticEvent<HTMLInputElement>) => {
        const input = e.currentTarget.value;
        if (parseInt(input, 10) > -1 || input === "") {
            setStartDay(input);
            if (input && startMonth && startYear) {
                setStartDate(getDateFromParts(input, startMonth, startYear));
            }
        }
        e.stopPropagation();
        e.preventDefault();
    };

    const changeEndDay = (e: React.SyntheticEvent<HTMLInputElement>) => {
        const input = e.currentTarget.value;
        if (parseInt(input, 10) > -1 || input === "") {
            setEndDay(input);
            setEndDate(getDateFromParts(input, endMonth, endYear));
        }
        e.stopPropagation();
    };

    const changeStartMonth = (e: React.SyntheticEvent<HTMLInputElement>) => {
        const input = e.currentTarget.value;
        if (parseInt(input, 10) > -1 || input === "") {
            setStartMonth(input);
            setStartDate(getDateFromParts(startDay, input, startYear));
        }
        e.stopPropagation();
    };

    const changeEndMonth = (e: React.SyntheticEvent<HTMLInputElement>) => {
        const input = e.currentTarget.value;
        if (parseInt(input, 10) > -1 || input === "") {
            setEndMonth(input);
            setEndDate(getDateFromParts(endDay, input, endYear));
        }
        e.stopPropagation();
    };

    const changeStartYear = (e: React.SyntheticEvent<HTMLInputElement>) => {
        const input = e.currentTarget.value;
        if (parseInt(input, 10) > -1 || input === "") {
            setStartYear(input);
            setStartDate(getDateFromParts(startDay, startMonth, input));
        }
        e.stopPropagation();
    };

    const changeEndYear = (e: React.SyntheticEvent<HTMLInputElement>) => {
        const input = e.currentTarget.value;
        if (parseInt(input, 10) > -1 || input === "") {
            setEndYear(input);
            setEndDate(getDateFromParts(endDay, endMonth, input));
        }
        e.stopPropagation();
    };

    const changeDescription = (e: React.SyntheticEvent<HTMLInputElement>) => {
        const input = e.currentTarget.value;
        setDesc(input);
        setDescription(input);
        e.stopPropagation();
    };

    const invalidStartDate = !validate(startDay, startMonth, startYear, true);
    const invalidEndDate = !validate(endDay, endMonth, endYear, true);

    if (!invalidStartDate && !invalidEndDate && !enableButtons) {
        setEnableButtons(true);
    }

    if ((invalidStartDate || invalidEndDate) && enableButtons) {
        setEnableButtons(false);
    }

    return <div>
        <MainContainer>
            <Label style={{ marginBottom: "20px", marginTop: "20px" }}>Start date</Label>
            <DateInput
                htmlId="start-date"
                invalid={invalidStartDate}
                label=""
                day={startDay}
                month={startMonth}
                year={startYear}
                dayChanged={changeStartDay}
                monthChanged={changeStartMonth}
                yearChanged={changeStartYear}
                margin="0 0 40px 0"
            />
            <Label style={{ marginBottom: "20px" }}>End date</Label>
            <DateInput
                htmlId="end-date"
                invalid={invalidEndDate}
                label=""
                day={endDay}
                month={endMonth}
                year={endYear}
                dayChanged={changeEndDay}
                monthChanged={changeEndMonth}
                yearChanged={changeEndYear}
                margin="0 0 40px 0"
            />
            <Label>Description</Label>
            <TextInput value={desc} onChange={changeDescription} />
            <Label>Status</Label>
            <Select onChange={setStatus} childValues={STATUS_OPTIONS.map(option => option.value)} value={status}>
                {STATUS_OPTIONS.map((option, index) => <SelectItem key={`expense-search-item-${index}`} value={option.key}>{option.value}</SelectItem>)}
            </Select>
            <Button disabled={!enableButtons} onClick={onSearch} margin="40px 0px 0px 0px" style={{ width: "calc(100% - 16px)" }}>Search</Button>
        </MainContainer>
    </div>

};

export default ExpenseSearch;

