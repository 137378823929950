import * as React from "react";
import styled from "styled-components";
import { SessionContext } from "../../SessionContext";

import Button from "../../../SharedComponents/Button";
import ErrorText from "../../../SharedComponents/ErrorText";
import LoadingIndicator from "../../../SharedComponents/LoadingIndicator";
import DynamicSelect from "../../../SharedComponents/Select/DynamicSelect";
import Select from "../../../SharedComponents/Select/Select";
import SelectItem from "../../../SharedComponents/Select/SelectItem";
import TextInput, { updateText } from "../../../SharedComponents/TextInput";
import { Main, Title } from "./QuotesTable";

import { createQuoteNotification, getRelatedQuotes, saveQuote } from "./QuotesService";
import { getCustomers } from "../Customers/CustomersService";
import { getUsers } from "../Users/UsersService";

import ICustomer from "../../../ServerEntities/ICustomer";
import IPagedCustomers from "../../../ServerEntities/IPagedCustomers";
import IPagedUsers from "../../../ServerEntities/IPagedUsers";
import IQuote from "../../../ServerEntities/IQuote";
import IQuoteType from "../../../ServerEntities/IQuoteType";
import IQuoteUser from "../../../ServerEntities/IQuoteUser";

import useDateConverter from "../../../UsefulFunctions/useDateConverter";
import RelatedQuotes from "./RelatedQuotes";
import { COLORS, TRIGGER_QUOTE_CREATED } from "../../../config";
import { createTriggerCreatedQuote } from "../../../UsefulFunctions/createTriggers";

const Row = styled.div`
    display: flex;
    flex-direction: row;
    margin: 12px 0px 15px;
`;

const Label = styled.label`
    color: #333333;
    line-height: 32px;
    min-width: 200px;
`;

const Value = styled.div`
    line-height: 32px;
`;

const ColumnsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const ColumnContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 20px;
`;

interface IProps {
    onAddComplete: () => void,
    types: IQuoteType[]
};

const QuoteAdd = (props: IProps) => {
    const { state } = React.useContext(SessionContext);
    const quoteTypes = props.types;
    const [customer, setCustomer] = React.useState("");
    const [projectName, setProjectName] = React.useState("");
    const [orderOwner, setOrderOwner] = React.useState("");
    const [type, setType] = React.useState("");
    const [projectValue, setProjectValue] = React.useState("");
    const [relatedQuotes, setRelatedQuotes] = React.useState([] as unknown as string[]);
    const [quotes, setQuotes] = React.useState(null as unknown as string[]);
    const [relatedQuoteDetails, setRelatedQuoteDetails] = React.useState("");

    const now = new Date();
    const enteredDate = Date.now();
    const expiryDate = now.setMonth(now.getMonth() + 3);

    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const [customers, setCustomers] = React.useState(null as unknown as ICustomer[])
    const [users, setUsers] = React.useState(null as unknown as IQuoteUser[])

    React.useEffect(() => {
        setLoading(true);
        getCustomers(state.webToken, 100000, 0, (customers: IPagedCustomers) => {
            setLoading(false);
            setError("");
            setCustomers(customers.data);
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage); ''
            setCustomers(null as unknown as ICustomer[]);
        });
        getUsers(state.webToken, 100000, 0, (quoteUsers: IPagedUsers) => {
            setLoading(false);
            setError("");
            setUsers(quoteUsers.data);
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage); ''
            setUsers(null as unknown as IQuoteUser[]);
        });
    }, []);

    React.useEffect(() => {
        setLoading(true);
        const customerId = getCustomerIdBy(customer);
        if (customerId !== undefined) {
            getRelatedQuotes(state.webToken, customerId, (quotes: string[]) => {
                setLoading(false);
                setError("");
                setQuotes(quotes);
            }, (errorMessage: string) => {
                setLoading(false);
                setError(errorMessage); ''
                setQuotes([]);
            });
        }
    }, [customer]);

    const save = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const quote: IQuote = {
            id: 0,
            orderOwner: getUserIdBy(orderOwner),
            quoteNumber: "",
            enteredDate: enteredDate,
            customerId: getCustomerIdBy(customer),
            projectName: projectName,
            projectValue: projectValue,
            orderEnteredBy: state.loggedInUser,
            relatedQuotes: relatedQuotes,
            relatedQuoteDetails: relatedQuoteDetails,
            orderYear: 0,
            orderSequence: 0,
            archived: false,
            expiryDate: expiryDate,
            workAuthorisedBy: "",
            workAuthorisedDate: 0,
            winStatus: "",
            closed: false,
            closedDate: undefined,
            authorised: false,
            typeId: getTypeId()
        };
        setLoading(true);
        saveQuote(state.webToken, quote, (createdQuote: IQuote) => {
            setLoading(false);
            setError("");
            props.onAddComplete();
            createQuoteNotification(
                createTriggerCreatedQuote(
                    createdQuote.id.toString(),
                    createdQuote.quoteNumber,
                    createdQuote.projectName), state.webToken, () => {

                    },
                () => {

                },
            )
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
        });
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
    };

    const onChangeCustomer = (customer: string) => {
        setCustomer(customer);
    };

    const onChangeOrderOwner = (user: string) => {
        setOrderOwner(user);
    };

    const onChangeType = (type: string) => {
        setType(type);
    };

    const getCustomerIdBy = (customer: string) => {
        if (customers) {
            const userObj = customers.filter(obj => {
                return obj.customerName === customer
            });
            return userObj ? userObj[0].id : 0;
        }
    };

    const getUserIdBy = (user: string) => {
        const userObj = users.filter(obj => {
            return obj.id === user
        });
        return userObj ? userObj[0].id : "";
    };

    const getTypeId = () => {
        return quoteTypes.filter(obj => {
            return obj.description === type
        })[0].id;
    };

    const validateForm = () => {
        if (customer.length > 0 && projectName.length > 0 && orderOwner.length > 0 && type.length > 0) {
            return true;
        }
        return false;
    };

    const onUpdateRelatedQuotes = (quotes: string[]) => {
        setRelatedQuotes(quotes);
    }


    return <Main>
        <LoadingIndicator type="Linear" show={loading} />
        {error && <ErrorText>{error}</ErrorText>}
        <Title>Add quote</Title>
        <form>
            <ColumnsContainer>
                <ColumnContainer>
                    <Row>
                        <Label htmlFor="expiryDate">Expiry Date</Label>
                        <Value>{useDateConverter(expiryDate)}</Value>
                    </Row>
                    <Row>
                        <Label htmlFor="customer">Customer</Label>
                        <DynamicSelect required={true} placeholder="Select a customer" onChange={onChangeCustomer} childValues={customers && customers.map(option => option.customerName)} value={customer}>
                            {customers && customers.filter((obj: { status: boolean; }) => { return obj.status === true }).map((option, index) => <SelectItem height="20px" key={`add-quote-customer-item-${index}`} value={option.customerName}>{option.customerName}</SelectItem>)}
                        </DynamicSelect>
                    </Row>
                    <Row>
                        <Label htmlFor="projectName">Project name</Label>
                        <TextInput required={true} width={"500px"} htmlId="location" value={projectName} onChange={updateText(setProjectName)} placeholder="Project name" />
                    </Row>
                    <Row>
                        <Label htmlFor="orderOwner">Order owner</Label>
                        <Select required={true} placeholder="Select a user" onChange={onChangeOrderOwner} childValues={users && users.map(option => option.id)} value={orderOwner}>
                            {users && users.map((option, index) => <SelectItem height="20px" key={`add-quote-order-owner-item-${index}`} value={option.id}>{option.forename} {option.surname}</SelectItem>)}
                        </Select>
                    </Row>
                    <Row>
                        <Label htmlFor="orderEnteredBy">Order entered by</Label>
                        <Value>{state.loggedInUser}</Value>
                    </Row>
                    <Row>
                        <Label htmlFor="type">Type</Label>
                        <Select required={true} placeholder="Select a type" onChange={onChangeType} childValues={quoteTypes && quoteTypes.map(option => option.description)} value={type}>
                            {quoteTypes && quoteTypes.map((option, index) => <SelectItem height="20px" key={`add-quote-type-item-${index}`} value={option.description}>{option.description}</SelectItem>)}
                        </Select>
                    </Row>
                    <Row>
                        <Label htmlFor="quoteNumber">Entered date</Label>
                        <Value>{useDateConverter(enteredDate)}</Value>
                    </Row>
                    <Row>
                        <Label htmlFor="projectValue">Project value</Label>
                        <TextInput required={false} width={"500px"} htmlId="projectValue" value={projectValue} onChange={updateText(setProjectValue)} placeholder="Project value" />
                    </Row>
                </ColumnContainer>
                <ColumnContainer>
                    <Row>
                        <Label htmlFor="relatedQuote">Related quote</Label>
                        <RelatedQuotes quotes={quotes} relatedQuotes={relatedQuotes} updateRelateQuotes={onUpdateRelatedQuotes}></RelatedQuotes>
                    </Row>
                    <Row>
                        <Label htmlFor="relatedQuoteDetails">Related quote details</Label>
                        <TextInput textArea={true} width={"500px"} height={"100px"} required={false} htmlId="relatedQuoteDetails" value={relatedQuoteDetails} onChange={updateText(setRelatedQuoteDetails)} placeholder="Related quote details" />
                    </Row>
                </ColumnContainer>
            </ColumnsContainer>
            <ButtonContainer>
                <Button color={COLORS.FIFTH} disabled={!validateForm()} onClick={save}>Save quote</Button>
                <Button plain={true} style={{ marginLeft: "8px" }} onClick={props.onAddComplete}>Cancel</Button>
            </ButtonContainer>
        </form>
    </Main >
};

export default QuoteAdd;
