import axios, { AxiosError, AxiosResponse } from "axios";

import { getAPIUrl } from '../config';
import IRoleAndJwt from '../ServerEntities/IRoleAndJwt';
import { IErrorResponse, NETWORK_ERROR } from '../UsefulFunctions/createErrorMessage';

export const changePreferredRole = (roleName: string, applicationName: string, webToken: string, successCallback: (jwt: string) => void, failureCallback: (errorMessage: string) => void) => {
    axios.put(
        `${getAPIUrl()}dashboard/preferredRole`,
        {
            app: applicationName,
            role: roleName
        },
        { headers: { Authorization: `Bearer ${webToken}`, "Content-Type": "application/json" } }
    ).then((response: AxiosResponse<string>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<IErrorResponse, any>) => {
        failureCallback(workoutErrorMessage(error));
    });
};

export const getRoles = (applicationName: string, webToken: string, successCallback: (data: string[]) => void, failureCallback: (errorMessage: string) => void) => {
    axios.get(
        `${getAPIUrl()}dashboard/${applicationName}/roles`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-Type": "application/json" } }
    ).then((response: AxiosResponse<string[]>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<IErrorResponse, any>) => {
        failureCallback(workoutErrorMessage(error));
    });
};

export const getPreferredRole = (applicationName: string, webToken: string, successCallback: (preferredRole: IRoleAndJwt) => void,
    failureCallback: (errorMessage: string, errorCode?: number) => void) => {

    axios.get(
        `${getAPIUrl()}dashboard/${applicationName}/preferredRole`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-Type": "application/json" } }
    ).then((response: AxiosResponse<{ jwt: string, role: string, username: string }>) => {
        const roleToUse = response.data.role;

        if (roleToUse !== undefined && roleToUse !== null) {
            successCallback({
                jwt: response.data.jwt,
                role: roleToUse,
                username: response.data.username
            });
        } else {
            if (response.data.role === "") {
                failureCallback("You have no role assigned to your user account. Please contact your IT administrator for assistance.")
            } else {
                failureCallback(`You have an unknown role "${response.data}" assigned to your user account. Please contact your IT administrator for assistance.`)
            }
        }
    }).catch((error: AxiosError<IErrorResponse, any>) => {
        failureCallback(workoutErrorMessage(error), error.response ? error.response.status : 404);
    });
};

const workoutErrorMessage = (error: AxiosError<IErrorResponse, any>) => {
    let errorMessage = "Unknown error";
    if (error.response) {
        if (error.response.status === 409) {
            errorMessage = "List already exists";
        } else {
            errorMessage = error.response.data.message
        }
    }
    if (error.message === "Network Error") {
        errorMessage = NETWORK_ERROR;
    }
    return errorMessage as string
};
