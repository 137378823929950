import * as React from "react";
import styled from "styled-components";

import handleKeyboardSelect from "../../CustomHooks/handleKeyboardSelect";
import Ripple from "../Ripple";

interface ITableRowProps {
    onClick?: () => void,
    children?: React.ReactNode;
    selected?: boolean;
    style?: React.CSSProperties;
    deceased?: string;
    minHeight?: string;
};

const TableRowContainer = styled.div`
    color: inherit;
    min-height: ${({ minHeight, clickable }: { minHeight?: string, clickable?: boolean }) => minHeight || "48px"};
    outline: none;
    vertical-align: middle;
    display: flex;
    flex-direction: row;
    flex: 0 0 auto;
    width: 100%;
    align-items: stretch;
    justify-content: flex-start;
    cursor:${({ minHeight, clickable }: { minHeight?: string, clickable?: boolean }) => clickable ? "pointer" : ""};
    &.selected {
        background-color: rgba(24,127,186,0.05);
    }
    &.deceased {
        background-color: lightgray;
    }
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    &:hover, &:focus {
        ${({ minHeight, clickable }: { minHeight?: string, clickable?: boolean }) => clickable ? "background-color: rgba(24,127,186,0.05);" : ""
    }
    &.deceased:hover, &.deceased:focus {
        ${({ minHeight, clickable }: { minHeight?: string, clickable?: boolean }) => clickable ? "background-color: rgba(10,10,10,0.05);" : ""
    }
`;

const TableRow = (props: ITableRowProps) => {
    const { onClick, children, deceased, selected, style } = props;
    if (onClick) {
        const tableRowStyle = { ...style, display: "flex", flexDirection: "row", flex: "1 1 auto" }
        return <TableRowContainer
            tabIndex={0}
            className={`TableRow ${deceased ? "deceased" : ""} ${selected ? "selected" : ""}`}
            clickable={true}
            minHeight={props.minHeight}
            onKeyDown={handleKeyboardSelect(onClick, true)}
            onClick={onClick}>
            <Ripple color="rgba(24,127,186,0.5)" style={tableRowStyle}>
                {children}
            </Ripple>
        </TableRowContainer>
    } else {
        return <TableRowContainer className={`TableRow ${deceased ? "deceased" : ""} ${selected ? "selected" : ""}`} {...props}>
            {children}
        </TableRowContainer>
    }
}

export default TableRow;