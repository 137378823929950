import * as React from "react";
import styled from "styled-components";
import { SessionContext } from "../../SessionContext";
import Button from "../../../SharedComponents/Button";
import LoadingIndicator from "../../../SharedComponents/LoadingIndicator";
import TextInput, { updateText } from "../../../SharedComponents/TextInput";
import ICustomer from "../../../ServerEntities/ICustomer";
import { addDetails, deleteCustomerContactDetails, getCustomerContacts, getCustomers, saveCustomer, updateCustomer, updateDetails } from "./CustomersService";
import ErrorText from "../../../SharedComponents/ErrorText";
import { Main, Title } from "./CustomersTable";
import handleKeyboardSelect from "../../../CustomHooks/handleKeyboardSelect";
import Table from "../../../SharedComponents/Table/Table";
import TableCell from "../../../SharedComponents/Table/TableCell";
import ICustomerContactDetails from "../../../ServerEntities/ICustomerContactDetails";
import Checkbox from "../../../SharedComponents/Checkbox";
import Dialog from "../../../SharedComponents/Dialog/Dialog";
import { RolesContext } from "../../../Roles/RolesContext";
import { componentConfiguration } from "../../../UsefulFunctions/getComponentConfiguration";
import ICustomerReplaced from "../../../ServerEntities/ICustomerReplaced";
import DynamicSelect from "../../../SharedComponents/Select/DynamicSelect";
import SelectItem from "../../../SharedComponents/Select/SelectItem";
import IPagedCustomers from "../../../ServerEntities/IPagedCustomers";
import { getCustomerIdBy } from "../../../UsefulFunctions/customerUtils";
import { COLORS } from "../../../config";

const Row = styled.div`
    display: flex;
    flex-direction: row;
    margin: 12px 0px 30px;
`;

const Label = styled.label`
    color: #333333;
    line-height: 32px;
    min-width: 140px;
`;

const ButtonContainer = styled.div`
    text-align: right;
`;

const ActionContainer = styled.div`
    height: 24px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex: 1 1 100%;
`;

const PrimaryContactsContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
`;

const CheckboxContainer = styled.div`
    width: 90px;
    text-align: center;
`;

const TableCellDiv = styled.td`
    width: 40%;
    min-height: 48px;
    overflow: hidden;
    font-size: 14px;
    line-height:19px;
    border-bottom: 1px solid ${({ noBorder }: { noBorder?: boolean }) => noBorder ? "transparent" : "#e5e5e5"};
    font-weight: 500;
    color: inherit;
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
    align-items: center;
    min-width: 0;
`;

const Value = styled.div`
    line-height: 32px;
`;

const LinkedCustomerContainer = styled.div`
    line-height: 42px;
     display: flex;
    flex: 0 0 auto;
    flex-direction: row;
    justify-content: space-between;
`;



interface IProps {
    onAddComplete: () => void,
    customer: ICustomer;
};

interface ICustomerProps {
    onSave: (contact: ICustomerContactDetails) => void,
    contact: ICustomerContactDetails;
};

const PrimaryContacts = (props: ICustomerProps) => {
    const [contact, setContact] = React.useState(props.contact);

    const onChangeArch = (checked: boolean) => {
        contact.primaryArch = checked;
        save(contact);
    };

    const onChangeDev = (checked: boolean) => {
        contact.primaryDev = checked;
        save(contact);
    };

    const onChangeFinance = (checked: boolean) => {
        contact.primaryFinance = checked;
        save(contact);
    };

    const onChangePmo = (checked: boolean) => {
        contact.primaryPmo = checked;
        save(contact);
    };

    const onChangeSales = (checked: boolean) => {
        contact.primarySales = checked;
        save(contact);
    };

    const save = (contact: ICustomerContactDetails) => {
        setContact(contact);
        props.onSave(contact);
    };

    return <PrimaryContactsContainer>
        <Checkbox checked={contact.primaryArch} onChange={onChangeArch} />
        <Checkbox checked={contact.primaryDev} onChange={onChangeDev} />
        <Checkbox checked={contact.primaryFinance} onChange={onChangeFinance} />
        <Checkbox checked={contact.primaryPmo} onChange={onChangePmo} />
        <Checkbox checked={contact.primarySales} onChange={onChangeSales} />
    </PrimaryContactsContainer>;
};

const CustomerEdit = (props: IProps) => {
    const { state } = React.useContext(SessionContext);
    const { configuration } = React.useContext(RolesContext).state;
    const rbac = componentConfiguration("Customers", configuration);
    const customer = props.customer;
    const [customerName, setCustomerName] = React.useState("");
    const [shortName, setShortName] = React.useState("");
    const [clientId, setClientId] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const [editing, setEditing] = React.useState(-1);
    const [contactName, setContactName] = React.useState("");
    const [contactTitle, setContactTitle] = React.useState("");
    const [contactEmail, setContactEmail] = React.useState("");
    const [contactPhone, setContactPhone] = React.useState("");
    const [contactId, setContactId] = React.useState(0);
    const contactCustomerId = customer.id ? customer.id : 0;
    const [refresh, setRefresh] = React.useState(0);
    const [contactDetails, setContactDetails] = React.useState(null as unknown as ICustomerContactDetails[]);
    const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
    const [deleting, setDeleting] = React.useState(-1);
    const [isEditing, setIsEditing] = React.useState(false);
    const [replaces, setReplaces] = React.useState(customer.replaces as ICustomerReplaced[]);
    const [customers, setCustomers] = React.useState(null as unknown as ICustomer[])
    const [existingCustomers, setExistingCustomers] = React.useState([] as string[]);
    const none = -1;
    const newItem = -2;


    React.useEffect(() => {
        setCustomerName(customer.customerName);
        setShortName(customer.shortName);
        setClientId(customer.clientId);
        setContactId(customer.id ? customer.id : 0);
        if (customer.id) {
            getCustomerContacts(customer.id, state.webToken, (contacts: ICustomerContactDetails[]) => {
                setLoading(false);
                setError("");
                setContactDetails(contacts);
            }, (errorMessage: string) => {
                setLoading(false);
                setError(errorMessage); ''
                setContactDetails(null as unknown as ICustomerContactDetails[]);
            });
        }
    }, [refresh]);

    const save = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        existingCustomers.forEach((customer: string) => { replaces.push({ id: getCustomerIdBy(customer, customers), name: customer }) })
        const customer: ICustomer = {
            customerName: customerName,
            shortName: shortName,
            clientId: clientId,
            id: contactId,
            status: true,
            replaces: replaces
        };
        setLoading(true);
        updateCustomer(state.webToken, customer, () => {
            setLoading(false);
            setError("");
            setIsEditing(false);
            props.onAddComplete();
        }, (errorMessage: string) => {
            setLoading(false);
            setIsEditing(false);
            setError(errorMessage);
        });
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
    };

    const editContact = (contact: ICustomerContactDetails) => (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setEditing(contact.id)
        setContactName(contact.name);
        setContactTitle(contact.jobTitle);
        setContactEmail(contact.email);
        setContactPhone(contact.phone);
        setContactId(contact.id);
    };

    const saveContact = (contact: ICustomerContactDetails) => {
        if (editing === newItem) {
            addDetails(state.webToken, contact, () => {
                console.log("updated");
                setLoading(false);
                setError("");
                setRefresh(refresh + 1);
                setEditing(none);
            }, (errorMessage: string) => {
                console.log("failed to update");
                setLoading(false);
                setError(errorMessage);
                setEditing(none);
            });
        }
        else {
            updateDetails(state.webToken, contact, () => {
                console.log("updated");
                setLoading(false);
                setError("");
                setRefresh(refresh + 1);
                setEditing(none);
            }, (errorMessage: string) => {
                console.log("failed to update");
                setLoading(false);
                setError(errorMessage);
                setEditing(none);
            });
        }
    };

    const saveContactDetails = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setLoading(true);
        const contact: ICustomerContactDetails = {
            name: contactName,
            jobTitle: contactTitle,
            email: contactEmail,
            phone: contactPhone,
            id: contactId,
            customerId: contactCustomerId,
            primaryArch: false,
            primarySales: false,
            primaryFinance: false,
            primaryPmo: false,
            primaryDev: false
        };
        saveContact(contact);
    };

    const addContactDetails = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (e) {
            e.preventDefault();
        }

        setEditing(newItem);
        const contact: ICustomerContactDetails = {
            name: "",
            jobTitle: "",
            email: "",
            phone: "",
            id: newItem,
            customerId: contactCustomerId,
            primaryArch: false,
            primarySales: false,
            primaryFinance: false,
            primaryPmo: false,
            primaryDev: false
        };
        let contactDetailsCopy = [...contactDetails];
        contactDetailsCopy.push(contact);
        setContactDetails(contactDetailsCopy);
    };

    const cancelEditContact = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setEditing(none);
    };

    const onRemove = (id: number) => (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setShowDeleteDialog(true);
        setDeleting(id);
    };

    const onCloseDeleteDialog = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setShowDeleteDialog(false);
    };

    const onDelete = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setLoading(true);
        setShowDeleteDialog(false);
        deleteCustomerContactDetails(state.webToken, deleting, () => {
            setLoading(false);
            setError("");
            setDeleting(none);
            setRefresh(refresh + 1);
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
        });
    };

    const editCustomer = () => {
        setIsEditing(true);
    };


    const removeLinkedCustomer = (id: number) => (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (replaces) {
            let newReplaces = [...replaces];
            newReplaces = newReplaces.filter(function (obj) {
                return obj.id !== id;
            });

            setReplaces(newReplaces);
        }
    }

    React.useEffect(() => {
        setLoading(true);
        getCustomers(state.webToken, 100000, 0, (customers: IPagedCustomers) => {
            setLoading(false);
            setError("");
            setCustomers(customers.data);
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage); ''
            setCustomers(null as unknown as ICustomer[]);
        });
    }, [existingCustomers]);

    const onChangeCustomer = (index: number) => (customer: string) => {
        const existingCustomersCopy = [...existingCustomers];
        existingCustomersCopy[index] = customer;
        setExistingCustomers(existingCustomersCopy);
    };

    const renderReplaceCustomerItem = (existingCustomer: string, index: number) => {
        return <DynamicSelect required={false} placeholder="Select a customer" onChange={onChangeCustomer(index)} childValues={customers && customers.map(option => option.customerName)} value={existingCustomers[index]}>
            {customers && customers.filter((obj: { status: boolean; }) => { return obj.status === true }).map((option, index) => <SelectItem height="20px" key={`add-quote-customer-item-${index}`} value={option.customerName}>{option.customerName}</SelectItem>)}
        </DynamicSelect>
    }

    const addCustomerToReplace = () => {
        const existingCustomersCopy = [...existingCustomers];
        existingCustomersCopy.push("");
        setExistingCustomers(existingCustomersCopy);
    }

    const renderReplaces = (customer: ICustomerReplaced) => {
        return <LinkedCustomerContainer>
            <div>Replaces {customer.id} - {customer.name}</div>
            {isEditing && <Button color={COLORS.FOURTH} onClick={removeLinkedCustomer(customer.id)}>Remove</Button>}
        </LinkedCustomerContainer>
    }

    const renderReplaced = (customer: ICustomerReplaced) => {
        return <div>Replaced by {customer.id} - {customer.name}</div>
    }


    return <Main>
        <LoadingIndicator type="Linear" show={loading} />
        {error && <ErrorText>{error}</ErrorText>}
        <Dialog style={{ padding: "32px" }} open={showDeleteDialog} onClose={onCloseDeleteDialog}>
            <h3>Are you sure you want to delete?</h3>
            <ActionContainer>
                <Button color={COLORS.FOURTH} onClick={onCloseDeleteDialog} style={{ marginRight: "10px" }}>Cancel</Button>
                <Button onClick={onDelete} color={"#e60000"}>Delete</Button>
            </ActionContainer>
        </Dialog>
        <Title>Customer</Title>
        <form>
            <Row>
                <Label htmlFor="customerName">Customer name</Label>
                {!isEditing && <Value>{customerName}</Value>}
                {isEditing && <TextInput required={true} htmlId="customerName" value={customerName} onChange={updateText(setCustomerName)} placeholder="Customer name" />}
            </Row>
            <Row>
                <Label htmlFor="shortName">Short name</Label>
                {!isEditing && <Value>{shortName}</Value>}
                {isEditing && <TextInput required={true} htmlId="shortName" value={shortName} onChange={updateText(setShortName)} placeholder="Short name" />}
            </Row>

            {!isEditing && rbac.update && customer.status && <Button color={COLORS.FOURTH} onClick={editCustomer}>Edit</Button>}
            {isEditing && rbac.update && <Button color={COLORS.FOURTH} onClick={save}>Save customer</Button>}
            {isEditing && rbac.update && <Button plain={true} style={{ marginLeft: "8px" }} onClick={props.onAddComplete}>Cancel</Button>}
        </form>

        <Title style={{ marginTop: "50px" }}>Linked Customers</Title>
        <LinkedCustomerContainer>
            {isEditing && <Button color={COLORS.FOURTH} onClick={addCustomerToReplace}>Add</Button>}
        </LinkedCustomerContainer>
        {replaces && replaces.map(renderReplaces)}
        {customer.replacedBy && renderReplaced(customer.replacedBy)}
        {existingCustomers.map(renderReplaceCustomerItem)}


        <Title style={{ marginTop: "50px" }}>Contacts</Title>

        {contactDetails && <Table>
            <tbody>
                <tr style={{ minHeight: "48px", display: "flex" }} >
                    <TableCell width={"20%"}>Name</TableCell>
                    <TableCell width={"10%"}>Job title</TableCell>
                    <TableCell width={"10%"}>Email</TableCell>
                    <TableCell width={"10%"}>Phone</TableCell>
                    <TableCellDiv width={"40%"}>
                        <PrimaryContactsContainer>
                            <CheckboxContainer>Architecture</CheckboxContainer>
                            <CheckboxContainer>Development</CheckboxContainer>
                            <CheckboxContainer>Finance</CheckboxContainer>
                            <CheckboxContainer>PMO</CheckboxContainer>
                            <CheckboxContainer>Sales</CheckboxContainer>
                        </PrimaryContactsContainer>
                    </TableCellDiv>
                    <TableCell width={"10%"}></TableCell>
                </tr>
                {contactDetails.map((contact, index) => {
                    const id = contact.id ? contact.id : none;
                    if (editing === id) {
                        return <tr key={`contact-entry=${index}`} style={{ minHeight: "48px", display: "flex" }}>
                            <TableCell width={"20%"}>
                                <TextInput width="80" onKeyDown={handleKeyboardSelect(saveContactDetails, false)} value={contactName} onChange={updateText(setContactName)} placeholder="Name" />
                            </TableCell>
                            <TableCell width={"10%"}>
                                <TextInput onKeyDown={handleKeyboardSelect(saveContactDetails, false)} value={contactTitle} onChange={updateText(setContactTitle)} placeholder="Job title" />
                            </TableCell>
                            <TableCell width={"10%"}>
                                <TextInput onKeyDown={handleKeyboardSelect(saveContactDetails, false)} value={contactEmail} onChange={updateText(setContactEmail)} placeholder="Email" />
                            </TableCell>
                            <TableCell width={"10%"}>
                                <TextInput onKeyDown={handleKeyboardSelect(saveContactDetails, false)} value={contactPhone} onChange={updateText(setContactPhone)} placeholder="Phone" />
                            </TableCell>
                            <TableCellDiv>{id !== newItem && <PrimaryContacts contact={contact} onSave={saveContact} />}</TableCellDiv>
                            <TableCell width={"60%"}>
                                <ActionContainer>
                                    {rbac.update && <Button plain={true} onClick={saveContactDetails}>Save</Button>}
                                    <Button plain={true} onClick={cancelEditContact}>Cancel</Button>
                                </ActionContainer>
                            </TableCell>
                        </tr>;
                    } else {
                        return <tr key={`contact-entry=${index}`} style={{ minHeight: "48px", display: "flex" }}>
                            <TableCell width={"20%"}>{contact.name}</TableCell>
                            <TableCell width={"10%"}>{contact.jobTitle}</TableCell>
                            <TableCell width={"10%"}>{contact.email}</TableCell>
                            <TableCell width={"10%"}>{contact.phone}</TableCell>
                            <TableCellDiv><PrimaryContacts contact={contact} onSave={saveContact} /></TableCellDiv>
                            <TableCell width={"20%"}>
                                {editing === none &&
                                    <ButtonContainer>
                                        {rbac.update && <Button plain={true} onClick={editContact(contact)} style={{ marginRight: "10px" }}>Edit</Button>}
                                        {rbac.update && <Button plain={true} onClick={onRemove(id)}>Remove</Button>}
                                    </ButtonContainer>
                                }
                            </TableCell>
                        </tr>;
                    }
                })}
            </tbody>

        </Table>
        }
        <Button color={COLORS.FOURTH} style={{ width: "100px", marginTop: "20px" }} onClick={addContactDetails}>Add contact details</Button>
    </Main>;
};

export default CustomerEdit;
