import * as React from "react";
import styled from "styled-components";
import IPagedExpenseForm from "../../ServerEntities/IPagedExpenseForm";

import ViewContainer from "../../SharedComponents/ViewContainer";
import { SessionContext } from "../SessionContext";
import ExpenseResults from "./ExpenseResults";
import ExpenseSearch from "./ExpenseSearch";
import TitleBar from "./Titlebar"

import { approveExpenseForm, getPagedExpenseForms, rejectExpenseForm } from "./ExpensesService";
import ErrorBox from "../../SharedComponents/ErrorBox";
import EmptyBox from "../../SharedComponents/EmptyBox";
import IExpenseForm from "../../ServerEntities/IExpenseForm";
import Button from "../../SharedComponents/Button";
import LoadingIndicator from "../../SharedComponents/LoadingIndicator";
import Header from "../../SharedComponents/Header";
import IExpenseEntry from "../../ServerEntities/IExpenseEntry";
import { GBP_FORMATTER } from "../../UsefulFunctions/currencyFunctions";
import dateConverter from "../../UsefulFunctions/dateConverter";
import { useNavigate } from "react-router-dom";
import useCookie from "../../CustomHooks/useCookie";


const MainContainer = styled.div`
    overflow-x: hidden;
    display: block;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;
const FormContainer = styled.div`
    padding: 8px 16px;
`;

const DetailContainer = styled.div`
    margin: 24px;
`;
const ActionContainer = styled.div`
    display: block;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    flex-wrap: wrap;
`;

const TitleContainer = styled.div`
    display: block;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    flex-wrap: wrap;
`;


interface IExpenseHistoryProps {
    isAdmin: boolean,
    backUrl: string
};

const ExpenseHistory = (props: IExpenseHistoryProps) => {
    const { isAdmin, backUrl } = props;
    const navigate = useNavigate();
    const { webToken } = React.useContext(SessionContext).state;
    const [loading, setLoading] = React.useState(false);
    const [description, setDescription] = React.useState("");
    const [status, setStatus] = React.useState("");
    const [startDate, setStartDate] = React.useState(null as unknown as Date | null);
    const [endDate, setEndDate] = React.useState(null as unknown as Date | null);
    const [data, setData] = React.useState(null as unknown as IPagedExpenseForm);
    const [error, setError] = React.useState("");
    const [tablePage, setTablePage] = React.useState(0);
    const [tablePageSize, setTablePageSize] = useCookie("auditActivityTablePageSize", "10");
    const pageSizeNumber = parseInt(tablePageSize, 20);
    const [selectedExpenseForm, setSelectedExpenseForm] = React.useState(null as IExpenseForm | null);

    const onSearch = () => {
        setLoading(true);
        setSelectedExpenseForm(null);
        getPagedExpenseForms(isAdmin, description, status, startDate, endDate, pageSizeNumber, tablePage * pageSizeNumber,
            webToken, onDataLoaded, onLoadingError);
    };

    const onDataLoaded = (dataResponse: IPagedExpenseForm) => {
        setData(dataResponse);
        setLoading(false);
        setError("");
    };

    const onLoadingError = (errorMessage: string) => {
        setError(errorMessage);
        setLoading(false);
    };

    const changeTablePageSize = (size: number) => {
        setTablePageSize(size.toString());
        setLoading(true);
        getPagedExpenseForms(isAdmin, description, status, startDate, endDate, size, tablePage * size,
            webToken, onDataLoaded, onLoadingError);
    };

    const changeTablePage = (pageNumber: number) => {
        setTablePage(pageNumber);
        setLoading(true);
        getPagedExpenseForms(isAdmin, description, status, startDate, endDate, pageSizeNumber, pageNumber * pageSizeNumber,
            webToken, onDataLoaded, onLoadingError);
    };

    const openExpenseFormDetail = (expenseForm: IExpenseForm) => {
        setSelectedExpenseForm(expenseForm);
    };

    const onCloseDetail = () => {
        setSelectedExpenseForm(null);
    };

    const entryToRow = (entry: IExpenseEntry, index: number) => {
        return <tr key={`expense-entry=${index}`}>
            <td style={{ width: "20%" }}>{dateConverter(entry.date)}</td>
            <td style={{ width: "5%" }}>{GBP_FORMATTER.format(entry.total)}</td>
            <td style={{ width: "20%" }}>{entry.recRef}</td>
            <td style={{ width: "20%" }}>{entry.description}</td>
            <td style={{ width: "20%" }}>{entry.location}</td>
            <td style={{ width: "5%" }}>{entry.category}</td>
            <td style={{ width: "5%" }}>{entry.miles}</td>
            <td style={{ width: "5%" }}>{entry.chargeable ? "Yes" : "No"}</td>
        </tr>;
    }


    const onApprove = () => {
        if (selectedExpenseForm) {
            setLoading(true);
            approveExpenseForm(selectedExpenseForm.id, webToken, onFormStatusUpdated, onLoadingError);
        }
    }

    const onFormStatusUpdated = (dataResponse: IExpenseForm) => {
        setSelectedExpenseForm(dataResponse);
        setLoading(false);
        setError("");
        onSearch();
    };

    const onReject = () => {
        if (selectedExpenseForm) {
            setLoading(true);
            rejectExpenseForm(selectedExpenseForm.id, webToken, onFormStatusUpdated, onLoadingError);
        }
    }

    const onSendReminder = () => {
        navigate("/Finances/Reminder");
    }

    return <div>
        <TitleBar viewName="Expense form search" viewDescription=""><Button onClick={onSendReminder}>Send reminders</Button></TitleBar>
        <MainContainer>
            <FormContainer>
                <ExpenseSearch
                    onSearch={onSearch}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    setDescription={setDescription}
                    setStatus={setStatus}
                    isAdmin={isAdmin}
                    status={status}
                />
            </FormContainer>
            <ViewContainer>
                {selectedExpenseForm && <DetailContainer>
                    <h3>Expense form details</h3>
                    <table style={{ textAlign: "center" }}>
                        <thead>
                            <tr>
                                <th style={{ width: "20%" }}>Date</th>
                                <th style={{ width: "5%" }}>Total</th>
                                <th style={{ width: "20%" }}>Receipt reference</th>
                                <th style={{ width: "20%" }}>Description</th>
                                <th style={{ width: "20%" }}>Location</th>
                                <th style={{ width: "5%" }}>Type</th>
                                <th style={{ width: "5%" }}>Miles</th>
                                <th style={{ width: "5%" }}>Chargeable</th>
                            </tr>
                        </thead>
                        <tbody>
                            {selectedExpenseForm.formEntries.map(entryToRow)}
                            <tr key={`expense-entry=0`}>
                                <td style={{ width: "20%" }}></td>
                                <td style={{ width: "5%" }}>{GBP_FORMATTER.format(selectedExpenseForm.formEntries.reduce((previous, current) => previous + current.total, 0))}</td>
                                <td colSpan={6} style={{ width: "75%" }}></td>
                            </tr>
                        </tbody>
                    </table>

                    <ActionContainer>
                        <Button margin="5px" onClick={onCloseDetail}>Close</Button>
                        {isAdmin && selectedExpenseForm.status === 1 && <div>
                            <Button margin="5px" color="#2d7d1b" onClick={onApprove}>Approve</Button>
                            <Button margin="5px" color="#d11302" onClick={onReject}>Reject</Button>
                        </div>}
                    </ActionContainer>

                </DetailContainer>}
                <LoadingIndicator type={"Linear"} show={loading}></LoadingIndicator>
                {error && <ErrorBox>{error}</ErrorBox>}
                {!error && data && data.expenseForms.length > 0 && <ExpenseResults
                    data={data}
                    tablePage={tablePage}
                    tablePageSize={pageSizeNumber}
                    changeTablePage={changeTablePage}
                    changeTablePageSize={changeTablePageSize}
                    onSelect={openExpenseFormDetail}
                />}
                {!error && data && data.expenseForms.length == 0 && <EmptyBox>No expenses forms found.</EmptyBox>
                }
            </ViewContainer>
        </MainContainer>
    </div>;
};

export default ExpenseHistory;
