import * as React from "react";
import styled from "styled-components";

import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from '../../SharedComponents/IconButton';
import RowPadding from '../../SharedComponents/RowPadding';
import Tooltip from '../../SharedComponents/Tooltip';

const PaginationContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1 1 auto;
    padding: 0 8px;
`;

const CurrentRecordsText = styled.p`
    white-space: nowrap;
    font-size: 13px;
    color: #4c4c4c;
    margin-right: 8px;
`;

interface IProps {
    pageSize: number,
    onPageChange: (newPage: number) => void,
    onPageSizeChange?: (newPageSize: number) => void
    currentPage: number,
    totalNumberOfRows: number
};

const Paginator = (props: IProps) => {
    const { pageSize, onPageChange, onPageSizeChange, currentPage, totalNumberOfRows } = props;
    const numberOfPages = Math.ceil(totalNumberOfRows / pageSize);

    const gotoFirstPage = () => {
        onPageChange(0);
    };

    const gotoNextPage = () => {
        onPageChange(currentPage + 1);
    };

    const gotoPreviousPage = () => {
        onPageChange(currentPage - 1)
    };

    const gotoLastPage = () => {
        onPageChange(numberOfPages - 1);
    };

    return <PaginationContainer>
        <RowPadding />
        <CurrentRecordsText style={{ marginRight: "16px" }}>{(currentPage * pageSize) + 1} - {(currentPage * pageSize) + pageSize > totalNumberOfRows ? totalNumberOfRows : (currentPage * pageSize) + pageSize} of {totalNumberOfRows}</CurrentRecordsText>
        {numberOfPages > 2 && <Tooltip title="First page" placement="top-start" disabled={currentPage === 0}>
            <IconButton onClick={gotoFirstPage} disabled={currentPage === 0}>
                <FontAwesomeIcon icon={faChevronLeft} style={{ padding: "3px 0 3px 6px", fontSize: "15px", lineHeight: "24px" }} />
                <FontAwesomeIcon icon={faChevronLeft} style={{ padding: "3px 6px 3px 0", fontSize: "15px", lineHeight: "24px" }} />
            </IconButton>
        </Tooltip>}
        <Tooltip title="Previous page" placement="top-start" disabled={currentPage === 0}>
            <IconButton onClick={gotoPreviousPage} disabled={currentPage === 0}>
                <FontAwesomeIcon icon={faChevronLeft} style={{ padding: "3px 6px", fontSize: "15px", lineHeight: "24px" }} />
            </IconButton>
        </Tooltip>
        <Tooltip title="Next page" placement="top-start" disabled={(currentPage + 1) * pageSize > totalNumberOfRows}>
            <IconButton onClick={gotoNextPage} disabled={currentPage + 1 === numberOfPages}>
                <FontAwesomeIcon icon={faChevronRight} style={{ padding: "3px 6px", fontSize: "15px", lineHeight: "24px" }} />
            </IconButton>
        </Tooltip>
        {numberOfPages > 2 && <Tooltip title="Last page" placement="top-start" disabled={currentPage === 0}>
            <IconButton onClick={gotoLastPage} disabled={currentPage + 1 === numberOfPages}>
                <FontAwesomeIcon icon={faChevronRight} style={{ padding: "3px 0 3px 6px", fontSize: "15px", lineHeight: "24px" }} />
                <FontAwesomeIcon icon={faChevronRight} style={{ padding: "3px 6px 3px 0", fontSize: "15px", lineHeight: "24px" }} />
            </IconButton>
        </Tooltip>}
    </PaginationContainer>;
};

export default Paginator;
