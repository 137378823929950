import IExpensesConfig from "../ServerEntities/IExpensesConfig";

export const GBP_FORMATTER = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'GBP'
});

export const calculateVat = (total: number, vat: number, vatRate: number) => {
    if (!vat || !total || !vatRate) {
        return 0;
    }
    if (vat === 9) {
        return 0;
    }
    return GBP_FORMATTER.format(total / (100 + vatRate) * vatRate);
};

export const getMileageCost = (miles: number, expensesConfig: IExpensesConfig) => {
    if (!miles || !expensesConfig) {
        return 0;
    }
    return GBP_FORMATTER.format(miles * expensesConfig.pencePerMile / 100);
};