import * as React from "react";
import { useState } from "react";
import styled from "styled-components";
import TextInput from "./TextInput";

const errorColor = "red";

interface IProps {
    day: string,
    month: string,
    year: string,
    dayChanged: (e: React.SyntheticEvent<HTMLInputElement>) => void,
    monthChanged: (e: React.SyntheticEvent<HTMLInputElement>) => void,
    yearChanged: (e: React.SyntheticEvent<HTMLInputElement>) => void,
    htmlId: string,
    label?: string,
    margin?: string,
    required?: boolean,
    disabled?: boolean,
    invalid?: boolean,
    enterPressed?: () => void
};

const DateOfBirthContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    margin: 0;
    position: relative;
`;

const DateOfBirthInputContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1 1 auto;
`;

interface ITextProps {
    error?: boolean
};

const AssistiveText = styled.p`
    font-size: 10px;
    color: ${(props: ITextProps) => props.error ? errorColor : "#62676B"};
`;

const AssistiveTextRow = styled.div`
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    bottom: -26px;
    width: 100%;
    left: 0;
`;

const Label = styled.p`
    font-size: 1.1rem;
    line-height: 32px;
    color: #62676B;
    margin: 0 24px 0 0;
`;

const DateInput = (props: IProps) => {
    const {
        label,
        disabled,
        required,
        day,
        month,
        htmlId,
        year,
        dayChanged,
        monthChanged,
        yearChanged,
        invalid
    } = props;

    const [error] = useState(false);

    return <DateOfBirthContainer>
        {label && <Label>{label}</Label>}
        <DateOfBirthInputContainer>
            <TextInput htmlId={htmlId} invalid={invalid} disabled={disabled} required={required} value={day} onChange={dayChanged} label="DD" maxLength={2} type="number" width="40px" margin="0 5px 0 0" hideAssistiveText={true} />
            <TextInput invalid={invalid} disabled={disabled} required={required} value={month} onChange={monthChanged} label="MM" maxLength={2} type="number" width="40px" margin="0 5px" hideAssistiveText={true} />
            <TextInput invalid={invalid} disabled={disabled} required={required} value={year} onChange={yearChanged} label="YYYY" maxLength={4} type="number" width="60px" margin="0 5px" hideAssistiveText={true} />
        </DateOfBirthInputContainer>
        <AssistiveTextRow className="AssistiveTextRow">
            {required || (error && required) ? <AssistiveText className="AssistiveText" error={error}>*Required</AssistiveText> : null}
        </AssistiveTextRow>
    </DateOfBirthContainer>;
};

export default DateInput;
