export const VERIFY_ACTIVE_INTERVAL = 120000;

const DEFAULT_PORT = 443;
const DEV_BFF_PORT = 7820;

const hostname = window.location.hostname;
// const hostname = "dockerdevhome";

const isDev = window.location.port === "3000";

export const getAPIUrl = () => {
    if (isDev) {
        return `https://${hostname}:${DEV_BFF_PORT}/`;
    }
    return `https://${hostname}:${DEFAULT_PORT}/api/`;
};

export const getHostUrl = () => {
    if (isDev) {
        return `http://localhost:3000`;
    }
    return `https://internal.restartconsulting.com`;
};
export const TRIGGER_QUOTE_CREATED = "quote-created";
export const TRIGGER_QUOTE_AUTHORISED = "quote-authorised";

export const COLORS = {
    PRIMARY: '#4e3b6b',
    SECONDARY: '#6a437d',
    THIRD: '#624c6b',
    FOURTH: '#58597c',
    FIFTH: '#5680a2',
    SIXTH: '#1d5d90'
};