import * as React from "react";
import styled from "styled-components";
import { SessionContext } from "../../SessionContext";
import LoadingIndicator from "../../../SharedComponents/LoadingIndicator";
import ErrorText from "../../../SharedComponents/ErrorText";
import IPagedCustomers from "../../../ServerEntities/IPagedCustomers";
import Button from "../../../SharedComponents/Button";
import ICustomer from "../../../ServerEntities/ICustomer";
import { archiveCustomer, getCustomersCSV, unarchiveCustomer, updateCustomer } from "./CustomersService";
import TextInput, { updateText } from "../../../SharedComponents/TextInput";
import Dialog from "../../../SharedComponents/Dialog/Dialog";
import Paginator from "../../../SharedComponents/Table/Paginator";
import handleKeyboardSelect from "../../../CustomHooks/handleKeyboardSelect";
import { RolesContext } from "../../../Roles/RolesContext";
import { componentConfiguration } from "../../../UsefulFunctions/getComponentConfiguration";
import ICustomerReplaced from "../../../ServerEntities/ICustomerReplaced";
import { COLORS } from "../../../config";

export const Main = styled.main`
    padding: 50px;
    display: flex;
    flex-direction: column;
    text-align: left;
`;

export const Title = styled.h1`
    font-size: 1.3rem;
    margin: 12px 0px 20px;
`;

const ActionContainer = styled.div`
    height: 24px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex: 1 1 100%;
`;

const ButtonContainer = styled.div`
    text-align: right;
`;

const Table = styled.table`
    border-collapse: collapse;
`;

const TableFooter = styled.tfoot`
    & td {
        border-top: 1px solid #333333;
        font-weight: 600;
    }
`;

const TableCell = styled.td`
    height: 24px;
`;

interface IProps {
    onAdd: () => void,
    onEdit: (quote: ICustomer) => void,
    onView: (quote: ICustomer) => void,
    onDelete: () => void,
    onRefresh: () => void,
    pageNumber: number;
    pageSize: number;
    onChangePageNumber: (pageNumber: number) => void,
    customers: IPagedCustomers
};

const CustomersTable = (props: IProps) => {
    const { state } = React.useContext(SessionContext);
    const { configuration } = React.useContext(RolesContext).state;
    const rbac = componentConfiguration("Customers", configuration);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const [editing, setEditing] = React.useState(-1);
    const [archiving, setArchiving] = React.useState(-1);
    const [customerName, setCustomerName] = React.useState("");
    const [shortName, setShortName] = React.useState("");
    const [editingCustomer, setEditingCustomer] = React.useState(null as unknown as ICustomer);
    const [showArchiveDialog, setShowArchiveDialog] = React.useState(false);
    const none = -1;

    const customers = props.customers ? props.customers.data : [];
    const customersCount = props.customers ? props.customers.count : 0;

    const saveCustomer = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setLoading(true);
        editingCustomer.customerName = customerName;
        editingCustomer.shortName = shortName;
        updateCustomer(state.webToken, editingCustomer, () => {
            setLoading(false);
            setError("");
            setEditing(none);
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
            setEditing(none);
        });
    };

    const viewCustomer = (customer: ICustomer) => (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        props.onView(customer);
    }

    const editCustomer = (customer: ICustomer) => (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        props.onEdit(customer);
    }

    const cancelEditCustomer = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setEditing(none);
    }

    const onCloseArchiveDialog = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setShowArchiveDialog(false);
    }

    const onArchive = (id: number) => (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setShowArchiveDialog(true);
        setArchiving(id);
    }

    const archive = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setLoading(true);
        setShowArchiveDialog(false);
        archiveCustomer(state.webToken, archiving, () => {
            setLoading(false);
            setError("");
            setArchiving(none);
            props.onDelete();
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
        });
    };

    const unarchive = (id: number) => (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setLoading(true);
        setShowArchiveDialog(false);
        unarchiveCustomer(state.webToken, id, () => {
            setLoading(false);
            setError("");
            setArchiving(none);
            props.onDelete();
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
        });
    };

    const changeTablePage = (pageNumber: number) => {
        props.onChangePageNumber(pageNumber);
    };

    const onExport = () => {
        getCustomersCSV(state.webToken);
    };

    const renderReplaces = (customer: ICustomerReplaced) => {
        return <div>Replaces {customer.id} - {customer.name}</div>
    }

    const renderReplaced = (customer: ICustomerReplaced) => {
        return <div>Replaced by {customer.id} - {customer.name}</div>
    }

    return <Main>
        <Title>Customers</Title>
        <ActionContainer>
            <Button color={COLORS.FOURTH} plain={false} onClick={onExport}>Export customer list</Button>
            {rbac.create && <Button color={COLORS.FOURTH} plain={false} style={{ marginLeft: "8px" }} onClick={props.onAdd}>Add new customer</Button>}
        </ActionContainer>
        <LoadingIndicator type="Linear" show={loading} />
        {error && <ErrorText>{error}</ErrorText>}
        <Dialog style={{ padding: "32px" }} open={showArchiveDialog} onClose={onCloseArchiveDialog}>
            <h3>Are you sure you want to archive?</h3>
            <ActionContainer>
                <Button onClick={onCloseArchiveDialog} style={{ marginRight: "10px" }}>Cancel</Button>
                <Button onClick={archive} color={"#e60000"}>Archive</Button>
            </ActionContainer>
        </Dialog>
        <Table>
            <thead>
                <tr>
                    <th style={{ width: "30%" }}>Customer name</th>
                    <th style={{ width: "10%" }}>Short name</th>
                    <th style={{ width: "10%" }}>Client ID</th>
                    <th style={{ width: "30%" }}>Linked customers</th>
                    <th style={{ width: "20%" }}></th>
                </tr>
            </thead>
            <tbody>
                {customers.map((customer, index) => {
                    const id = customer.id ? customer.id : none;
                    if (editing === id) {
                        return <tr key={`customer-entry=${index}`} style={{ height: "40px" }}>
                            <TableCell width={"40%"}>
                                <TextInput onKeyDown={handleKeyboardSelect(saveCustomer, false)} value={customerName} onChange={updateText(setCustomerName)} placeholder="Name" />
                            </TableCell>
                            <TableCell width={"20%"}>
                                <TextInput onKeyDown={handleKeyboardSelect(saveCustomer, false)} value={shortName} onChange={updateText(setShortName)} placeholder="Short name" />
                            </TableCell>
                            <TableCell width={"20%"}>{customer.clientId}</TableCell>
                            <TableCell width={"20%"}>
                                <ActionContainer>
                                    <Button plain={true} onClick={saveCustomer}>Save</Button>
                                    <Button plain={true} onClick={cancelEditCustomer}>Cancel</Button>
                                </ActionContainer>
                            </TableCell>
                        </tr>;
                    } else {
                        return <tr key={`customer-entry=${index}`} style={{ height: "40px", color: customer.status ? "#000" : "#ccc" }}>
                            <TableCell>{customer.customerName}</TableCell>
                            <TableCell>{customer.shortName}</TableCell>
                            <TableCell>{customer.clientId}</TableCell>
                            <TableCell>
                                {customer.replaces && customer.replaces.map(renderReplaces)}
                                {customer.replacedBy && renderReplaced(customer.replacedBy)}
                            </TableCell>
                            <TableCell>
                                {editing === none &&
                                    <ButtonContainer>
                                        {rbac.update && <Button plain={true} onClick={editCustomer(customer)} style={{ marginRight: "10px" }}>Details</Button>}
                                        {rbac.archive && customer.status && <Button plain={true} onClick={onArchive(id)}>Archive</Button>}
                                        {rbac.unarchive && !customer.status && <Button plain={true} onClick={unarchive(id)}>Unarchive</Button>}
                                    </ButtonContainer>
                                }
                            </TableCell>
                        </tr>;
                    }
                })}
            </tbody>
            <TableFooter>
                <tr>
                    <td colSpan={4}>
                        <Paginator
                            pageSize={props.pageSize}
                            onPageChange={changeTablePage}
                            currentPage={props.pageNumber}
                            totalNumberOfRows={customersCount}
                        />
                    </td>
                </tr>
            </TableFooter>
        </Table>
    </Main >;
};

export default CustomersTable;