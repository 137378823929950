import * as React from "react";
import styled from "styled-components";

type TextAlign = "left" | "right" | "center";

interface IProps {
    textAlign?: TextAlign,
    width: string,
    noBorder?: boolean,
    children?: React.ReactNode,
    hoverText: string | number,
    innerStyle?: {},
    outerStyle?: {}
};

const TableCellEllipsisContainer = styled.div`
    overflow: hidden;
    text-align: ${(props: any) => props.textAlign ? props.textAlign : "left"};
    justify-content: ${(props: IProps) => {
            switch (props.textAlign) {
                case "left": return "flex-start";
                case "center": return "center";
                case "right": return "flex-end";
            }
            return "row";
        }};
    font-size: 14px;
    line-height: 19px;
    border-bottom: 1px solid ${({ noBorder }: { noBorder?: boolean }) => noBorder ? "transparent" : "#e5e5e5"};
    font-weight: 500;
    padding: 4px 8px;
    color: inherit;
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: 0;
    width: ${(props: IProps) => props.width ? props.width : ""};
    .SortLabel {
        flex-direction: ${(props: IProps) => {
            switch (props.textAlign) {
                case "left": return "row";
                case "center": return "row";
                case "right": return "row-reverse";
            }
            return "row";
        }};
    }
    p {
        margin: 0px;
        font-size: inherit;
        color: inherit;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    &:first-child {
        padding: 4px 8px 4px 12px;
    }
    &:last-child {
        padding: 4px 12px 4px 8px;
    }
`;

const TextContainer = styled.div`
    margin: 0px;
    padding: 0px;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1 1 auto;
`;

const TableCellEllipsis = (props: IProps) => {
    const title = props.hoverText !== undefined ? props.hoverText + '' : '';
    return <TableCellEllipsisContainer {...props} style={props.outerStyle}>
        <TextContainer style={props.innerStyle} title={title}>
            {props.children}
        </TextContainer>
    </TableCellEllipsisContainer>
}

export default TableCellEllipsis;
