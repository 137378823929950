import axios, { AxiosError, AxiosResponse } from "axios";
import { getAPIUrl } from "../../config";
import IExpenseEntry from '../../ServerEntities/IExpenseEntry';
import IExpenseForm from '../../ServerEntities/IExpenseForm';
import IExpensesConfig from '../../ServerEntities/IExpensesConfig'
import IPagedExpenseForm from "../../ServerEntities/IPagedExpenseForm";
import IUser from '../../ServerEntities/IUser';
import { IErrorResponse, NETWORK_ERROR } from "../../UsefulFunctions/createErrorMessage";

export const getPagedExpenseForms = (isAdmin: boolean, description: string, status: string, startDate: Date | null, endDate: Date | null, limit: number, offset: number, webToken: string,
    successCallback: (report: IPagedExpenseForm) => void, failureCallback: (errorMessage: string) => void) => {

    let url: string = `${getAPIUrl()}expenses/search/`;
    if (isAdmin) {
        url = `${getAPIUrl()}expenses/manage/search/`;
    }
    if (description) {
        url += `;description=${description}`;
    }
    if (status) {
        url += `;status=${status}`;
    }
    if (startDate) {
        url += `;startDate=${startDate.getTime()}`;
    }
    if (endDate) {
        url += `;endDate=${endDate.getTime()}`;
    }
    url += `?limit=${limit}&offset=${offset}`
    axios.get(
        url,
        { headers: { Authorization: `Bearer ${webToken}` } }
    ).then((response: AxiosResponse<IPagedExpenseForm>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<IErrorResponse, any>) => {
        failureCallback(workoutErrorMessage(error));
    });
};

export const getExpensesConfig = (webToken: string, successCallback: (serverConfig: IExpensesConfig) => void, failureCallback: (error: string) => void) => {
    axios.get(
        `${getAPIUrl()}expenses/config`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "text/plain" } }
    ).then((response: AxiosResponse<IExpensesConfig>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const getExpenseFormForUser = (webToken: string, successCallback: (serverConfig: IExpenseForm) => void, failureCallback: (error: string) => void) => {
    axios.get(
        `${getAPIUrl()}expenses/user`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then((response: AxiosResponse<IExpenseForm>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const saveExpense = (webToken: string, expense: IExpenseEntry, successCallback: () => void, failureCallback: (error: string) => void) => {
    axios.post(
        `${getAPIUrl()}expenses/form`,
        expense,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then(successCallback).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const submitExpenses = (webToken: string, formId: number, successCallback: () => void, failureCallback: (error: string) => void) => {
    axios.post(
        `${getAPIUrl()}expenses/${formId}/submit`,
        {},
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then(successCallback).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const approveExpenseForm = (formId: number, webToken: string, successCallback: (form: IExpenseForm) => void, failureCallback: (error: string) => void) => {
    axios.post(
        `${getAPIUrl()}expenses/${formId}/approve`,
        {},
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then((response: AxiosResponse<IExpenseForm>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const rejectExpenseForm = (formId: number, webToken: string, successCallback: (form: IExpenseForm) => void, failureCallback: (error: string) => void) => {
    axios.post(
        `${getAPIUrl()}expenses/${formId}/reject`,
        {},
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then((response: AxiosResponse<IExpenseForm>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const sendReminders = (message: string, users: string[], webToken: string, successCallback: () => void, failureCallback: (error: string) => void) => {
    axios.post(
        `${getAPIUrl()}users/reminders`,
        {
            "message": message,
            "users": users
        },
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then((response: AxiosResponse<IExpenseForm>) => {
        successCallback();
    }).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const getUsers = (webToken: string, successCallback: (users: IUser[]) => void, failureCallback: (error: string) => void) => {
    axios.get(
        `${getAPIUrl()}users`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then((response: AxiosResponse<IUser[]>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const getLastRemindees = (webToken: string, successCallback: (users: string[]) => void, failureCallback: (error: string) => void) => {
    axios.get(
        `${getAPIUrl()}users/remindees`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then((response: AxiosResponse<string[]>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

const workoutErrorMessage = (error: AxiosError<IErrorResponse, any>) => {
    let errorMessage = "Unknown error";
    if (error.response) {
        if (error.response.status === 409) {
            errorMessage = "List already exists";
        } else {
            errorMessage = error.response.data.message
        }
    }
    if (error.message === "Network Error") {
        errorMessage = NETWORK_ERROR;
    }
    return errorMessage as string
};
