import axios, { AxiosError, AxiosResponse } from "axios";
import { getAPIUrl } from "../../../config";
import IPagedUsers from "../../../ServerEntities/IPagedUsers";
import IQuoteUser from "../../../ServerEntities/IQuoteUser";


export const saveUser = (webToken: string, user: IQuoteUser, successCallback: () => void, failureCallback: (error: string) => void) => {
    axios.post(
        `${getAPIUrl()}quotes/users`,
        user,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then(successCallback).catch((error: AxiosError<string>) => {
        if (error.response && error.response.data) {
            failureCallback(error.response.data);
        }
        else {
            failureCallback(error.message);
        }

    });
};

export const getUsers = (webToken: string, limit: number, offset: number, successCallback: (Users: IPagedUsers) => void, failureCallback: (error: string) => void) => {
    axios.get(
        `${getAPIUrl()}quotes/users?limit=${limit}&offset=${offset}`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then((response: AxiosResponse<IPagedUsers>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const updateUser = (webToken: string, user: IQuoteUser, successCallback: () => void, failureCallback: (error: string) => void) => {
    axios.put(
        `${getAPIUrl()}quotes/users`,
        user,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then(successCallback).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const archiveUser = (webToken: string, userId: string, archived: boolean, successCallback: () => void, failureCallback: (error: string) => void) => {
    axios.post(
        `${getAPIUrl()}quotes/users/${userId}/archive`,
        archived,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then(successCallback).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};