import styled from "styled-components";

const EmptyBox = styled.p`
    border-radius: 5px;
    padding: 12px;
    margin: 12px;
    border: 1px solid #000000;
    background-color: white;
    font-size: 15px;
`;

export default EmptyBox;