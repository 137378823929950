import { useEffect } from 'react';

// Hook
const useKeyDown = (handler: (event: KeyboardEvent) => void) => {
    useEffect(
        () => {
            const listener = (event: KeyboardEvent) => {
                handler(event);
            };

            document.addEventListener('keydown', listener);

            return () => {
                document.removeEventListener('keydown', listener);
            };
        },
        [handler]
    );
};

export default useKeyDown;