import * as React from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { COLORS } from "../../../config";
import { RolesContext } from "../../../Roles/RolesContext";
import ICustomer from "../../../ServerEntities/ICustomer";
import IPagedCustomers from "../../../ServerEntities/IPagedCustomers";
import IQuote from "../../../ServerEntities/IQuote";
import IQuoteType from "../../../ServerEntities/IQuoteType";
import Button from "../../../SharedComponents/Button";
import Checkbox from "../../../SharedComponents/Checkbox";
import ErrorText from "../../../SharedComponents/ErrorText";
import Header from "../../../SharedComponents/Header";
import LoadingIndicator from "../../../SharedComponents/LoadingIndicator";
import { componentConfiguration } from "../../../UsefulFunctions/getComponentConfiguration";
import useDateConverter from "../../../UsefulFunctions/useDateConverter";
import { SessionContext } from "../../SessionContext";
import { getCustomers } from "../Customers/CustomersService";
import QuoteEdit from "./QuoteEdit";
import { Main, Title } from "./QuotesTable";

const Row = styled.div`
    display: flex;
    flex-direction: row;
    margin: 12px 0px 15px;
`;

const Label = styled.label`
    color: #333333;
    line-height: 32px;
    min-width: 200px;
`;

const Value = styled.div`
    line-height: 32px;
`;

const ColumnsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const ColumnContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 20px;
`;

interface IProps {
    onEdit: (quote: IQuote) => void,
    types: IQuoteType[],
    quote: IQuote
};

const QuoteDetails = (props: IProps) => {
    const { state } = React.useContext(SessionContext);
    const { configuration } = React.useContext(RolesContext).state;
    const rbac = componentConfiguration("Quotes", configuration);
    const { quote, types } = props;
    const [isEditing, setIsEditing] = React.useState(null as unknown as IQuote | null);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const [customers, setCustomers] = React.useState(null as unknown as ICustomer[])


    React.useEffect(() => {
        getCustomers(state.webToken, 100000, 0, (customers: IPagedCustomers) => {
            setLoading(false);
            setError("");
            setCustomers(customers.data);
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage); ''
            setCustomers(null as unknown as ICustomer[]);
        });
    }, []);

    const edit = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        props.onEdit(quote);
    };

    const doNothing = () => { };

    const getCustomerById = (customerId: number) => {
        if (customers) {
            const userObj = customers.filter(obj => {
                return obj.id === customerId
            });
            return userObj ? userObj[0].customerName : "";
        }
        return "";
    }

    if (isEditing) {
        return <div>
            <Header backgroundColor={COLORS.FIFTH} previousPageUrl={"/quotes"} />
            <LoadingIndicator type="Linear" show={loading} />
            {error && <ErrorText>{error}</ErrorText>}
            <QuoteEdit types={types} quote={isEditing} />
        </div>
    }

    return <div>
        <LoadingIndicator type="Linear" show={loading} />
        {error && <ErrorText>{error}</ErrorText>}
        {quote && <Main>
            <Title>Quote</Title>
            <form>
                <ColumnsContainer>
                    <ColumnContainer>
                        <Row>
                            <Label>Quote number</Label>
                            <Value>{quote.quoteNumber}</Value>
                        </Row>
                        <Row>
                            <Label>Expiry Date</Label>
                            <Value>{useDateConverter(quote.expiryDate)}</Value>
                        </Row>
                        <Row>
                            <Label>Customer</Label>
                            <Value>{quote.customerId && getCustomerById(quote.customerId)}</Value>
                        </Row>
                        <Row>
                            <Label htmlFor="projectName">Project name</Label>
                            <Value>{quote.projectName}</Value>
                        </Row>
                        <Row>
                            <Label>Order owner</Label>
                            <Value>{quote.orderOwner}</Value>
                        </Row>
                        <Row>
                            <Label>Order entered by</Label>
                            <Value>{quote.orderEnteredBy}</Value>
                        </Row>
                        <Row>
                            <Label>Type</Label>
                            <Value>{quote.typeId}</Value>
                        </Row>
                        <Row>
                            <Label>Entered date</Label>
                            <Value>{useDateConverter(quote.enteredDate)}</Value>
                        </Row>
                        <Row>
                            <Label htmlFor="projectValue">Project value</Label>
                            <Value>{quote.projectValue}</Value>
                        </Row>
                    </ColumnContainer>
                    <ColumnContainer>
                        <Row>
                            <Label>Related quote</Label>
                            <Value>{quote.relatedQuotes}</Value>
                        </Row>
                        <Row>
                            <Label htmlFor="relatedQuoteDetails">Related quote details</Label>
                            <Value>{quote.relatedQuoteDetails}</Value>
                        </Row>
                        <Row>
                            <Label>Work authorised by</Label>
                            <Value>{quote.workAuthorisedBy}</Value>
                        </Row>
                        <Row>
                            <Label>Work authorised date</Label>
                            <Value>{quote.workAuthorisedDate ? useDateConverter(quote.workAuthorisedDate) : ""}</Value>
                        </Row>
                        <Row>
                            <Label>Win status</Label>
                            <Value>{quote.winStatus}</Value>
                        </Row>
                        <Row>
                            <Label htmlFor="closed">Closed</Label>
                            <Checkbox htmlId="closed" checked={quote.closed} onChange={doNothing} />
                        </Row>
                        <Row>
                            <Label htmlFor="closedDate">Closed date</Label>
                            <Value>{quote.closedDate && useDateConverter(quote.closedDate)}</Value>
                        </Row>
                    </ColumnContainer>
                </ColumnsContainer>
                <ButtonContainer>
                    {rbac.update && <Button color={COLORS.FIFTH} onClick={edit}>Edit quote</Button>}
                </ButtonContainer>
            </form>
        </Main>}
    </div>;
};

export default QuoteDetails;
