import * as React from "react";
import styled from "styled-components";
import { SessionContext } from "../../SessionContext";
import Button from "../../../SharedComponents/Button";
import LoadingIndicator from "../../../SharedComponents/LoadingIndicator";
import TextInput, { updateText } from "../../../SharedComponents/TextInput";
import { saveUser } from "./UsersService";
import ErrorText from "../../../SharedComponents/ErrorText";
import { Main, Title } from "./UsersTable";
import IQuoteUser from "../../../ServerEntities/IQuoteUser";

const Row = styled.div`
    display: flex;
    flex-direction: row;
    margin: 12px 0px 30px;
`;

const Label = styled.label`
    color: #333333;
    line-height: 32px;
    min-width: 140px;
`;

interface IProps {
    onAddComplete: () => void
};

const UserAdd = (props: IProps) => {
    const { state } = React.useContext(SessionContext);
    const [forename, setForename] = React.useState("");
    const [surname, setSurname] = React.useState("");
    const [id, setId] = React.useState("");
    const [initials, setInitials] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");


    const save = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const user: IQuoteUser = {
            forename: forename,
            surname: surname,
            id: id,
            archived: false
        };
        setLoading(true);
        saveUser(state.webToken, user, () => {
            setLoading(false);
            setError("");
            props.onAddComplete();
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
        });
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
    };

    return <Main>
        <LoadingIndicator type="Linear" show={loading} />
        {error && <ErrorText>{error}</ErrorText>}
        <Title>Add user</Title>
        <form>
            <Row>
                <Label htmlFor="initials">Initials</Label>
                <TextInput required={true} htmlId="initials" value={id} onChange={updateText(setId)} placeholder="Initials" />
            </Row>
            <Row>
                <Label htmlFor="forename">Forename</Label>
                <TextInput required={true} htmlId="forename" value={forename} onChange={updateText(setForename)} placeholder="Forename" />
            </Row>
            <Row>
                <Label htmlFor="surname">Surname</Label>
                <TextInput required={true} htmlId="surname" value={surname} onChange={updateText(setSurname)} placeholder="Surname" />
            </Row>
            <Button onClick={save}>Save user</Button>
            <Button plain={true} style={{ marginLeft: "8px" }} onClick={props.onAddComplete}>Cancel</Button>
        </form>
    </Main>;
};

export default UserAdd;
