import * as React from "react";
import styled from "styled-components";
import { SessionContext } from "../../SessionContext";
import LoadingIndicator from "../../../SharedComponents/LoadingIndicator";
import IOpportunity from "../../../ServerEntities/IOpportunity";
import { getOpenQuotes, getOpportunity, } from "./OpportunitiesService";
import ErrorText from "../../../SharedComponents/ErrorText";
import { Main, Title } from "./OpportunitiesTable";
import IMeetingInformation from "../../../ServerEntities/IMeetingInformation";
import ICustomerContactDetails from "../../../ServerEntities/ICustomerContactDetails";
import Table from "../../../SharedComponents/Table/Table";
import TableCell from "../../../SharedComponents/Table/TableCell";
import { RolesContext } from "../../../Roles/RolesContext";
import useDateConverter from "../../../UsefulFunctions/useDateConverter";
import IQuote from "../../../ServerEntities/IQuote";
import { getClientByQuoteNumber } from "../../../UsefulFunctions/opportunityUtils";
import { getCustomerContacts } from "../Customers/CustomersService";
import "react-datepicker/dist/react-datepicker.css";

const Value = styled.div`
    line-height: 32px;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    margin: 12px 0px 30px;
`;

const Label = styled.label`
    color: #333333;
    line-height: 32px;
    min-width: 140px;
`;

const ContactsContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    min-height: 62px;
`;

const ContactContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
`;
const ContactName = styled.div`
    padding: 5px;
`;

interface IProps {
    opportunity: IOpportunity
};


const OpportunityDetails = (props: IProps) => {
    const { state } = React.useContext(SessionContext);
    const [quoteNumber, setQuoteNumber] = React.useState("");
    const [workRequired, setWorkRequired] = React.useState("");
    const [additionalInfo, setAdditionalInfo] = React.useState("");
    const [dateAdded, setDateAdded] = React.useState(0);
    const [meetings, setMeetings] = React.useState([] as IMeetingInformation[]);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const [customer, setCustomer] = React.useState("");
    const [owner, setOwner] = React.useState("");


    React.useEffect(() => {
        getOpportunity(props.opportunity.id, state.webToken, (op: IOpportunity) => {
            setLoading(false);
            setError("");
            setQuoteNumber(op.quoteNumber);
            setDateAdded(op.dateAdded);
            setWorkRequired(op.workRequired);
            setAdditionalInfo(op.additionalInfo);
            setMeetings(op.meetingInformation);
            setCustomer(op.customer);
            setOwner(op.owner);
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
        });
    }, []);

    const renderContact = (meetingId: number) => (contact: ICustomerContactDetails, index: number) => {
        return <ContactContainer>
            <ContactName>{contact.name}</ContactName>
        </ContactContainer>
    }


    return <Main>
        <LoadingIndicator type="Linear" show={loading} />
        {error && <ErrorText>{error}</ErrorText>}
        <Title>View opportunity</Title>
        <form>
            <Row>
                <Label htmlFor="customer">Customer</Label>
                <Value>{customer}</Value>
            </Row>
            <Row>
                <Label htmlFor="quoteNumber">Quote number</Label>
                <Value>{quoteNumber}</Value>
            </Row>
            <Row>
                <Label htmlFor="orderOwner">Owner</Label>
                <Value>{owner}</Value>
            </Row>
            <Row>
                <Label htmlFor="workRequired">Work required</Label>
                <Value>{workRequired}</Value>
            </Row>
            <Row>
                <Label htmlFor="additionalInfo">Additional info</Label>
                <Value>{additionalInfo}</Value>
            </Row>
            <Row>
                <Label htmlFor="dateAdded">Date added</Label>
                <Value>{useDateConverter(dateAdded)}</Value>
            </Row>
        </form>
        <Title style={{ marginTop: "50px" }}>Meetings</Title>

        {meetings && <Table>
            <tbody>
                <tr style={{ minHeight: "48px", display: "flex" }} >
                    <TableCell width={"10%"}>Date</TableCell>
                    <TableCell width={"30%"}>Free text</TableCell>
                    <TableCell width={"40%"}>Contacts</TableCell>
                    <TableCell width={"20%"}></TableCell>
                </tr>
                {meetings.map((meeting, index) => {
                    return <tr key={`contact-entry=${index}`} style={{ minHeight: "48px", display: "flex", flexDirection: "row" }}>
                        <TableCell width={"10%"}>{useDateConverter(meeting.date)}</TableCell>
                        <TableCell width={"30%"}>{meeting.freeText}</TableCell>
                        <TableCell width={"40%"}>
                            <ContactsContainer>
                                {meeting.contacts.map(renderContact(meeting.id))}
                            </ContactsContainer>
                        </TableCell>
                        <TableCell width={"20%"}></TableCell>
                    </tr>;
                })
                }
            </tbody>
        </Table>}
    </Main>;
};

export default OpportunityDetails;
