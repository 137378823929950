import * as React from "react";
import * as ReactDOM from "react-dom";
import styled from "styled-components";

const modalRoot = document.getElementById('modal-root');

const Presentation = styled.div`
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1300;
    position: fixed;
`;

const MenuOverlay = styled.div`
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    position: fixed;
    background-color: transparent;
`;

interface IProps {
    children: React.ReactNode;
    style?: React.CSSProperties;
    show: boolean;
};

const Modal = (props: IProps) => {

    const stop = (e: React.SyntheticEvent<HTMLDivElement>) => {
        e.stopPropagation();
    };

    if (modalRoot && props.show) {
        return ReactDOM.createPortal(
            <Presentation
                onContextMenu={stop}
                onDoubleClick={stop}
                onDrag={stop}
                onDragEnd={stop}
                onDragEnter={stop}
                onDragExit={stop}
                onDragLeave={stop}
                onDragOver={stop}
                onDragStart={stop}
                onDrop={stop}
                onMouseDown={stop}
                onMouseEnter={stop}
                onMouseLeave={stop}
                onMouseMove={stop}
                onMouseOver={stop}
                onMouseOut={stop}
                onMouseUp={stop}

                onKeyDown={stop}
                onKeyPress={stop}
                onKeyUp={stop}

                onFocus={stop}
                onBlur={stop}

                onChange={stop}
                onInput={stop}
                onInvalid={stop}
                onSubmit={stop}
            >
                {props.children}
                <MenuOverlay style={props.style} />
            </Presentation>,
            modalRoot
        );
    }
    return <span />;
};

export default Modal;
