import * as React from "react";
import styled from "styled-components";
import IExpensesConfig from "../../ServerEntities/IExpensesConfig";
import Button from "../../SharedComponents/Button";
import Checkbox from "../../SharedComponents/Checkbox";
import DateInput from "../../SharedComponents/DateInput";
import ErrorText from "../../SharedComponents/ErrorText";
import GrowableTextArea from "../../SharedComponents/GrowableTextArea";
import LoadingIndicator from "../../SharedComponents/LoadingIndicator";
import TextInput, { updateNumeric, updateText } from "../../SharedComponents/TextInput";
import { calculateVat, getMileageCost } from "../../UsefulFunctions/currencyFunctions";
import { SessionContext } from "../SessionContext";
import { getExpensesConfig, saveExpense } from "./ExpensesService";

const Main = styled.main`
    padding: 24px;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    margin: 20px 0;
`;

const Label = styled.label`
    color: #333333;
    line-height: 32px;
    min-width: 140px;
`;

const TextLabel = styled.p`
    color: #333333;
    line-height: 32px;
    width: 140px;
    margin: 0;
`;

const TextValue = styled.p`
    color: #333333;
    line-height: 32px;
    margin: 0;
`;

const TypeList = styled.ul`
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;

const TypeItem = styled.li`
    border: 1px solid #e5e5e5;
    border-radius: 12px;
    height: 32px;
    line-height: 32px;
    margin: 0 16px 8px 0;
    padding: 0 8px;
    cursor: pointer;
    &:first-child {
        margin-left: 0;
    }
    &.selected {
        background-color: #187fba;
        color: #ffffff;
    }
`;

const getTotalDisplay = (focused: boolean, value: number) => {
    if (value === undefined) {
        return "";
    }
    return focused ? value + "" : value.toFixed(2);
};

const dateToUTC = (date: Date) => Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds());

interface IProps {
    onAddComplete: () => void,
    formId?: number
};

const ExpensesForm = (props: IProps) => {
    const { state } = React.useContext(SessionContext);
    const [day, setDay] = React.useState("");
    const [month, setMonth] = React.useState("");
    const [year, setYear] = React.useState("");
    const [description, setDescription] = React.useState("");
    const [receiptReference, setReceiptReference] = React.useState("");
    const [location, setLocation] = React.useState("");
    const [type, setType] = React.useState("Mileage");
    const [miles, setMiles] = React.useState(0);
    const [vat, setVat] = React.useState(undefined as unknown as number);
    const [total, setTotal] = React.useState(undefined as unknown as number);
    const [inTotal, setInTotal] = React.useState(false);
    const [chargeable, setChargeable] = React.useState(false);
    const [expensesConfig, setExpensesConfig] = React.useState(null as unknown as IExpensesConfig);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");

    React.useEffect(() => {
        setLoading(true);
        getExpensesConfig(state.webToken, (serverConfig) => {
            setLoading(false);
            setError("");
            setExpensesConfig(serverConfig);
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
            setExpensesConfig(null as unknown as IExpensesConfig);
        });
    }, []);

    const changeType = (newType: string) => () => {
        setType(newType);
    };

    const changeDay = (e: React.SyntheticEvent<HTMLInputElement>) => {
        if (parseInt(e.currentTarget.value, 10) > -1 || e.currentTarget.value === "") {
            setDay(e.currentTarget.value);
        }
    };

    const changeMonth = (e: React.SyntheticEvent<HTMLInputElement>) => {
        if (parseInt(e.currentTarget.value, 10) > -1 || e.currentTarget.value === "") {
            setMonth(e.currentTarget.value);
        }
    };

    const changeYear = (e: React.SyntheticEvent<HTMLInputElement>) => {
        if (parseInt(e.currentTarget.value, 10) > -1 || e.currentTarget.value === "") {
            setYear(e.currentTarget.value);
        }
    };

    const toggleInTotal = (focused: boolean) => () => {
        setInTotal(focused);
    };

    const saveExpenseEntry = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const entry = {
            date: dateToUTC(new Date(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10))),
            expenseFormId: props.formId,
            recRef: receiptReference,
            description,
            location,
            miles,
            total: type === "Mileage" ? (miles * expensesConfig.pencePerMile / 100) : total,
            vatCode: type === "Mileage" ? 1 : vat,
            chargeable,
            category: type
        };
        setLoading(true);
        saveExpense(state.webToken, entry, () => {
            setLoading(false);
            setError("");
            props.onAddComplete();
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
        });
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
    };

    const isFormValid = () => {
        if (day === "" || month === "" || year === "" || description === "" || location === "") {
            return false;
        }
        if (type === "Mileage") {
            return miles > 0;
        }
        return vat !== undefined && total !== undefined;
    };

    return <Main>
        <LoadingIndicator type="Linear" show={loading} />
        {error && <ErrorText>error</ErrorText>}
        <form>
            <Row>
                <Label>Type</Label>
                <TypeList>
                    {expensesConfig && expensesConfig.categories.map((category) => {
                        return <TypeItem key={`type-item-${category}`} className={type === category ? "selected" : ""} onClick={changeType(category)}>
                            {category}
                        </TypeItem>;
                    })}
                </TypeList>
            </Row>
            <Row>
                <Label htmlFor="date-day">Date</Label>
                <DateInput required={true} htmlId="date-day" day={day} month={month} year={year} dayChanged={changeDay} monthChanged={changeMonth} yearChanged={changeYear} />
            </Row>
            <Row>
                <Label htmlFor="description">Description</Label>
                <GrowableTextArea required={true} htmlId="description" text={description} setText={setDescription} placeholder="Description" />
            </Row>
            <Row>
                <Label htmlFor="receipt-reference">Receipt reference</Label>
                <TextInput htmlId="receipt-reference" value={receiptReference} onChange={updateText(setReceiptReference)} placeholder="Receipt reference" />
            </Row>
            <Row>
                <Label htmlFor="location">Location</Label>
                <TextInput required={true} htmlId="location" value={location} onChange={updateText(setLocation)} placeholder="Client/Exeter etc." />
            </Row>
            {type === "Mileage" && <Row>
                <Label htmlFor="miles">Miles</Label>
                <TextInput required={true} htmlId="miles" value={miles + ""} onChange={updateNumeric(setMiles)} type="number" />
            </Row>}
            {type === "Mileage" && <Row>
                <TextLabel>Mileage cost</TextLabel>
                <TextValue>{getMileageCost(miles, expensesConfig) + ""}</TextValue>
            </Row>}
            {type !== "Mileage" && <Row>
                <Label htmlFor="vat-code">VAT code</Label>
                <TextInput required={true} htmlId="vat-code" value={vat + ""} onChange={updateNumeric(setVat)} type="number" />
            </Row>}
            {type !== "Mileage" && <Row>
                <Label htmlFor="total">Total</Label>
                <TextInput required={true} htmlId="total" value={getTotalDisplay(inTotal, total)} onBlur={toggleInTotal(false)} onInputFocus={toggleInTotal(true)}
                    onChange={updateNumeric(setTotal)} type="number" />
            </Row>}
            {type !== "Mileage" && <Row>
                <TextLabel>VAT</TextLabel>
                <TextValue>{calculateVat(total, vat, expensesConfig.vat)}</TextValue>
            </Row>}
            <Row>
                <Label htmlFor="chargeable">Chargeable</Label>
                <Checkbox checked={chargeable} onChange={setChargeable} />
            </Row>
            <Button disabled={!isFormValid()} onClick={saveExpenseEntry}>Save expense</Button>
            <Button plain={true} style={{ marginLeft: "8px" }} onClick={props.onAddComplete}>Cancel</Button>
        </form>
    </Main>;
};

export default ExpensesForm;
