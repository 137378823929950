import styled from 'styled-components';

const ViewContainer = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    overflow: auto;
`;

export default ViewContainer;
