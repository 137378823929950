import * as React from "react";
import styled from "styled-components";
import { SessionContext } from "../../SessionContext";
import Button from "../../../SharedComponents/Button";
import LoadingIndicator from "../../../SharedComponents/LoadingIndicator";
import TextInput, { updateText } from "../../../SharedComponents/TextInput";
import ICustomer from "../../../ServerEntities/ICustomer";
import { getCustomers, saveCustomer } from "./CustomersService";
import ErrorText from "../../../SharedComponents/ErrorText";
import { Main, Title } from "./CustomersTable";
import IPagedCustomers from "../../../ServerEntities/IPagedCustomers";
import DynamicSelect from "../../../SharedComponents/Select/DynamicSelect";
import SelectItem from "../../../SharedComponents/Select/SelectItem";
import ICustomerReplaced from "../../../ServerEntities/ICustomerReplaced";
import { getCustomerIdBy } from "../../../UsefulFunctions/customerUtils";
import { COLORS } from "../../../config";

const Row = styled.div`
    display: flex;
    flex-direction: row;
    margin: 12px 0px 30px;
`;

const ReplacedCustomerContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: -20px;
`;

const Label = styled.label`
    color: #333333;
    line-height: 32px;
    min-width: 140px;
`;


interface IProps {
    onAddComplete: () => void
};

const CustomerAdd = (props: IProps) => {
    const { state } = React.useContext(SessionContext);
    const [customerName, setCustomerName] = React.useState("");
    const [shortName, setShortName] = React.useState("");
    const [clientId, setClientId] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const [customers, setCustomers] = React.useState(null as unknown as ICustomer[])
    const [existingCustomers, setExistingCustomers] = React.useState([] as string[]);

    const save = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const replace: ICustomerReplaced[] = [];
        existingCustomers.forEach((customer: string) => { replace.push({ id: getCustomerIdBy(customer, customers), name: customer }) })
        const customer: ICustomer = {
            customerName: customerName,
            shortName: shortName,
            clientId: clientId,
            status: true,
            replaces: replace
        };
        setLoading(true);
        saveCustomer(state.webToken, customer, () => {
            setLoading(false);
            setError("");
            props.onAddComplete();
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
        });
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
    };

    React.useEffect(() => {
        setLoading(true);
        getCustomers(state.webToken, 100000, 0, (customers: IPagedCustomers) => {
            setLoading(false);
            setError("");
            setCustomers(customers.data);
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage); ''
            setCustomers(null as unknown as ICustomer[]);
        });
    }, [existingCustomers]);

    const onChangeCustomer = (index: number) => (customer: string) => {
        const existingCustomersCopy = [...existingCustomers];
        existingCustomersCopy[index] = customer;
        setExistingCustomers(existingCustomersCopy);
    };

    const renderReplaceCustomerItem = (existingCustomer: string, index: number) => {
        return <DynamicSelect required={false} placeholder="Select a customer" onChange={onChangeCustomer(index)} childValues={customers && customers.map(option => option.customerName)} value={existingCustomers[index]}>
            {customers && customers.filter((obj: { status: boolean; }) => { return obj.status === true }).map((option, index) => <SelectItem height="20px" key={`add-quote-customer-item-${index}`} value={option.customerName}>{option.customerName}</SelectItem>)}
        </DynamicSelect>
    }

    const addCustomerToReplace = () => {
        const existingCustomersCopy = [...existingCustomers];
        existingCustomersCopy.push("");
        setExistingCustomers(existingCustomersCopy);
    }

    return <Main>
        <LoadingIndicator type="Linear" show={loading} />
        {error && <ErrorText>{error}</ErrorText>}
        <Title>Add customer</Title>


        <form>
            <Row>
                <Label htmlFor="customerName">Customer name</Label>
                <TextInput required={true} htmlId="customerName" value={customerName} onChange={updateText(setCustomerName)} placeholder="Customer name" />
            </Row>
            <Row>
                <Label htmlFor="shortName">Short name</Label>
                <TextInput required={true} htmlId="shortName" value={shortName} onChange={updateText(setShortName)} placeholder="Short name" />
            </Row>
        </form>
        <Row>
            <Label htmlFor="customer">Replaces existing customer</Label>
            <Button color={COLORS.FOURTH} style={{ marginLeft: "20px" }} onClick={addCustomerToReplace}>Add</Button>
        </Row>
        <Row>
            <ReplacedCustomerContainer>
                {existingCustomers.map(renderReplaceCustomerItem)}
            </ReplacedCustomerContainer>
        </Row>
        <Row>
            <Button color={COLORS.FOURTH} onClick={save}>Save customer</Button>
            <Button plain={true} style={{ marginLeft: "8px" }} onClick={props.onAddComplete}>Cancel</Button>
        </Row>


    </Main >;
};

export default CustomerAdd;
