import { useEffect } from 'react';

const useOnClickOutsideSelect = (inputRef: { current: HTMLDivElement }, optionsRef: { current: HTMLElement }, handler: (event: Event) => void) => {
    useEffect(
        () => {
            const listener = (event: Event) => {
                // Do nothing if clicking ref's element or descendent elements
                if (!inputRef.current && !optionsRef.current) {
                    return;
                }
                if (inputRef.current && inputRef.current.contains(event.target as Node)) {
                    return;
                }
                if (optionsRef.current && optionsRef.current.contains(event.target as Node)) {
                    return;
                }

                handler(event);
            };

            document.addEventListener('mousedown', listener);
            document.addEventListener('touchend', listener);

            return () => {
                document.removeEventListener('mousedown', listener);
                document.removeEventListener('touchend', listener);
            };
        },
        [inputRef, optionsRef, handler]
    );
}

export default useOnClickOutsideSelect;