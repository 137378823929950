import axios, { AxiosError, AxiosResponse } from "axios";
import { getAPIUrl } from "../../../config";
import IOpportunity from "../../../ServerEntities/IOpportunity";
import INotificationTrigger from "../../../ServerEntities/INotificationTrigger";
import IPagedOpportunities from "../../../ServerEntities/IPagedOpportunities";
import IQuote from "../../../ServerEntities/IQuote";

export const getOpenQuotes = (webToken: string, successCallback: (quotes: IQuote[]) => void, failureCallback: (error: string) => void) => {
    axios.get(
        `${getAPIUrl()}quotes/quotes/open`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then((response: AxiosResponse<IQuote[]>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};


export const saveOpportunity = (webToken: string, opportunity: IOpportunity, successCallback: (opportunity: IOpportunity) => void, failureCallback: (error: string) => void) => {
    axios.post(
        `${getAPIUrl()}quotes/opportunities`,
        opportunity,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then((response: AxiosResponse<IOpportunity>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const getOpportunity = (opportunityId: number, webToken: string, successCallback: (opportunity: IOpportunity) => void, failureCallback: (error: string) => void) => {
    axios.get(
        `${getAPIUrl()}quotes/opportunities/${opportunityId}`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then((response: AxiosResponse<IOpportunity>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const getOpportunities = (webToken: string, limit: number, offset: number, successCallback: (opportunitys: IPagedOpportunities) => void, failureCallback: (error: string) => void) => {
    axios.get(
        `${getAPIUrl()}quotes/opportunities?limit=${limit}&offset=${offset}`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then((response: AxiosResponse<IPagedOpportunities>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const updateOpportunity = (webToken: string, opportunity: IOpportunity, successCallback: () => void, failureCallback: (error: string) => void) => {
    axios.put(
        `${getAPIUrl()}quotes/opportunities`,
        opportunity,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then(successCallback).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const deleteOpportunity = (webToken: string, opportunityId: number, successCallback: () => void, failureCallback: (error: string) => void) => {
    axios.delete(
        `${getAPIUrl()}quotes/opportunities/${opportunityId}`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then(successCallback).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const commitOpportunityNotification = (trigger: INotificationTrigger, webToken: string, successCallback: () => void, failureCallback: (error: string) => void) => {
    axios.post(
        `${getAPIUrl()}notifications/message/publish`,
        trigger,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then(successCallback).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

