import Axios, { AxiosError, AxiosResponse } from 'axios';

import { getAPIUrl } from '../config';
import { NETWORK_ERROR } from '../UsefulFunctions/createErrorMessage';

export const setMessagesAsSeen = (messageIDs: number[], webToken: string, successCallback: () => void) => {
    Axios.put(
        `${getAPIUrl()}notifications/seen`,
        messageIDs,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then((response: AxiosResponse<undefined>) => {
        successCallback();
    });
};

export const deleteMessages = (messageIDs: number[], webToken: string, successCallback: () => void) => {
    Axios.put(
        `${getAPIUrl()}notifications/delete`,
        messageIDs,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then((response: AxiosResponse<undefined>) => {
        successCallback();
    });
};

export const removeMessage = (messageID: number, webToken: string, successCallback: () => void, failureCallback: (error: string) => void) => {
    Axios.delete(
        `${getAPIUrl()}notifications/message/${messageID}`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then((response: AxiosResponse<undefined>) => {
        successCallback();
    }).catch((error: AxiosError) => {
        handleError(error, failureCallback);
    });
};

const handleError = (error: AxiosError, failureCallback: (errorMessage: string) => void) => {
    let errorMessage = "Unknown error";

    if (error.message === "Network Error") {
        errorMessage = NETWORK_ERROR;
    }
    failureCallback(errorMessage as string);
};