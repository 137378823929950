import * as React from 'react'

interface IProps {
    children?: React.ReactNode;
};

interface ISetWebTokenAction { type: "setWebToken", payload: string };
interface IClearWebTokenAction { type: "clearWebToken", payload: undefined };
interface ISetLoggedInUserAction { type: "setLoggedInUser", payload: string };
interface IClearLoggedInUserAction { type: "clearLoggedInUser", payload: undefined };
interface IUserInteractionOccurredAction { type: "userInterractionOccurred", payload: undefined };
interface IClearUserInteractionOccurredAction { type: "clearUserInterractionOccurred", payload: undefined };
interface ISetShowExpiryDialog { type: "setShowExpiryDialog", payload: boolean };
interface IRefreshMessagesAction { type: "refreshMessages"; payload: undefined }
interface ISetRequestedPath { type: "setRequestedPath", payload: string };

interface ISessionState {
    webToken: string,
    loggedInUser: string,
    showExpiryDialog: boolean,
    userInterractionOccurred: boolean,
    refreshMessages: number,
    requestedPath: string
};

type Actions = ISetWebTokenAction | IClearWebTokenAction |
    ISetLoggedInUserAction | IClearLoggedInUserAction |
    IUserInteractionOccurredAction | IClearUserInteractionOccurredAction | ISetShowExpiryDialog | IRefreshMessagesAction | ISetRequestedPath;

const initialState: ISessionState = {
    loggedInUser: "",
    showExpiryDialog: false,
    userInterractionOccurred: false,
    webToken: "",
    refreshMessages: 0,
    requestedPath: ""
};

const reducer = (state: typeof initialState, action: Actions) => {
    switch (action.type) {
        case "setWebToken":
            return { ...state, webToken: action.payload, userInteractionOccurred: true };
        case "clearWebToken":
            return { ...state, webToken: "" };
        case "setLoggedInUser":
            return { ...state, loggedInUser: action.payload };
        case "clearLoggedInUser":
            return { ...state, loggedInUser: "" };
        case "clearUserInterractionOccurred":
            return { ...state, userInterractionOccurred: false };
        case "userInterractionOccurred":
            return { ...state, userInterractionOccurred: true };
        case "setShowExpiryDialog":
            return { ...state, showExpiryDialog: action.payload };
        case "refreshMessages":
            return { ...state, refreshMessages: state.refreshMessages + 1 };
        case "setRequestedPath":
            return { ...state, requestedPath: state.requestedPath };
    }
};

const SessionContext = React.createContext(initialState as unknown as { state: ISessionState, dispatch: React.Dispatch<Actions> });

const SessionContextProvider = (props: IProps) => {
    const [state, dispatch] = React.useReducer(reducer, (initialState as never));
    const appState = (state as ISessionState);
    return <SessionContext.Provider value={{ state: appState, dispatch } as any}>
        {props.children}
    </SessionContext.Provider>;
};

export { SessionContext, SessionContextProvider };
