import * as React from "react";
import { useParams } from "react-router-dom";
import ErrorText from "../../../SharedComponents/ErrorText";
import Header from "../../../SharedComponents/Header";
import LoadingIndicator from "../../../SharedComponents/LoadingIndicator";
import { SessionContext } from "../../SessionContext";
import { getOpportunities, getOpportunity } from "./OpportunitiesService";
import IOpportunity from "../../../ServerEntities/IOpportunity";
import IPagedOpportunities from "../../../ServerEntities/IPagedOpportunities";
import OpportunitiesTable from "./OpportunitiesTable";
import OpportunityAdd from "./OpportunityAdd";
import OpportunityEdit from "./OpportunityEdit";
import OpportunityDetails from "./OpportunityDetails";
import { COLORS } from "../../../config";

const OpportunitiesIndex = () => {
    const { opportunityId } = useParams();
    const { state } = React.useContext(SessionContext);
    const [isAdding, setIsAdding] = React.useState(false);
    const [isEditing, setIsEditing] = React.useState(null as unknown as IOpportunity | null);
    const [isViewing, setIsViewing] = React.useState(null as unknown as IOpportunity | null);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const [opportunities, setOpportunities] = React.useState(null as unknown as IPagedOpportunities);
    const [refresh, setRefresh] = React.useState(0);
    const [pageNumber, setPageNumber] = React.useState(0);
    const pageSize = 20;

    React.useEffect(() => {
        setLoading(true);
        getOpportunities(state.webToken, pageSize, pageNumber * pageSize, (opportunities: IPagedOpportunities) => {
            setLoading(false);
            setError("");
            setOpportunities(opportunities);
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage); ''
            setOpportunities(null as unknown as IPagedOpportunities);
        });
    }, [refresh]);

    React.useEffect(() => {
        if (opportunityId && isViewing == null) {
            setLoading(true);
            getOpportunity(parseInt(opportunityId), state.webToken, (opportunity: IOpportunity) => {
                setLoading(false);
                setError("");
                setIsViewing(opportunity);
            }, (errorMessage: string) => {
                setLoading(false);
                setError(errorMessage); ''
                setIsViewing(null as unknown as IOpportunity);
            });
        }
    });

    const openAddScreen = () => {
        setIsAdding(true);
    };

    const openEditScreen = (opportunity: IOpportunity) => {
        setIsEditing(opportunity);
    };

    const openViewScreen = (opportunity: IOpportunity) => {
        setIsViewing(opportunity);
    };

    const onChangePageNumber = (pageNumber: number) => {
        setPageNumber(pageNumber);
        onRefresh();
    };

    const closeScreen = () => {
        setRefresh(refresh + 1);
        setIsAdding(false);
        setIsEditing(null);
        setIsViewing(null);
    };

    const onRefresh = () => {
        setRefresh(refresh + 1);
    };

    const getComponent = (isAdding: boolean, isEditing: IOpportunity | null, isViewing: IOpportunity | null) => {
        if (isAdding) {
            return <OpportunityAdd onAddComplete={closeScreen} />;
        }
        else if (isViewing) {
            return <OpportunityDetails opportunity={isViewing} />;
        }
        else if (isEditing) {
            return <OpportunityEdit onAddComplete={closeScreen} opportunity={isEditing} />;
        }
        return <OpportunitiesTable
            pageNumber={pageNumber}
            onChangePageNumber={onChangePageNumber}
            onRefresh={onRefresh}
            onAdd={openAddScreen}
            onEdit={openEditScreen}
            onDelete={onRefresh}
            onView={openViewScreen}
            opportunities={opportunities} pageSize={0} />;
    };


    if (opportunities && opportunities.data.length === 0 && pageNumber > 0) {
        onChangePageNumber(pageNumber - 1);
    }

    const onBack = () => {
        setIsAdding(false);
        setIsEditing(null);
        setIsViewing(null);
    }

    return <div>
        <Header backgroundColor={COLORS.SIXTH} onBack={onBack} previousPageUrl={isAdding || isEditing || isViewing ? "/Opportunities" : "/"} />
        <LoadingIndicator type="Linear" show={loading} />
        {error && <ErrorText>{error}</ErrorText>}
        {getComponent(isAdding, isEditing, isViewing)}
    </div>;
};

export default OpportunitiesIndex;
