import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import styled from "styled-components";
import useFocus from "../CustomHooks/useFocus";
import IconButton from "./IconButton";
import Menu from "./Menu/Menu";
import MenuItem from "./Menu/MenuItem";

interface IUserMenuProps {
    role?: string,
    username: string,
    onSignOut: () => void,
    onChangeRole: () => void
};

const UserContainer = styled.div`
    padding: 8px 16px 12px 16px;
    border-bottom: 1px solid #e5e5e5;
    user-interaction: none;
    cursor: default;
`;

const TopLabel = styled.p`
    margin: 0;
    font-size: 14px;
    line-height: 19px;
    color: #555555;
`;

const UsernameLabel = styled.p`
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 25px;
    color: #000000;
`;

const RoleLabel = styled.p`
    margin: 0;
    font-size: 14px;
    line-height: 22px;
`;

const UserMenu = (props: IUserMenuProps) => {
    const { role, username, onSignOut, onChangeRole } = props;
    const [navMenuOpen, setNavMenuOpen] = React.useState(false);
    const menuButtonRef = React.useRef(null as unknown as HTMLDivElement);
    const menuRef = React.useRef(null as unknown as HTMLDivElement);

    const toggleNavMenu = () => {
        if (!navMenuOpen) {
            setNavMenuOpen(true);
            useFocus(menuRef);
        } else {
            setNavMenuOpen(false);
        }
    };

    const closeNavMenu = () => {
        setNavMenuOpen(false);
    };

    const handleKeyDown = (isFirst: boolean, isLast: boolean, enterFunction: () => void) => (event: any) => {
        const key = event.key || event.keyCode;
        if (key === "Enter" || key === " ") {
            enterFunction();
        }
        if (key === "Escape") {
            menuButtonRef.current.focus();
            setNavMenuOpen(false);
        }
        if (key === "Tab" && event.shiftKey && isFirst) {
            menuButtonRef.current.focus();
            setNavMenuOpen(false);
        }
        if (key === "Tab" && !event.shiftKey && isLast) {
            menuButtonRef.current.focus();
            setNavMenuOpen(false);
        }
    };

    return <span>
        <IconButton onClick={toggleNavMenu} refObject={menuButtonRef} key="userMenuIcon">
            <FontAwesomeIcon color="white" icon="user" />
        </IconButton>
        { navMenuOpen && <Menu menuContainerRef={menuRef} onClose={closeNavMenu} anchorElement={menuButtonRef} width={300}>
            <UserContainer>
                <TopLabel>Signed in as</TopLabel>
                <UsernameLabel>{username}</UsernameLabel>
                <RoleLabel>{role}</RoleLabel>
            </UserContainer>
            <MenuItem key="change-role-item" onKeyDown={handleKeyDown(true, false, onChangeRole)} onClick={onChangeRole}>
                Change role
            </MenuItem>
            <MenuItem key="sign-out-item" onKeyDown={handleKeyDown(false, true, onSignOut)} onClick={onSignOut}>
                Sign out
            </MenuItem>
        </Menu> }
    </span>;
};

export default UserMenu;
